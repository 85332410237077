define("bd-hybrid-cloud/templates/app/projects/project/add/configurations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QPotAhkD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"x-app-content-overlay-view\",null,[[\"title\",\"subtitle\",\"previous\"],[[20,[\"model\",\"title\"]],[25,\"t\",[\"projects.add.configurations\"],null],true]],{\"statements\":[[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/projects/project/add/configurations.hbs"
    }
  });
  _exports.default = _default;
});