define("bd-hybrid-cloud/components/modals/x-send-email-modal", ["exports", "bgr-ember-modal/components/bgr-modal", "bd-hybrid-cloud/utils/type-check"], function (_exports, _bgrModal, _typeCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    isBlank = Ember.isBlank,
    debounce = Ember.run.debounce;
  var _default = _bgrModal.default.extend({
    /**
     * State
     */

    emailAddresses: [],
    form: {
      message: ''
    },
    selectedUsers: [],
    showTextarea: false,
    users: [],
    /**
     * Computed
     */

    modelName: computed.alias('model.constructor.modelName'),
    modelIsProject: computed.equal('modelName', 'project'),
    sortedUsersConfig: ['attributes.email'],
    sortedUsers: computed.sort('users', 'sortedUsersConfig'),
    toggleButtonIconClass: computed('showTextarea', function () {
      return this.get('showTextarea') ? 'icon-minus' : 'icon-plus';
    }),
    toggleButtonLabel: computed('showTextarea', 'trans.locale', function () {
      var trans = this.get('trans');
      var baseTranslationKey;
      if (this.get('showTextarea')) {
        baseTranslationKey = 'actions.removeWithEntity';
      } else {
        baseTranslationKey = 'actions.addWithEntity';
      }
      return trans.t(baseTranslationKey, {
        entity: trans.t('general.message')
      });
    }),
    /**
     * Actions
     */

    actions: {
      toggleTextarea: function toggleTextarea() {
        this.toggleProperty('showTextarea');
        if (!this.get('showTextarea')) {
          this.set('form.message', '');
        }
      },
      search: function search(query) {
        debounce(this, this.queryUsers, {
          query: query
        }, 200);
      },
      createOnEnter: function createOnEnter(select, e) {
        if (e.keyCode === 13 && select.isOpen && !select.highlighted && !isBlank(select.searchText)) {
          this.send('addEmail', select.searchText);
        }
      },
      submit: function submit() {
        var _this = this;
        var selectedUsers = this.get('selectedUsers');
        var trans = this.get('trans');
        var model = this.get('model');
        if (selectedUsers.length) {
          var _this$get = this.get('form'),
            message = _this$get.message;
          var entity = selectedUsers[0].attributes.email;
          if (selectedUsers.length > 1) {
            entity = "".concat(selectedUsers.length, " ").concat(trans.t('general.people'));
          }
          var successMessage = trans.t('alert.success.sharedModelWithEntity', {
            entity: entity,
            model: model.get('title')
          });
          model.sendShareEmail(selectedUsers, message).then(function () {
            // clear the given users
            selectedUsers.clear();
            _this.hide();
            _this.get('flashMessages').success(successMessage);
          });
        }
      },
      unshare: function unshare(member) {
        this.get('model').unshare(member);
      },
      addEmail: function addEmail(emailAddress) {
        if ((0, _typeCheck.default)(emailAddress)) {
          var selectedUsers = this.get('selectedUsers');
          var object = {
            attributes: {
              email: emailAddress
            }
          };
          if (!selectedUsers.includes(object)) {
            selectedUsers.addObject(object);
          }
        }
      },
      removeEmailAtIndex: function removeEmailAtIndex(index) {
        this.get('selectedUsers').removeAt(index);
      },
      onSelectChange: function onSelectChange(input) {
        this.set('selectedUsers', input);
      }
    },
    /**
     * Methods
     */
    queryUsers: function queryUsers(filter) {
      var _this2 = this;
      var activeUserId = parseInt(this.get('activeUser.model.id'), 10);
      this.get('api').request("users?filter[email]=%25".concat(filter.query, "%25")).then(function (response) {
        _this2.set('users', response.data.filter(function (user) {
          return user.id !== activeUserId;
        }));
      });
    }
  });
  _exports.default = _default;
});