define("bd-hybrid-cloud/templates/app/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7ck6gKy7",
    "block": "{\"symbols\":[],\"statements\":[[6,\"header\"],[9,\"class\",\"section section--collapse-bottom section--white\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"gr-12\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row section__row--sm\"],[7],[0,\"\\n          \"],[6,\"h1\"],[9,\"class\",\"bd-title bd-title--h3\"],[7],[1,[25,\"t\",[\"pages.help.title\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row section__row--sm\"],[7],[0,\"\\n          \"],[6,\"h1\"],[9,\"class\",\"bd-title bd-title--h5\"],[7],[1,[25,\"t\",[\"pages.help.description\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row section__row--sm\"],[7],[0,\"\\n          \"],[6,\"nav\"],[7],[0,\"\\n            \"],[6,\"ul\"],[9,\"class\",\"sub-navigation\"],[7],[0,\"\\n              \"],[6,\"li\"],[9,\"class\",\"sub-navigation__item\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"app.help.tutorials\"],[[\"class\"],[\"sub-navigation__link\"]],{\"statements\":[[0,\"                  \"],[1,[25,\"t\",[\"pages.help.tutorials.title\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"              \"],[8],[0,\"\\n              \"],[6,\"li\"],[9,\"class\",\"sub-navigation__item\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"app.help.documents\"],[[\"class\"],[\"sub-navigation__link\"]],{\"statements\":[[0,\"                  \"],[1,[25,\"t\",[\"pages.help.documents.title\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"              \"],[8],[0,\"\\n            \"],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"py-15px\"],[7],[0,\"\\n  \"],[1,[18,\"outlet\"],false],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/help.hbs"
    }
  });
  _exports.default = _default;
});