define("bd-hybrid-cloud/templates/components/load-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ttc7GeYk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[19,0,[\"loadRecordTask\",\"isRunning\"]],[19,0,[\"loadRecordTask\",\"lastSuccessful\",\"value\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/load-record.hbs"
    }
  });
  _exports.default = _default;
});