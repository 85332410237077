define("bd-hybrid-cloud/components/modals/x-export-samples-modal", ["exports", "bd-hybrid-cloud/utils/file", "bgr-ember-modal/components/bgr-modal", "moment"], function (_exports, _file, _bgrModal, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _bgrModal.default.extend({
    /**
     * state
     */

    isLoading: false,
    /**
     * actions
     */

    actions: {
      downloadExport: function downloadExport() {
        var _this = this;
        var data = {
          filter: Object.assign({}, this.get('model.filters'))
        };
        data.sort = data.filter.sort;
        Reflect.deleteProperty(data.filter, 'page');
        Reflect.deleteProperty(data.filter, 'sort');
        var options = {
          xhrFields: {
            responseType: 'blob'
          }
        };
        this.set('isLoading', true);
        this.get('api').request('samples/export', data, options).then(function (fileContent) {
          var fileName = _this.get('trans').t('samples.exportFileName', {
            date: (0, _moment.default)().format('DD-MM-YYYY'),
            ext: 'xlsx'
          });
          (0, _file.downloadFile)(fileContent, fileName);
        }).catch(function () {
          _this.get('flashMessages').danger(_this.get('trans').t('alert.danger.exportDownload'));
        }).finally(function () {
          _this.set('isLoading', false);
        });
      }
    }
  });
  _exports.default = _default;
});