define("bd-hybrid-cloud/components/x-editor-instruction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    Component = Ember.Component;
  var _default = Component.extend({
    /**
     * Props
     */

    arrowPosition: null,
    centered: false,
    text: '',
    theme: 'light',
    /**
     * State
     */

    classNames: ['editor-instruction'],
    classNameBindings: ['centered:editor-instruction--centered', 'themeClass'],
    /**
     * Computed
     */

    themeClass: computed('theme', function () {
      return "editor-instruction--theme-".concat(this.get('theme'));
    })
  });
  _exports.default = _default;
});