define("bd-hybrid-cloud/mixins/route/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    /**
     * Props
     */

    neededPermissions: [],
    /**
     * Hooks
     */
    beforeModel: function beforeModel(transition) {
      this._super(transition);
      var canAccessRoute = this.canAccessRoute(this.get('activeUser'), this.get('neededPermissions'));
      if (!canAccessRoute) {
        this.transitionTo('not-found', {
          wildcard: 'not-found'
        });
      }
    },
    canAccessRoute: function canAccessRoute(activeUserService, neededPermissions) {
      var can = activeUserService.get('can');
      return neededPermissions.every(function (permission) {
        return can[permission.camelize()];
      });
    }
  });
  _exports.default = _default;
});