define("bd-hybrid-cloud/helpers/style", ["exports", "bd-hybrid-cloud/utils/create-style-string"], function (_exports, _createStyleString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.style = style;
  function style(params, hash) {
    return (0, _createStyleString.default)(hash);
  }
  var _default = Ember.Helper.helper(style);
  _exports.default = _default;
});