define("bd-hybrid-cloud/controllers/app/collections/collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var DASH = '-';
  var ASC = 'asc';
  var DESC = 'desc';
  function isDesc(sort) {
    return sort.startsWith(DASH);
  }
  function getAttribute(sort) {
    return isDesc(sort) ? sort.substring(1, sort.length) : sort;
  }
  var _default = Ember.Controller.extend({
    /**
     * State
     */

    queryParams: ['sort'],
    sort: '-updatedAt',
    /**
     * Computed
     */

    sortedConfig: computed('sort', function () {
      var sort = this.get('sort');
      var sortField = getAttribute(sort);
      var sortDirection = isDesc(sort) ? DESC : ASC;
      return ["".concat(sortField, ":").concat(sortDirection)];
    }),
    collectionThemes: computed.alias('model.collection.collectionThemes'),
    sortedCollectionThemes: computed.sort('collectionThemes', 'sortedConfig'),
    hasCollectionThemes: computed('collectionThemes', function () {
      return this.get('collectionThemes.length') > 0;
    })
  });
  _exports.default = _default;
});