define("bd-hybrid-cloud/components/x-color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = Ember.Component.extend({
    /**
     * Props
     */

    allowCustomColor: false,
    colors: [],
    filterBy: null,
    hexProperty: 'hex',
    isDeleteColorsEnabled: false,
    multiple: true,
    onChange: null,
    onDeleteColor: function onDeleteColor() {},
    selected: null,
    /**
     * State
     */

    classNames: ['color-picker'],
    selectedCustomColor: null,
    tagName: 'ul',
    /**
     * Computed
     */

    defaultColors: computed('colors', 'filterBy', function () {
      var colors = this.get('colors');
      return this.get('filterBy') ? colors.filter(function (color) {
        return color.id.indexOf('-') === -1;
      }) : colors;
    }),
    filterByItems: computed('filterBy', function () {
      var filterBy = this.get('filterBy');
      return filterBy && filterBy.trim().split(',');
    }),
    filteredColorGroups: computed('colors', 'filterBy', 'filterByItems.[]', function () {
      var _this = this;
      var filterBy = this.get('filterBy');
      return filterBy && this.get('filterByItems').map(function (item) {
        var idPrefix = "".concat(item, "-");
        return {
          label: item,
          colors: _this.get('colors').filter(function (color) {
            return color.id.indexOf(idPrefix) > -1;
          })
        };
      });
    }),
    /**
     * Hooks
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var selected = this.get('selected');

      // set 'selectedCustomColor' based on 'selected'
      // if 'selected' is a custom color
      if (selected && selected.isCustom) {
        this.set('selectedCustomColor', selected.color);
      }
    },
    /**
     * Actions
     */

    actions: {
      confirmSelectedCustomColor: function confirmSelectedCustomColor() {
        this.send('selectCustomColor', this.get('selectedCustomColor'));
        document.activeElement.blur();
      },
      toggleColor: function toggleColor(color) {
        var multiple = this.get('multiple');
        var selected = this.get('selected');
        var newSelected;
        if (multiple) {
          newSelected = selected ? selected.slice() : [];
          if (newSelected.includes(color)) {
            newSelected.removeObject(color);
          } else {
            newSelected.addObject(color);
          }
        } else {
          newSelected = color === selected ? null : color;
        }
        this.set('selectedCustomColor', null);
        this.sendAction('onChange', newSelected);
      },
      selectCustomColor: function selectCustomColor(customColor) {
        // trigger 'onChange' with a custom color object
        this.sendAction('onChange', {
          color: customColor,
          id: customColor,
          isCustom: true
        });
      }
    },
    onDeleteClick: function onDeleteClick(color) {
      this.onDeleteColor(color);
    }
  });
  _exports.default = _default;
});