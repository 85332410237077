define("bd-hybrid-cloud/templates/components/modals/x-vimeo-video-player-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cocGF6q0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bgr-modal-template\",null,null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"modal__title\"],[7],[0,\"\\n    \"],[6,\"p\"],[7],[1,[19,0,[\"model\",\"title\"]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal__row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"relative\"],[9,\"style\",\"padding-bottom: 56.25%;\"],[7],[0,\"\\n      \"],[6,\"iframe\"],[9,\"allow\",\"autoplay; fullscreen\"],[9,\"class\",\"absolute h-full pin-l pin-t w-full\"],[9,\"frameborder\",\"0\"],[9,\"height\",\"360\"],[10,\"src\",[26,[[19,0,[\"model\",\"videoUrl\"]]]]],[9,\"width\",\"640\"],[9,\"allowfullscreen\",\"\"],[7],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal__row\"],[7],[0,\"\\n      \"],[6,\"p\"],[7],[1,[19,0,[\"model\",\"description\"]],true],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/modals/x-vimeo-video-player-modal.hbs"
    }
  });
  _exports.default = _default;
});