define("bd-hybrid-cloud/mixins/route/filter-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
    Mixin = Ember.Mixin;
  function queryParamConfig() {
    var isFilter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    return {
      isFilter: isFilter,
      refreshModel: true
    };
  }
  var _default = Mixin.create({
    /**
     * Injections
     */

    filterQuery: inject.service(),
    /**
     * State
     */

    queryParams: {
      brand: queryParamConfig(),
      categories: queryParamConfig(),
      collection: queryParamConfig(),
      color: queryParamConfig(),
      composition: queryParamConfig(),
      country: queryParamConfig(),
      endsMin: queryParamConfig(),
      endsMax: queryParamConfig(),
      finishing: queryParamConfig(),
      isBorder: queryParamConfig(),
      isPanel: queryParamConfig(),
      page: queryParamConfig(false),
      picksMin: queryParamConfig(),
      picksMax: queryParamConfig(),
      private: queryParamConfig(),
      project: queryParamConfig(),
      projects: queryParamConfig(),
      q: queryParamConfig(),
      region: queryParamConfig(),
      sort: queryParamConfig(false),
      style: queryParamConfig(),
      tag: queryParamConfig(),
      technology: queryParamConfig(),
      theme: queryParamConfig(),
      weightMin: queryParamConfig(),
      weightMax: queryParamConfig(),
      widthMin: queryParamConfig(),
      widthMax: queryParamConfig()
    },
    /**
     * Methods
     */
    generateFilterObject: function generateFilterObject(params) {
      var filterObject = {};
      var queryParams = this.get('queryParams');
      Object.keys(queryParams).forEach(function (key) {
        var queryParam = queryParams[key];
        if (queryParam.isFilter) {
          var value = params[key];
          if (value && Ember.isPresent(value)) {
            filterObject[key] = value;
          }
        }
      });
      return filterObject;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('filterQuery.controller', controller);
    }
  });
  _exports.default = _default;
});