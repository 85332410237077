define("bd-hybrid-cloud/templates/partials/share/detail-panel/component/fabric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iECYux2r",
    "block": "{\"symbols\":[],\"statements\":[[12,\"partials/share/detail-panel-section/component/detail-sample\",[]],[0,\"\\n\"],[4,\"if\",[[20,[\"hasRibbonComponent\"]]],null,{\"statements\":[[0,\"  \"],[12,\"partials/share/detail-panel-section/component/detail-ribbon\",[]],[0,\"\\n\"]],\"parameters\":[]},null],[12,\"partials/share/detail-panel-section/component/detail-quilt\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/share/detail-panel/component/fabric.hbs"
    }
  });
  _exports.default = _default;
});