define("bd-hybrid-cloud/components/x-color-picker-button", ["exports", "bd-hybrid-cloud/utils/create-style-string"], function (_exports, _createStyleString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed;
  var _default = Component.extend({
    /**
     * Props
     */

    color: null,
    hexProperty: 'hex',
    isActive: false,
    onClick: null,
    type: 'button',
    /**
     * State
     */

    attributeBindings: ['style', 'type'],
    classNameBindings: ['isActive:is-active'],
    classNames: ['color-picker__button'],
    tagName: 'button',
    /**
     * Computed
     */

    style: computed('color', function () {
      return (0, _createStyleString.default)({
        backgroundColor: this.get("color.".concat(this.get('hexProperty'))) || this.get('color')
      });
    }),
    /**
     * Events
     */
    click: function click() {
      this.sendAction('onClick', this.get('color'));
    }
  });
  _exports.default = _default;
});