define("bd-hybrid-cloud/mixins/component/configuration-sample-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    Mixin = Ember.Mixin;
  var _default = Mixin.create({
    /**
     * State
     */

    model: {},
    /**
     * Computed
     */

    title: computed('sample.title', function () {
      var trans = this.get('trans');
      return this.get('sample.title') || trans.t('actions.selectEntity', {
        entity: trans.t('modelNames.sample')
      });
    }),
    sample: computed('model.id', function () {
      var sample;
      var id = this.get('model.id');
      if (id) {
        sample = this.get('store').peekRecord('sample', id);
      }
      return sample;
    }),
    subtitle: computed('sample.quality', function () {
      var subtitle;
      var sample = this.get('sample');
      if (sample) {
        subtitle = sample.get('draft') ? this.get('trans').t('general.draft') : sample.get('quality');
      }
      return subtitle;
    })
  });
  _exports.default = _default;
});