define("bd-hybrid-cloud/templates/app/capsules/capsule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "r84EAB1e",
    "block": "{\"symbols\":[\"isLoading\",\"capsule\"],\"statements\":[[4,\"load-record\",null,[[\"modelName\",\"id\"],[\"concept\",[19,0,[\"model\",\"id\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[19,1,[]]],null,{\"statements\":[[0,\"    \"],[1,[18,\"app-content-loader\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"x-project-view\",null,[[\"model\",\"parentRoute\",\"shouldRenderPinButton\"],[[19,2,[]],\"app.capsules\",false]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/capsules/capsule.hbs"
    }
  });
  _exports.default = _default;
});