define("bd-hybrid-cloud/services/api", ["exports", "bd-hybrid-cloud/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var $ = Ember.$,
    inject = Ember.inject,
    Promise = Ember.RSVP.Promise,
    Service = Ember.Service;
  var _default = Service.extend({
    /**
     * State
     */

    session: inject.service(),
    /**
     * Methods
     */
    makeRequest: function makeRequest(type, path, data, options) {
      var _this = this;
      return new Promise(function (resolve, reject) {
        var authenticatedData = _this.get('session.data.authenticated');
        var ajaxOptions = _objectSpread(_objectSpread({}, options), {}, {
          contentType: 'application/json',
          headers: {
            Authorization: "".concat(authenticatedData.token_type, " ").concat(authenticatedData.access_token),
            Accept: 'application/json'
          },
          type: type,
          url: "".concat(_environment.default.api.url, "/").concat(path)
        });
        if (data) {
          if (type === 'GET') {
            ajaxOptions.data = data;
          } else {
            var attributes = {};
            Object.keys(data).forEach(function (key) {
              attributes[key.underscore()] = data[key];
            });
            ajaxOptions.data = JSON.stringify({
              data: {
                attributes: attributes
              }
            });
          }
        }
        $.ajax(ajaxOptions).done(resolve).fail(reject);
      });
    },
    patch: function patch() {
      for (var _len = arguments.length, parameters = new Array(_len), _key = 0; _key < _len; _key++) {
        parameters[_key] = arguments[_key];
      }
      return this.makeRequest.apply(this, ['PATCH'].concat(parameters));
    },
    post: function post() {
      for (var _len2 = arguments.length, parameters = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        parameters[_key2] = arguments[_key2];
      }
      return this.makeRequest.apply(this, ['POST'].concat(parameters));
    },
    request: function request() {
      for (var _len3 = arguments.length, parameters = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        parameters[_key3] = arguments[_key3];
      }
      return this.makeRequest.apply(this, ['GET'].concat(parameters));
    }
  });
  _exports.default = _default;
});