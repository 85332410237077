define("bd-hybrid-cloud/components/inline-title-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * State
     */

    isEditModeEnabled: false,
    /**
     * Methods
     */
    callCallback: function callCallback() {
      if (typeof this.onEditTitleCallback === 'function') {
        this.onEditTitleCallback();
      }
    },
    focusOnInput: function focusOnInput() {
      var _this = this;
      setTimeout(function () {
        _this.get('element').querySelector("#title-input").focus();
      }, 10);
    },
    /**
     * Actions
     */
    toggleEditMode: function toggleEditMode() {
      this.set('isEditModeEnabled', !this.isEditModeEnabled);
      if (this.isEditModeEnabled) {
        this.focusOnInput();
      }
      if (!this.isEditModeEnabled) {
        this.callCallback();
      }
    }
  });
  _exports.default = _default;
});