define("bd-hybrid-cloud/templates/partials/share/detail-panel/component/zipper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "26ENY4j7",
    "block": "{\"symbols\":[],\"statements\":[[12,\"partials/share/detail-panel-section/component/detail-zipper\",[]],[0,\"\\n\"],[12,\"partials/share/detail-panel-section/component/detail-color\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/share/detail-panel/component/zipper.hbs"
    }
  });
  _exports.default = _default;
});