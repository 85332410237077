define("bd-hybrid-cloud/templates/app/projects/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cHWXnSZe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"x-app-content-overlay-view\",null,[[\"title\",\"subtitle\",\"onCloseRoute\"],[[25,\"t\",[\"projects.create.title\"],null],[25,\"t\",[\"projects.create.subtitle\"],null],\"app.projects\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"gr-8 gr-4@desktop gr-centered u-text-center\"],[7],[0,\"\\n        \"],[1,[25,\"x-edit-description-form\",null,[[\"project\",\"tags\"],[[20,[\"model\",\"project\"]],[20,[\"model\",\"tags\"]]]]],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/projects/create.hbs"
    }
  });
  _exports.default = _default;
});