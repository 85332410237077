define("bd-hybrid-cloud/routes/app/projects/project/note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    /**
     * Actions
     */

    actions: {
      updateNote: function updateNote(content) {
        var _this = this;
        var note = this.modelFor('app.projects.project.note');
        note.set('content', content);
        note.save().then(function () {
          _this.transitionTo('app.projects.project');
        });
      }
    },
    /**
     * Hooks
     */
    model: function model(_ref) {
      var note_id = _ref.note_id;
      return this.get('store').findRecord('note', note_id);
    }
  });
  _exports.default = _default;
});