define("bd-hybrid-cloud/components/help-video-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * State
     */

    tagName: '',
    /**
     * Actions
     */
    onClickHandler: function onClickHandler() {
      if (typeof this.onClickCallback === 'function') {
        this.onClickCallback();
      }
    }
  });
  _exports.default = _default;
});