define("bd-hybrid-cloud/templates/register-confirmed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RiO1EEmI",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n  \"],[6,\"section\"],[9,\"class\",\"section section--lg u-text-center\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n        \"],[12,\"partials/general/logos/bd-logo-blue\",[]],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"section__row section__row--xs\"],[7],[0,\"\\n        \"],[6,\"p\"],[7],[1,[25,\"t\",[\"register.confirmed\"],null],false],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row section__row--lg\"],[7],[0,\"\\n      \"],[4,\"link-to\",[\"login\"],[[\"class\"],[\"bd-button bd-button--regular bd-button--solid\"]],{\"statements\":[[1,[25,\"t\",[\"auth.login\"],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/register-confirmed.hbs"
    }
  });
  _exports.default = _default;
});