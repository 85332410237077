define("bd-hybrid-cloud/components/x-project-view/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIMEO_URL = 'https://player.vimeo.com/video/';
  var _default = Ember.Component.extend({
    /**
     * Arguments
     */

    activeUser: null,
    canEdit: false,
    isSortable: false,
    notes: null,
    onSortUpdate: function onSortUpdate() {},
    shouldCollapseItems: true,
    shouldHideVideoNotes: false,
    /**
     * State
     */

    tagName: '',
    /**
     * Computed
     */

    filteredNotes: Ember.computed('notes.[]', function () {
      var notes = this.get('notes');
      if (this.shouldHideVideoNotes) {
        return notes.filter(function (note) {
          return !note.get('content').includes(VIMEO_URL);
        });
      }
      return notes;
    })
  });
  _exports.default = _default;
});