define("bd-hybrid-cloud/routes/welcome", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route,
    RSVP = Ember.RSVP;
  var _default = Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model() {
      return RSVP.hash({
        countries: this.get('store').seekAll('country', {
          include: 'salesReps'
        })
      });
    }
  });
  _exports.default = _default;
});