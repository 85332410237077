define("bd-hybrid-cloud/routes/app/projects/project/add/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      var projectId = this.modelFor('app.projects.project').id;
      return this.store.loadRecord('project', projectId);
    }
  });
  _exports.default = _default;
});