define("bd-hybrid-cloud/templates/components/x-camera-view-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LOpKU4Rb",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"input\",null,[[\"class\",\"disabled\",\"enter\",\"escape-press\",\"focus-out\",\"placeholder\",\"value\"],[\"camera-view-input__input\",[20,[\"isEditDisabled\"]],\"confirm\",\"cancel\",\"confirm\",[25,\"t\",[\"cameraViewInput.placeholder\"],null],[20,[\"internalValue\"]]]]],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"camera-view-input__icons\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"camera-view-input__icon\"],[7],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"icon-3D-camera\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"click\"],null],null],[10,\"title\",[25,\"t\",[\"actions.apply\"],null],null],[7],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"camera-view-input__icon\"],[7],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"icon-pencil\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"edit\"],null],null],[10,\"title\",[25,\"t\",[\"actions.rename\"],null],null],[7],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"camera-view-input__icon\"],[7],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"icon-trash\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"delete\"],null],null],[10,\"title\",[25,\"t\",[\"actions.remove\"],null],null],[7],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-camera-view-input.hbs"
    }
  });
  _exports.default = _default;
});