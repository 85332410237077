define("bd-hybrid-cloud/services/meta-store", ["exports", "bgr-ember-json-api/services/meta-store"], function (_exports, _metaStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _metaStore.default;
    }
  });
});