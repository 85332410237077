define("bd-hybrid-cloud/templates/partials/welcome/customization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lklMYxtt",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"hero hero--section-3 hero--flex customization\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"container text-center md:text-left\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"gr-12 gr-4@tablet\"],[7],[0,\"\\n          \"],[6,\"h1\"],[9,\"class\",\"lg:text-5xl bd-title bd-title--light bd-text-blue\"],[7],[1,[25,\"t\",[\"customization.title\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"gr-12 gr-6@tablet gr-5@desktop-md\"],[7],[0,\"\\n          \"],[6,\"p\"],[9,\"class\",\"bd-text-white\"],[7],[1,[25,\"t\",[\"customization.text\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"hero__image\"],[7],[0,\"\\n    \"],[6,\"img\"],[9,\"src\",\"/assets/images/visual-screen-3.jpg\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/welcome/customization.hbs"
    }
  });
  _exports.default = _default;
});