define("bd-hybrid-cloud/templates/components/inline-title-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "K56aZyy2",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"group -ml-2 relative\"],[7],[0,\"\\n  \"],[6,\"input\"],[10,\"class\",[25,\"cx\",[\"border border-transparent text-gray-blue truncate h-8 pl-2 pr-8 rounded w-full\",\"group-hover:border-blue\",[25,\"if\",[[19,0,[\"isEditModeEnabled\"]],\"border-blue\"],null]],null],null],[10,\"disabled\",[25,\"not\",[[19,0,[\"isEditModeEnabled\"]]],null],null],[9,\"id\",\"title-input\"],[10,\"oninput\",[25,\"action\",[[19,0,[]],[25,\"mut\",[[19,0,[\"value\"]]],null]],[[\"value\"],[\"target.value\"]]],null],[10,\"value\",[19,0,[\"value\"]],null],[7],[8],[0,\"\\n  \"],[6,\"button\"],[10,\"class\",[25,\"cx\",[\"absolute h-8 opacity-0 pin-r pin-t text-blue w-8 group-hover:opacity-100\",[25,\"if\",[[19,0,[\"isEditModeEnabled\"]],\"opacity-100\"],null]],null],null],[10,\"onclick\",[25,\"action\",[[19,0,[]],[19,0,[\"toggleEditMode\"]]],null],null],[7],[0,\"\\n    \"],[6,\"span\"],[10,\"class\",[25,\"if\",[[19,0,[\"isEditModeEnabled\"]],\"icon-check\",\"icon-pencil\"],null],null],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/inline-title-editor.hbs"
    }
  });
  _exports.default = _default;
});