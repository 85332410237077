define("bd-hybrid-cloud/helpers/state-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stateActive = stateActive;
  function stateActive(params) {
    return params[0] ? 'is-active' : 'is-inactive';
  }
  var _default = Ember.Helper.helper(stateActive);
  _exports.default = _default;
});