define("bd-hybrid-cloud/models/tag", ["exports", "ember-data", "bgr-ember-json-api/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany;
  var _default = _model.default.extend({
    /**
     * Props
     */

    name: attr('string'),
    projects: hasMany('project')
  });
  _exports.default = _default;
});