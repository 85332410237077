define("bd-hybrid-cloud/templates/components/x-project-view/note-videos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "A6VnLyZR",
    "block": "{\"symbols\":[\"video\"],\"statements\":[[4,\"if\",[[19,0,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"section section--alternate\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"loader loader--md\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,\"not\",[[25,\"is-empty\",[[19,0,[\"noteVideos\"]]],null]],null]],null,{\"statements\":[[4,\"each\",[[19,0,[\"noteVideos\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"section section--alternate\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"gr-8 gr-centered\"],[7],[0,\"\\n                \"],[1,[25,\"embed-video-player\",null,[[\"videoUrl\"],[[19,1,[\"content\"]]]]],false],[0,\"\\n              \"],[8],[0,\"\\n            \"],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-project-view/note-videos.hbs"
    }
  });
  _exports.default = _default;
});