define("bd-hybrid-cloud/templates/app/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "T8GL8xw/",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[6,\"header\"],[9,\"class\",\"section section--white\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"gr-8 gr-centered\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n          \"],[6,\"h1\"],[9,\"class\",\"bd-title bd-title--h3\"],[7],[1,[25,\"t\",[\"activityLog.title\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"section\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"container grid-view\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"gr-8 gr-centered\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"x-log\",null,[[\"model\",\"showProject\",\"showNotifcation\"],[[19,1,[]],true,true]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n          \"],[1,[25,\"bgr-pagination\",null,[[\"config\"],[[20,[\"model\",\"meta\",\"pagination\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/notifications.hbs"
    }
  });
  _exports.default = _default;
});