define("bd-hybrid-cloud/controllers/app/concepts/index", ["exports", "bd-hybrid-cloud/mixins/filter-query"], function (_exports, _filterQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_filterQuery.default, {
    /**
     * Computed
     */

    conceptsQuery: Ember.computed.alias('model.conceptsQuery')
  });
  _exports.default = _default;
});