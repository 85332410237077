define("bd-hybrid-cloud/templates/partials/share/detail-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Mdym2fGI",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-panel-tree\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"editor-panel-tree__header\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"bd-title bd-title--h5 bd-title--bold u-text-center u-capitalize\"],[7],[0,\"\\n        \"],[1,[18,\"modelName\"],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row section__row--xs\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"editor__subtitle u-text-center\"],[7],[0,\"\\n        \"],[1,[18,\"mattressDimensions\"],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"editor-panel-tree__body\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"editor-panel__section editor-panel__section\"],[7],[0,\"\\n\"],[4,\"x-editor-instruction\",null,[[\"theme\",\"text\"],[\"dark\",[25,\"t\",[\"share.instructions.selectComponent\"],null]]],{\"statements\":[[0,\"        \"],[6,\"img\"],[9,\"src\",\"/assets/images/interface/graphics/select-mattress-component.png\"],[9,\"width\",\"94\"],[9,\"height\",\"55\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/share/detail-panel.hbs"
    }
  });
  _exports.default = _default;
});