define("bd-hybrid-cloud/templates/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pw0eD3oG",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"loader \",[25,\"if\",[[19,0,[\"isInverted\"]],\"loader--inverted\"],null]]]],[7],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/loading-spinner.hbs"
    }
  });
  _exports.default = _default;
});