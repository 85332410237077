define("bd-hybrid-cloud/templates/components/etw/module-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mpVEGDRA",
    "block": "{\"symbols\":[\"moduleStyle\",\"snippet\"],\"statements\":[[6,\"section\"],[7],[0,\"\\n  \"],[6,\"h2\"],[9,\"class\",\"etw-pt-8 etw-mb-6 etw-text-lg etw-font-bold\"],[7],[1,[18,\"title\"],false],[8],[0,\"\\n\\n  \"],[6,\"ul\"],[9,\"class\",\"etw-list-reset etw-leading-normal\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"codeSnippets\"]]],null,{\"statements\":[[0,\"      \"],[6,\"li\"],[7],[6,\"code\"],[7],[1,[19,2,[]],false],[8],[8],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"etw-mt-8 etw-flex etw-flex-wrap\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"moduleStyles\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"etw/module-style-example\",null,[[\"moduleStyle\"],[[19,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/etw/module-section.hbs"
    }
  });
  _exports.default = _default;
});