define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel-section/component/edit-piping-material", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1V63MYPh",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-panel__section-title\"],[7],[0,\"\\n  \"],[1,[25,\"t\",[\"configurations.general.material\"],null],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"editor-panel__section\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"align align--gutter-xs\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n      \"],[1,[25,\"x-form-control\",null,[[\"type\",\"name\",\"label\",\"value\",\"bindTo\",\"onChange\"],[\"radio\",\"pipingMaterial\",\"Matte\",\"cotton\",[20,[\"pipingMaterial\"]],\"onPipingMaterialChange\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n      \"],[1,[25,\"x-form-control\",null,[[\"type\",\"name\",\"label\",\"value\",\"bindTo\",\"onChange\"],[\"radio\",\"pipingMaterial\",\"Glossy\",\"satin\",[20,[\"pipingMaterial\"]],\"onPipingMaterialChange\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel-section/component/edit-piping-material.hbs"
    }
  });
  _exports.default = _default;
});