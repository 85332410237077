define("bd-hybrid-cloud/components/x-document-list-item", ["exports", "bd-hybrid-cloud/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var token = JSON.parse(localStorage.getItem('ember_simple_auth-session'));
  var _default = Ember.Component.extend({
    classNames: ['document-list-item'],
    actions: {
      crop: function crop() {},
      edit: function edit() {},
      delete: function _delete() {
        var model = this.get('model');
        var onDelete = this.get('onDelete');
        var message = this.get('trans').t('confirm.delete', {
          name: "".concat(model.get('displayName'), ".").concat(model.get('extension'))
        });
        this.get('modal').confirm(message).then(function () {
          model.destroyRecord();
          if (onDelete) {
            onDelete();
          }
        });
      },
      uploadThumb: function uploadThumb(file) {
        var _this = this;
        this.set('file', file);
        file.upload("".concat(_environment.default.api.url, "/documents/").concat(this.get('model').id, "/thumbnail"), {
          headers: {
            Authorization: "".concat(token.authenticated.token_type, " ").concat(token.authenticated.access_token)
          }
        }).then(function (response) {
          var data = JSON.parse(response.body).data;
          _this.set('file', null);
          _this.get('model').set('thumbUrl', data.attributes.thumb_url);
        });
      },
      onBlur: function onBlur() {
        this.get('model').save();
      }
    }
  });
  _exports.default = _default;
});