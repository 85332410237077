define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/section/select-handle-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6CvLT/BK",
    "block": "{\"symbols\":[\"resetScrollPosition\",\"sample\"],\"statements\":[[6,\"div\"],[9,\"class\",\"section\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n\"],[4,\"x-grid-view\",null,[[\"filterIsVisible\",\"hasToggleFilterButton\",\"hasUsageFilter\",\"disableFilters\",\"isCollapsed\",\"items\",\"sort\",\"sortable\",\"sortingAlphabetical\",\"sortingChronological\"],[[20,[\"filterIsVisible\"]],true,true,[20,[\"disableFilters\"]],true,[20,[\"samples\"]],[20,[\"sort\"]],true,\"-id\",\"-updated_at\"]],{\"statements\":[[0,\"      \"],[1,[25,\"x-generic-card\",null,[[\"clickable\",\"imageUrl\",\"isSelected\",\"model\",\"onClick\",\"subtitle\",\"title\"],[true,[19,2,[\"thumbnail\"]],[25,\"eq\",[[20,[\"model\",\"handleLogo\",\"id\"]],[19,2,[\"id\"]]],null],[19,2,[]],[25,\"action\",[[19,0,[]],\"onSampleClick\",[19,2,[]]],null],[25,\"if\",[[19,2,[\"draft\"]],[25,\"t\",[\"general.draft\"],null],[19,2,[\"subtitle\"]]],null],[19,2,[\"title\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[8],[0,\"\\n\"],[4,\"unless\",[[20,[\"disableFilters\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n\"],[4,\"x-reset-scroll-position\",null,[[\"selector\"],[\".editor-panel-tree__body\"]],{\"statements\":[[0,\"        \"],[1,[25,\"x-pagination\",null,[[\"paginationConfig\",\"onPageChange\"],[[20,[\"paginationConfigSamples\"]],[19,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/section/select-handle-logo.hbs"
    }
  });
  _exports.default = _default;
});