define("bd-hybrid-cloud/templates/components/load-child-records", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8bk4FZOc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[19,0,[\"loadRecordsTask\",\"isRunning\"]],[19,0,[\"loadRecordsTask\",\"lastSuccessful\",\"value\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/load-child-records.hbs"
    }
  });
  _exports.default = _default;
});