define("bd-hybrid-cloud/models/configuration", ["exports", "ember-data", "bd-hybrid-cloud/utils/glide", "bgr-ember-json-api", "bgr-ember-json-api/model", "bd-hybrid-cloud/mixins/model/generate-share-url"], function (_exports, _emberData, _glide, _bgrEmberJsonApi, _model, _generateShareUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    hasMany = _emberData.default.hasMany;
  var _default = _model.default.extend(_generateShareUrl.default, {
    /**
     * Props
     */

    backgroundImage: belongsTo('backgroundImage'),
    categories: hasMany('configurationCategory'),
    colors: hasMany('color'),
    concept: belongsTo('concept'),
    coverTemplate: attr('boolean'),
    createdAt: attr('string'),
    identifier: attr('string'),
    json: attr('json'),
    project: belongsTo('project'),
    quiltPatterns: hasMany('quiltPattern'),
    samples: hasMany('sample', {
      meta: _bgrEmberJsonApi.Meta.Model.extend({
        type: _bgrEmberJsonApi.Meta.attr({
          defaultValue: null
        })
      })
    }),
    shareUrl: attr('string'),
    standardTemplate: attr('boolean'),
    pillowTemplate: attr('boolean'),
    thumb: attr('string'),
    thumbnail: computed('thumbUrl', function () {
      var thumb = this.get('thumbUrl');
      return (0, _glide.default)(thumb, {
        fit: 'crop',
        h: 350,
        w: 700
      });
    }),
    thumbUrl: attr('string'),
    title: attr('string'),
    type: attr(),
    user: belongsTo('user'),
    /**
     * Methods
     */
    addRelationship: function addRelationship(modelName, relationshipItems) {
      var _this = this;
      return new Promise(function (resolve) {
        var requests = [];
        var relationshipKey = modelName.pluralize();
        _this.deleteRelationship(relationshipKey);
        relationshipItems.forEach(function (_ref) {
          var id = _ref.id,
            type = _ref.type;
          var request = _this.get('store').seekRecord(modelName, id).then(function (record) {
            var metaProperties = {};
            if (modelName === 'sample') {
              metaProperties = {
                type: type
              };
            }
            _this.addRecordToRelationship(relationshipKey, record, metaProperties);
          }).catch(function () {
            window.console.error("".concat(modelName, " with id ").concat(id, " was not found."));
          });
          requests.push(request);
        });
        Promise.all(requests).then(function () {
          resolve();
        });
      });
    },
    addRelationships: function addRelationships(relationships) {
      var queue = [this.addRelationship('quiltPattern', relationships.quilts), this.addRelationship('sample', relationships.samples)];
      return new Promise(function (resolve) {
        Promise.all(queue).then(function () {
          resolve();
        });
      });
    },
    sendShareEmail: function sendShareEmail(recipients, message) {
      return this.get('api').post("configurations/".concat(this.get('id'), "/share"), {
        emailAddresses: recipients.map(function (item) {
          return item.attributes.email;
        }),
        message: message
      });
    }
  });
  _exports.default = _default;
});