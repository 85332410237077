define("bd-hybrid-cloud/utils/math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.round = round;
  function round(number) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }
  var _default = {
    round: round
  };
  _exports.default = _default;
});