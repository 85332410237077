define("bd-hybrid-cloud/serializers/news-item", ["exports", "bd-hybrid-cloud/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    attrs: {
      content: {
        serialize: false
      },
      date: {
        serialize: false
      },
      description: {
        serialize: false
      },
      intro: {
        serialize: false
      },
      picture: {
        serialize: false
      },
      title: {
        serialize: false
      },
      url: {
        serialize: false
      }
    }
  });
  _exports.default = _default;
});