define("bd-hybrid-cloud/components/x-reset-scroll-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    $ = Ember.$;
  var _default = Ember.Component.extend({
    /**
     * Props
     */
    selector: null,
    /**
     * Computed
     */
    scrollElement: computed('selector', function () {
      return $(this.selector);
    }),
    /**
     * Actions
     */
    actions: {
      resetScrollPosition: function resetScrollPosition() {
        var scrollElement = this.get('scrollElement');
        if (scrollElement && scrollElement.length) {
          scrollElement.scrollTop(0);
        }
      }
    }
  });
  _exports.default = _default;
});