define("bd-hybrid-cloud/templates/components/x-datepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "G+Dxrbco",
    "block": "{\"symbols\":[\"dd\",\"calendar\"],\"statements\":[[4,\"basic-dropdown\",null,[[\"class\",\"renderInPlace\"],[\"datepicker\",true]],{\"statements\":[[0,\"  \"],[6,\"input\"],[10,\"data-ebd-id\",[26,[[19,1,[\"uniqueId\"]],\"-trigger\"]]],[9,\"class\",\"bd-input datepicker__input\"],[9,\"type\",\"text\"],[10,\"placeholder\",[26,[[18,\"placeholder\"]]]],[10,\"value\",[26,[[25,\"if\",[[20,[\"value\"]],[25,\"moment-format\",[[20,[\"value\"]]],null]],null]]]],[10,\"onfocus\",[26,[[19,1,[\"actions\",\"open\"]]]]],[7],[8],[0,\"\\n\\n  \"],[6,\"button\"],[9,\"class\",\"datepicker__button\"],[9,\"type\",\"button\"],[10,\"onclick\",[26,[[19,1,[\"actions\",\"toggle\"]]]]],[7],[8],[0,\"\\n\\n\"],[4,\"component\",[[19,1,[\"content\"]]],null,{\"statements\":[[4,\"power-calendar\",null,[[\"class\",\"center\",\"selected\",\"onCenterChange\",\"onSelect\"],[\"calendar calendar--sm\",[20,[\"center\"]],[20,[\"value\"]],[25,\"action\",[[19,0,[]],\"onCenterChange\"],null],[25,\"action\",[[19,0,[]],\"onSelect\",[19,1,[]]],null]]],{\"statements\":[[0,\"        \"],[1,[19,2,[\"nav\"]],false],[0,\"\\n        \"],[1,[19,2,[\"days\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-datepicker.hbs"
    }
  });
  _exports.default = _default;
});