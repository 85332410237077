define("bd-hybrid-cloud/templates/components/x-error-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sRFtNt6P",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"each\",[[20,[\"errors\"]]],null,{\"statements\":[[0,\"  \"],[6,\"li\"],[9,\"class\",\"error\"],[7],[1,[19,1,[\"detail\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-error-list.hbs"
    }
  });
  _exports.default = _default;
});