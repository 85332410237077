define("bd-hybrid-cloud/components/x-sample-note-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * state
     */

    classNameBindings: ['hasNote:bd-button--solid-blue'],
    classNames: ['bd-button', 'bd-button--grey', 'bd-button--icon-sm', 'bd-button--round-sm'],
    hasNote: false,
    tagName: 'button',
    /**
     * hooks
     */
    init: function init() {
      this._super.apply(this, arguments);
      var meta = this.get('project').peekMeta('samples', this.get('sample.id'));
      if (meta) {
        this.set('hasNote', !!meta.get('note'));
      }
    },
    /**
     * methods
     */
    click: function click() {
      var _this = this;
      this.get('modal').show('project-sample-note', {
        project: this.get('project'),
        sample: this.get('sample')
      }).then(function (note) {
        _this.set('hasNote', !!note);
      });
    }
  });
  _exports.default = _default;
});