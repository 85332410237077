define("bd-hybrid-cloud/tailwind/config/line-height", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  |-----------------------------------------------------------------------------
  | Leading (line height)              https://tailwindcss.com/docs/line-height
  |-----------------------------------------------------------------------------
  |
  | Here is where you define your line height values, or as we call
  | them in Tailwind, leadings.
  |
  | Class name: .leading-{size}
  | CSS property: line-height
  |
  */
  var _default = {
    'none': 1,
    'tight': 1.25,
    'normal': 1.5,
    'relaxed': 1.75,
    'loose': 2
  };
  _exports.default = _default;
});