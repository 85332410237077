define("bd-hybrid-cloud/templates/components/sortable-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zYLp+sf1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"sortable-objects\",null,[[\"sortableObjectList\",\"tagName\",\"sortEndAction\",\"__ANGLE_ATTRS__\"],[[19,0,[\"sortableItems\"]],[19,0,[\"elementTagName\"]],[25,\"action\",[[19,0,[]],[19,0,[\"onUpdate\"]],[19,0,[\"sortableItems\"]]],null],[25,\"hash\",null,[[\"class\"],[[19,0,[\"className\"]]]]]]],{\"statements\":[[0,\"\\n  \"],[11,1,[[19,0,[\"sortableItems\"]],[25,\"component\",[\"draggable-object\"],null]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/sortable-view.hbs"
    }
  });
  _exports.default = _default;
});