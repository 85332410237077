define("bd-hybrid-cloud/components/x-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed;
  var positions = ['top', 'bottom'];
  var positionError = "Tooltip: Invalid position. Please provide one of the following positions: ".concat(positions.join(', '), ".");
  var _default = Component.extend({
    /**
     * Options
     */

    position: 'top',
    text: '',
    /**
     * State
     */

    classNameBindings: ['positionModifierClass'],
    classNames: ['tooltip'],
    /**
     * Computed
     */

    positionModifierClass: computed('position', function () {
      return "tooltip--".concat(this.get('position'));
    }),
    /**
     * Events
     */
    didInsertElement: function didInsertElement() {
      var position = this.get('position');
      if (!positions.includes(position)) {
        throw new Error(positionError);
      }
      this.$().parent().attr('tooltip', '');
    },
    willDestroyElement: function willDestroyElement() {
      this.$().parent().removeAttr('tooltip');
    }
  });
  _exports.default = _default;
});