define("bd-hybrid-cloud/routes/app", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route,
    RSVP = Ember.RSVP;
  var _default = Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel(transition) {
      var _this = this;
      this._super(transition);
      var promise;
      if (this.get('session.isAuthenticated')) {
        var store = this.get('store');
        promise = RSVP.hash({
          model: store.queryRecord('user', {
            active: true,
            include: 'cameraViews'
          }),
          permissions: store.findAll('permission')
        }).then(function (response) {
          _this.get('activeUser').setProperties(response);
        });
      }
      return promise;
    }
  });
  _exports.default = _default;
});