define("bd-hybrid-cloud/controllers/app/configuration/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    Controller = Ember.Controller,
    isEqual = Ember.isEqual;
  var _default = Controller.extend({
    /**
     * State
     */

    configurationCategory: '',
    queryParams: ['configurationCategory', 'type'],
    type: '',
    /**
     * Computed
     */

    allowDeleteConfigurations: computed.or('allowDeleteCoverSolutions', 'allowDeletePlayground'),
    allowDeleteCoverSolutions: computed('type', 'activeUser.can.deleteCoverSolutions', function () {
      return this.get('type') === 'cover-solutions' && this.get('activeUser.can.deleteCoverSolutions');
    }),
    allowDeletePlayground: computed('type', 'activeUser.can.deletePlayground', function () {
      return this.get('type') === 'playground' && this.get('activeUser.can.deletePlayground');
    }),
    configurationCategories: computed('configurationCategory', 'model.configurationCategories', 'type', function () {
      var categories = [];
      var categoryId = this.get('configurationCategory');
      if (categoryId) {
        var parentCategory = this.get('currentConfigurationCategory');
        categories = parentCategory.get('children');
      } else {
        categories = this.get('model.configurationCategories').filter(function (category) {
          return category.get('isParent');
        });
      }
      return categories;
    }),
    configurations: computed('currentConfigurationCategory', 'type', function () {
      var type = this.get('type');
      return type === 'playground' || type === 'pillows' ? this.get('model') : this.get('currentConfigurationCategory.configurations');
    }),
    currentConfigurationCategory: computed('configurationCategory', function () {
      var _this = this;
      var currentConfigurationCategory;
      var configurationCategories = this.get('model.configurationCategories');
      if (configurationCategories) {
        currentConfigurationCategory = configurationCategories.find(function (category) {
          return isEqual(category.id, _this.get('configurationCategory'));
        });
      }
      return currentConfigurationCategory;
    }),
    displayCategoryList: computed.or('hasNoConfigurationCategory', 'currentConfigurationCategory.isParent'),
    editorDescription: computed.alias('currentConfigurationCategory.description'),
    editorTitle: computed('currentConfigurationCategory', 'type', function () {
      var editorTitle;
      var type = this.get('type').camelize();
      var currentConfigurationCategoryTitle = this.get('currentConfigurationCategory.name');
      if (currentConfigurationCategoryTitle) {
        editorTitle = currentConfigurationCategoryTitle;
      } else {
        editorTitle = this.get('trans').t("create.".concat(type, ".title"));
      }
      return editorTitle;
    }),
    hasNoConfigurationCategory: computed.not('currentConfigurationCategory'),
    partialName: computed('type', function () {
      return "partials/configuration/create/".concat(this.get('type'));
    }),
    /**
     * Actions
     */

    actions: {
      deleteConfiguration: function deleteConfiguration(configuration) {
        var _this2 = this;
        var trans = this.get('trans');
        var transKey = configuration.get('coverTemplate') ? 'coverSolution' : 'playground';
        var name = configuration.get('title');
        var entity = trans.t("modelNames.".concat(transKey));
        var message = trans.t('confirm.deleteWithEntity', {
          entity: entity,
          name: name
        });
        this.get('modal').confirm(message).then(function () {
          configuration.destroyRecord().then(function () {
            _this2.get('flashMessages').success(trans.t('alert.success.deletedEntityWithName', {
              entity: entity,
              name: name
            }));
          });
        });
      },
      goBack: function goBack() {
        var configurationCategory = '';
        var parentId = this.get('currentConfigurationCategory.parentId');
        if (parentId) {
          configurationCategory = parentId;
        }
        this.set('configurationCategory', configurationCategory);
      },
      openConfigurationCategory: function openConfigurationCategory(category) {
        this.set('configurationCategory', category.get('id'));
      },
      selectModel: function selectModel(model) {
        // TODO: Possible functionality to route to /new
        // this.get('store').createRecord('configuration', {
        //   json: model.get('json'),
        //   title: model.get('title'),
        // }).save().then((newConfiguration) => {
        //   this.transitionToRoute('app.configuration.configuration.edit', newConfiguration);
        // });
        //
        // const newConfiguration = this.get('store').createRecord('configuration', {
        //   json: model.get('json'),
        //   title: model.get('title'),
        // });

        this.transitionToRoute('app.configuration.configuration.edit', model);
      }
    },
    /**
     * Methods
     */
    reset: function reset() {
      this.setProperties({
        configurationCategory: '',
        type: ''
      });
    }
  });
  _exports.default = _default;
});