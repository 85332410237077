define("bd-hybrid-cloud/routes/app/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.get('store').query('notification', {
        page: params.page,
        sort: 'read,-created_at'
      });
    }
  });
  _exports.default = _default;
});