define("bd-hybrid-cloud/adapters/application", ["exports", "ember-inflector", "bd-hybrid-cloud/config/environment", "bgr-ember-json-api/adapter", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberInflector, _environment, _adapter, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _config$api = _environment.default.api,
    host = _config$api.host,
    namespace = _config$api.namespace;
  var _default = _adapter.default.extend(_dataAdapterMixin.default, {
    /**
     * State
     */

    host: host,
    namespace: namespace,
    /**
     * Hooks
     */
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
        access_token = _this$get.access_token;
      xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
    },
    createEndpoint: function createEndpoint(path) {
      return "".concat(host, "/").concat(namespace, "/").concat(path);
    },
    urlForQuery: function urlForQuery(query, modelName) {
      var urlForQuery;
      if (query.search) {
        // Temporarily preserve the "search" property on the query object
        // As this will currently permanently delete it from the object
        // causing a side effect where the current logic is not executed
        // anymore a second time.
        // Reflect.deleteProperty(query, 'search');

        urlForQuery = this.createEndpoint("".concat((0, _emberInflector.pluralize)(modelName), "/search"));
      } else {
        urlForQuery = this._super(query, modelName);
      }
      return urlForQuery;
    }
  });
  _exports.default = _default;
});