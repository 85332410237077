define("bd-hybrid-cloud/models/country", ["exports", "ember-data", "bgr-ember-json-api/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany;
  var _default = _model.default.extend({
    iso: attr('string'),
    name: attr('string'),
    regionId: attr('number'),
    salesReps: hasMany('user', {
      inverse: 'salesCountry'
    })
  });
  _exports.default = _default;
});