define("bd-hybrid-cloud/services/filter-query", ["exports", "bd-hybrid-cloud/config/filter-query-params"], function (_exports, _filterQueryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var _default = Ember.Service.extend({
    /**
     * Services
     */

    routerService: Ember.inject.service('router'),
    /**
     * State
     */

    controller: null,
    queryParamsCache: {
      quilts: null,
      samples: null
    },
    /**
     * Methods
     */
    // TODO: REMOVE THIS WHEN REFACTORING THE CONFIGURATOR EDIT ROUTES
    // queryParamsCache, setCachedQueryParams and storeQueryParams are used as a
    // temporary "hack" to preserve the query-params for the samples and quilts filter
    // on the configurator page.
    applyCachedQueryParamsToController: function applyCachedQueryParamsToController(section) {
      var cachedQueryParams = this.get("queryParamsCache.".concat(section));
      if (cachedQueryParams) {
        this.get('controller').setProperties(cachedQueryParams);
      }
    },
    storeQueryParams: function storeQueryParams(section) {
      var controller = this.get('controller');
      (true && !(controller) && Ember.assert('service:filter-query:storeQueryParams() | A controller is required', controller));
      (true && !(section) && Ember.assert('service:filter-query:storeQueryParams() | The "section" argument is required', section));
      this.set("queryParamsCache.".concat(section), controller.getProperties.apply(controller, _toConsumableArray(_filterQueryParams.FILTER_QUERY_PARAMS)));
    }
  });
  _exports.default = _default;
});