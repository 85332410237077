define("bd-hybrid-cloud/templates/partials/share/detail-panel-section/component/detail-ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "G8qAL4VQ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-panel__section u-capitalize\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"editor__subtitle u-text-center u-capitalize\"],[7],[0,\"\\n    \"],[1,[25,\"t\",[\"details.ribbon\"],null],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n    \"],[6,\"table\"],[9,\"class\",\"table\"],[7],[0,\"\\n      \"],[6,\"tr\"],[9,\"class\",\"table__row\"],[7],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"table__cell\"],[7],[0,\"\\n          \"],[1,[25,\"t\",[\"details.ribbonMaterial\"],null],false],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"table__cell\"],[7],[0,\"\\n          \"],[1,[18,\"ribbonMaterialName\"],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"tr\"],[9,\"class\",\"table__row\"],[7],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"table__cell\"],[7],[0,\"\\n          \"],[1,[25,\"t\",[\"details.color\"],null],false],[0,\":\\n        \"],[8],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"table__cell\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"align align--gutter-xs\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"color-picker__button\"],[10,\"style\",[25,\"style\",null,[[\"backgroundColor\"],[[20,[\"selectedColor\",\"color\"]]]]],null],[7],[8],[0,\"\\n            \"],[8],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n              \"],[1,[20,[\"selectedColor\",\"id\"]],false],[0,\"\\n            \"],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/share/detail-panel-section/component/detail-ribbon.hbs"
    }
  });
  _exports.default = _default;
});