define("bd-hybrid-cloud/components/x-type-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Props
     */

    icon: '',
    text: '',
    title: '',
    /**
     * State
     */

    classNames: ['block-grid__item']
  });
  _exports.default = _default;
});