define("bd-hybrid-cloud/serializers/sample", ["exports", "bd-hybrid-cloud/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      var recordIsNew = snapshot.record.get('isNew');
      if (recordIsNew) {
        json.data.attributes.id = json.data.id || null;
      }
      return json;
    }
  });
  _exports.default = _default;
});