define("bd-hybrid-cloud/mixins/filter-query", ["exports", "bd-hybrid-cloud/config/filter-query-params"], function (_exports, _filterQueryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var computed = Ember.computed,
    Mixin = Ember.Mixin;
  var _default = Mixin.create({
    /**
     * State
     */

    queryParams: [].concat(_toConsumableArray(_filterQueryParams.FILTER_QUERY_PARAMS), ['page', 'sort']),
    brand: [],
    collection: [],
    color: [],
    composition: [],
    country: [],
    density: '',
    endsMin: '',
    endsMax: '',
    finishing: [],
    page: 1,
    picksMin: '',
    picksMax: '',
    private: false,
    project: [],
    projects: [],
    q: '',
    region: [],
    categories: [],
    sort: '-updated_at',
    style: [],
    tag: [],
    technology: null,
    theme: [],
    weightMin: '',
    weightMax: '',
    widthMin: '',
    widthMax: '',
    /**
     * Computed
     */

    hasQueryParams: computed('model', function () {
      var _this = this;
      return _filterQueryParams.FILTER_QUERY_PARAMS.map(function (item) {
        return Ember.isPresent(_this.get(item));
      }).some(function (item) {
        return item;
      });
    }),
    shouldDisplayResults: computed.or('hasQueryParams', 'model')
  });
  _exports.default = _default;
});