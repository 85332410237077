define("bd-hybrid-cloud/mixins/route/reset-selected-samples", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    actions: {
      willTransition: function willTransition(transition) {
        if (!transition.queryParamsOnly) {
          this.controllerFor(this.controllerFor('application').get('currentRouteName')).send('clearSelectedSamples');
        }
      }
    }
  });
  _exports.default = _default;
});