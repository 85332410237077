define("bd-hybrid-cloud/components/x-grid-quilts-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed,
    inject = Ember.inject,
    isArray = Ember.isArray,
    isBlank = Ember.isBlank,
    isPresent = Ember.isPresent;
  var PER_PAGE = 1000;
  var FILTER_MODELS = [{
    condition: 'includeProjects',
    dataProp: 'projects',
    fetch: 'fromRecord',
    modelName: 'project',
    queryProp: 'selectedProjects',
    queryParam: 'projects',
    queryOptions: {
      filter: {
        archived: false
      },
      per_page: PER_PAGE
    }
  }, {
    modelName: 'q',
    queryProp: 'query',
    queryParam: 'q'
  }, {
    dataProp: 'categories',
    fetch: 'fromRecord',
    modelName: 'quilt-pattern-category',
    queryProp: 'selectedCategories',
    queryParam: 'categories'
  }];
  var _default = Component.extend({
    /**
     * Props
     */

    searchPlaceholder: '',
    /**
     * Injections
     */

    filterQuery: inject.service(),
    /**
     * State
     */

    brands: [],
    classNameBindings: ['standalone:grid-view__filter--standalone'],
    classNames: ['grid-view__filter'],
    categories: [],
    filteredProjects: computed.filterBy('projects', 'archived', false),
    includeProjects: false,
    private: false,
    projects: [],
    query: '',
    selectedCategories: [],
    selectedProjects: [],
    shouldCacheQueryParams: false,
    shouldSetCachedQueryParams: false,
    sortedConfig: ['name:asc'],
    sortedProjectsConfig: ['title:asc'],
    sortedCategories: computed.sort('collections', 'sortedConfig'),
    sortedProjects: computed.sort('filteredProjects', 'sortedProjectsConfig'),
    standalone: false,
    /**
     * Events
     */
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      if (this.get('shouldSetCachedQueryParams')) {
        this.get('filterQuery').applyCachedQueryParamsToController('quilts');
      }
      this.setFilterModels().then(function () {
        _this.readQueryParams();
      });
    },
    willDestroy: function willDestroy() {
      if (this.get('shouldCacheQueryParams')) {
        this.get('filterQuery').storeQueryParams('quilts');
      }
      this.send('clearFilter');
    },
    /**
     * Methods
     */
    readQueryParams: function readQueryParams() {
      var _this2 = this;
      var filterQueryController = this.get('filterQuery.controller');
      FILTER_MODELS.forEach(function (item) {
        var queryValue;
        var modelName = item.modelName;
        var queryParamName = item.queryParam;
        var queryProp = item.queryProp;
        var queryParam = _this2.get(queryProp);
        if (item.type === 'range') {
          var minQueryVal = filterQueryController.get("".concat(modelName, "Min"));
          var maxQueryVal = filterQueryController.get("".concat(modelName, "Max"));
          if (isPresent(minQueryVal) && isPresent(maxQueryVal)) {
            queryValue = minQueryVal === maxQueryVal ? minQueryVal : "".concat(minQueryVal, "-").concat(maxQueryVal);
          }
        } else {
          queryValue = filterQueryController.get(queryParamName);
        }
        if (!isBlank(queryValue)) {
          if (isArray(queryParam)) {
            var selectedQueryItems = _this2.get(item.dataProp).filter(function (dataItem) {
              var filterProp = Number(dataItem.id) || dataItem.id || dataItem;
              return queryValue.includes(filterProp);
            });
            _this2.set(queryProp, selectedQueryItems);
          } else {
            _this2.set(queryProp, queryValue);
          }
        }
      });
    },
    setFilterModels: function setFilterModels() {
      var _this3 = this;
      var queue = [];
      var store = this.get('store');
      FILTER_MODELS.forEach(function (item) {
        var condition = item.condition,
          dataProp = item.dataProp,
          fetch = item.fetch,
          modelName = item.modelName,
          _item$queryOptions = item.queryOptions,
          queryOptions = _item$queryOptions === void 0 ? {} : _item$queryOptions;
        if (fetch && (!condition || _this3.get(condition))) {
          if (fetch === 'fromRecord') {
            queue.push(store.query(modelName, queryOptions).then(function (model) {
              _this3.set(dataProp, model);
            }));
          }
        }
      });
      return Promise.all(queue);
    },
    submitFilter: function submitFilter() {
      var _this4 = this;
      var queryParams = {
        page: 1
      };
      FILTER_MODELS.forEach(function (item) {
        var queryValue;
        var modelName = item.modelName;
        var queryParamName = item.queryParam;
        var queryParam = _this4.get(item.queryProp);
        if (item.type === 'range') {
          if (queryParam) {
            var split = queryParam.split('-');
            var min = split[0];
            var max = split[1] || min;
            queryParams["".concat(modelName, "Min")] = min;
            queryParams["".concat(modelName, "Max")] = max;
          } else {
            _this4.set(item.queryProp, '');
            queryParams["".concat(modelName, "Min")] = '';
            queryParams["".concat(modelName, "Max")] = '';
          }
        } else {
          if (isArray(queryParam)) {
            queryValue = queryParam.map(function (queryItem) {
              var id = queryItem.id;
              return Number(id) || id || queryItem;
            });
          } else {
            queryValue = queryParam;
          }
          queryParams[queryParamName] = queryValue;
        }
      });
      this.get('filterQuery.controller').setProperties(queryParams);
    },
    /**
     * Actions
     */

    actions: {
      clearFilter: function clearFilter() {
        var _this5 = this;
        FILTER_MODELS.forEach(function (item) {
          var queryProp = item.queryProp;
          var queryParam = _this5.get(queryProp);
          if (!isBlank(queryParam)) {
            var resetValue = isArray(queryParam) ? [] : '';
            _this5.set(queryProp, resetValue);
          }
        });
        this.submitFilter();
      },
      onChange: function onChange(propertyName, value) {
        this.set(propertyName, value);
        this.submitFilter();
      },
      submitFilter: function submitFilter() {
        this.submitFilter();
      }
    }
  });
  _exports.default = _default;
});