define("bd-hybrid-cloud/templates/components/help-video-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YRDTIscF",
    "block": "{\"symbols\":[],\"statements\":[[6,\"article\"],[3,\"_splattributes\",[[25,\"-merge-attrs\",[[25,\"hash\",null,[[\"class\"],[\"flex flex-col group relative shadow\"]]],[20,[\"__ANGLE_ATTRS__\"]]],null]]],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"bg-center bg-cover flex items-center pt-60% relative\"],[10,\"style\",[26,[\"background-image: url(\",[19,0,[\"thumbnail\"]],\");\"]]],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"absolute flex items-center justify-center pin\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"text-5xl text-white\"],[7],[0,\"\\n        ▶\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"bg-white flex flex-1 flex-col p-5\"],[7],[0,\"\\n    \"],[6,\"h3\"],[9,\"class\",\"leading-relaxed text-base text-blue-darker\"],[7],[0,\"\\n      \"],[1,[19,0,[\"title\"]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"flex-1 leading-normal pt-2 text-gray-dark text-sm\"],[7],[0,\"\\n      \"],[1,[19,0,[\"description\"]],true],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"pt-4\"],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"bg-gray-lightest border border-gray-light px-2 py-0.5 rounded-full text-gray-dark text-xs\"],[7],[0,\"\\n        \"],[1,[19,0,[\"duration\"]],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"absolute opacity-0 pin shadow-md transition-opacity z--1 group-hover:opacity-100\"],[7],[8],[0,\"\\n  \"],[6,\"button\"],[9,\"class\",\"absolute h-full pin-l pin-t w-full focus:outline-none focus:shadow-outline\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],[19,0,[\"onClickHandler\"]]],null],null],[7],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/help-video-card.hbs"
    }
  });
  _exports.default = _default;
});