define("bd-hybrid-cloud/components/x-grid-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed,
    inject = Ember.inject,
    on = Ember.on;
  var _default = Component.extend({
    /**
     * Injections
     */

    loadingState: inject.service(),
    /**
     * Props
     */

    areItemsLoading: false,
    columnWidth: 4,
    filterable: false,
    filterIsVisible: false,
    hasRegionsFilter: false,
    hasProductsFilter: true,
    hasToggleFilterButton: false,
    hasUsageFilter: false,
    includeProjects: false,
    isCollapsed: false,
    isSortable: false,
    items: [],
    onlyProducts: false,
    onSortUpdate: function onSortUpdate() {},
    searchPlaceholder: '',
    sort: '',
    sortable: false,
    sortingAlphabetical: '',
    sortingChronological: '-updated_at',
    /**
     * State
     */

    classNames: ['container', 'grid-view'],
    /**
     * Computed
     */

    blockGridClass: computed('isCollapsed', 'blockGridColumnClass', function () {
      var blockGridClass;
      if (this.get('isCollapsed')) {
        blockGridClass = 'block-grid--is-collapsed';
      }
      blockGridClass = "".concat(blockGridClass, " ").concat(this.get('blockGridColumnClass'));
      return blockGridClass;
    }),
    blockGridColumnClass: computed('filterIsVisible', 'isCollapsed', function () {
      return this.get('filterIsVisible') || this.get('isCollapsed') ? 'block-grid--6' : this.get('defaultColumnClass');
    }),
    canFilter: computed.and('filterIsVisible', 'filterable'),
    defaultColumnClass: computed('columnWidth', function () {
      return "block-grid--".concat(this.get('columnWidth'));
    }),
    gridClass: computed('canFilter', 'isCollapsed', function () {
      var gridClass;
      if (this.get('isCollapsed')) {
        gridClass = '';
      } else {
        gridClass = this.get('canFilter') ? 'gr-9' : 'gr-12';
      }
      return gridClass;
    }),
    isLoading: Ember.computed.or('areItemsLoading', 'loadingState.isLoading'),
    showToggleFilterButton: Ember.computed.or('filterable', 'hasToggleFilterButton'),
    /**
     * Events
     */

    didDestroyElementHandler: on('didDestroyElement', function () {
      this.set('filterIsVisible', false);
    }),
    /**
     * Actions
     */

    actions: {
      toggleFilter: function toggleFilter() {
        this.toggleProperty('filterIsVisible');
      }
    }
  });
  _exports.default = _default;
});