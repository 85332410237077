define("bd-hybrid-cloud/templates/components/modals/x-add-to-capsules-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IuyczX3H",
    "block": "{\"symbols\":[\"region\"],\"statements\":[[4,\"bgr-modal-template\",null,null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"modal__row\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"modal__description\"],[7],[0,\"\\n      \"],[1,[25,\"t\",[\"confirm.addModelWithoutNameToEntity\"],[[\"model\",\"entity\"],[[20,[\"model\",\"title\"]],[25,\"t\",[\"modelNames.capsules\"],null]]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"model__row\"],[7],[0,\"\\n\"],[4,\"x-select\",null,[[\"onchange\",\"options\",\"placeholder\",\"selected\"],[[25,\"action\",[[19,0,[]],[25,\"mut\",[[19,0,[\"selectedRegion\"]]],null]],null],[19,0,[\"regions\"]],[25,\"t\",[\"forms.placeholders.selectEntity\"],[[\"entity\"],[[25,\"t\",[\"modelNames.region\"],null]]]],[19,0,[\"selectedRegion\"]]]],{\"statements\":[[0,\"      \"],[1,[19,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal__row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align align--centered align--gutter-sm\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--regular bd-button--stroked-blue\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"hide\"],null],null],[7],[0,\"\\n          \"],[1,[25,\"t\",[\"actions.cancel\"],null],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--regular bd-button--solid-blue\"],[10,\"disabled\",[25,\"not\",[[19,0,[\"selectedRegion\"]]],null],null],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"addProjectToCapsules\"],null],null],[7],[0,\"\\n          \"],[1,[25,\"t\",[\"actions.confirm\"],null],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/modals/x-add-to-capsules-modal.hbs"
    }
  });
  _exports.default = _default;
});