define("bd-hybrid-cloud/components/x-app-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * State
     */

    classNames: ['app-header'],
    tagName: 'header',
    /**
     * Props
     */

    notifications: null,
    unreadNotifications: Ember.computed.filterBy('notifications', 'read', 0),
    hasUnreadNotifications: Ember.computed.bool('unreadNotifications.length'),
    /**
     * Actions
     */

    actions: {
      logout: function logout() {
        this.get('session').invalidate();
      },
      settings: function settings() {
        this.get('router').transitionTo('app.settings');
      }
    },
    calculatePosition: function calculatePosition(trigger) {
      var style = window.getComputedStyle(trigger);
      var top = parseInt(style.getPropertyValue('height'), 10);
      var left = trigger.offsetLeft;
      return {
        horizontalPosition: 'left',
        verticalPosition: 'below',
        style: {
          top: top,
          left: left
        }
      };
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this.get('store').query('notification', {
        sort: 'read,-created_at',
        per_page: 20
      }).then(function (notifications) {
        _this.set('notifications', notifications);
      });
    }
  });
  _exports.default = _default;
});