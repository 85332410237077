define("bd-hybrid-cloud/templates/partials/share/detail-panel-section/component/detail-quilt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5at3O1Fq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\",\"quilt\"]]],null,{\"statements\":[[6,\"div\"],[9,\"class\",\"editor-panel__section\"],[7],[0,\"\\n  \"],[1,[25,\"x-configuration-quilt-detail\",null,[[\"model\"],[[20,[\"model\",\"quilt\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/share/detail-panel-section/component/detail-quilt.hbs"
    }
  });
  _exports.default = _default;
});