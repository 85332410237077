define("bd-hybrid-cloud/components/x-concept-grid-view", ["exports", "bd-hybrid-cloud/mixins/show-sample"], function (_exports, _showSample) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CROP = '?w=281&h=141&fit=crop';
  var CROP_WIDE = '?w=577&h=298&fit=crop';
  var CROP_WIDE_LG = '?w=874&h=296&fit=crop';
  var FALLBACK_IMAGE_URL = '/assets/images/bekaertdeslee.jpg';
  var TYPES = [{
    crop: CROP_WIDE_LG,
    imageWidth: 9,
    samples: 2,
    sampleWidth: 12
  }, {
    crop: CROP_WIDE,
    imageWidth: 6,
    samples: 4,
    sampleWidth: 6
  }];
  var Component = Ember.Component,
    computed = Ember.computed;
  var _default = Component.extend(_showSample.default, {
    /**
     * Props
     */

    images: [],
    samples: [],
    /**
     * Methods
     */
    getSampleURL: function getSampleURL(sample) {
      var crop = sample.get('thumbnail');
      if (!crop) {
        return FALLBACK_IMAGE_URL;
      }
      return crop;
    },
    /**
     * Computed
     */

    rows: computed('images.isLoaded', 'samples.isLoaded', function () {
      var _this = this;
      var images = this.get('images') || [];
      var samples = this.get('samples') || [];
      var rows = [];
      var imagesLength = images.get('length');
      var samplesLength = samples.get('length');
      var reversed = false;

      // Check whether concept has images.
      // Create custom grid if true.
      if (imagesLength) {
        var typeIndex = 0;
        var sampleIndex = 0;
        var imageIndex = 0;
        images.forEach(function () {
          if (!TYPES[typeIndex]) {
            typeIndex = 0;
          }

          // Set correct layout type
          // Create new row for every image
          var activeType = TYPES[typeIndex];
          var row = [{
            subitems: [],
            width: 12 - activeType.imageWidth
          }];

          // Add the correct amount of samples to row, correspending to layout type
          for (var i = 0; i < activeType.samples; i += 1) {
            var sample = samples.objectAt(sampleIndex);
            if (sample) {
              row[0].subitems.push({
                sample: sample,
                id: sample.get('id'),
                type: 'sample',
                thumb: _this.getSampleURL(sample),
                width: activeType.sampleWidth
              });
              sampleIndex += 1;
            } else {
              var _image = images.objectAt(imageIndex);
              if (_image) {
                row[0].subitems.push({
                  image: _image,
                  id: _image.get('id'),
                  type: 'image',
                  thumb: _image.get('croppedImage'),
                  width: activeType.sampleWidth,
                  crop: CROP
                });
                imageIndex += 1;
              }
            }
          }

          // Add image to row, reverse image-samples order every 2 rows.
          if (imageIndex % 2 === 0 && imageIndex > 0) {
            reversed = !reversed;
          }
          var image = images.objectAt(imageIndex);
          if (image) {
            var imageObject = {
              image: image,
              id: image.get('id'),
              type: 'image',
              thumb: image.get('croppedImage'),
              width: activeType.imageWidth,
              crop: activeType.crop
            };
            if (reversed) {
              row.push(imageObject);
            } else {
              row.unshift(imageObject);
            }
          }
          rows.push(row);
          typeIndex += 1;

          // After every row, add a row with 4 samples.
          // Add images to row if no more samples are available.
          var thumbRow = [];
          for (var _i = 0; _i < 4; _i += 1) {
            var _sample = samples.objectAt(sampleIndex);
            if (_sample) {
              thumbRow.push({
                sample: _sample,
                id: _sample.get('id'),
                type: 'sample',
                thumb: _this.getSampleURL(_sample),
                width: 3
              });
              sampleIndex += 1;
            } else {
              imageIndex += 1;
              var remainingImage = images.objectAt(imageIndex);
              if (remainingImage) {
                thumbRow.push({
                  image: remainingImage,
                  id: remainingImage.get('id'),
                  type: 'image',
                  thumb: remainingImage.get('croppedImage'),
                  width: 3,
                  crop: CROP
                });
              }
            }
          }
          if (thumbRow.length) {
            rows.push(thumbRow);
          }
          imageIndex += 1;
        });

        // Fill grid with remaining samples
        if (samplesLength > sampleIndex) {
          var row = [];
          var remaining = samplesLength - sampleIndex;
          for (var i = 0; i < remaining; i += 1) {
            var sample = samples.objectAt(sampleIndex);
            if (sample) {
              row.push({
                sample: sample,
                id: sample.get('id'),
                type: 'sample',
                thumb: this.getSampleURL(sample),
                width: 3
              });
              sampleIndex += 1;
            }
          }
          rows.push(row);
        }
      } else if (samplesLength) {
        // Concept has no images, show samples only.
        var currentRow = null;
        samples.forEach(function (sample, index) {
          if (index % 4 === 0) {
            var _row = [{
              sample: sample,
              id: sample.get('id'),
              type: 'sample',
              thumb: _this.getSampleURL(sample),
              width: 3
            }];
            currentRow = _row;
            rows.push(_row);
          } else {
            currentRow.push({
              sample: sample,
              id: sample.get('id'),
              type: 'sample',
              thumb: _this.getSampleURL(sample),
              width: 3
            });
          }
        });
      }
      return rows.filter(function (row) {
        if (row.length) {
          return row.some(function (_ref) {
            var thumb = _ref.thumb,
              _ref$subitems = _ref.subitems,
              subitems = _ref$subitems === void 0 ? [] : _ref$subitems;
            return Boolean(thumb || subitems.lenth);
          });
        }
        return false;
      });
    }),
    actions: {
      showDetail: function showDetail(item) {
        if (item.type === 'sample') {
          this.get('modal').show('sample', {
            sampleId: item.id,
            sampleIds: this.get('samples').map(function (mappedSample) {
              return mappedSample.get('id');
            })
          });
        } else {
          var activeImageIndex = this.get('images').indexOf(item.image);
          var modalImages = this.get('images').map(function (image) {
            return {
              caption: image.get('caption'),
              url: image.get('croppedImage')
            };
          });
          this.get('modal').show('image', {
            activeImageIndex: activeImageIndex,
            images: modalImages
          });
        }
      }
    }
  });
  _exports.default = _default;
});