define("bd-hybrid-cloud/templates/components/x-collapsible-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ubFohf6T",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[25,\"if\",[[19,0,[\"shouldCollapseItems\"]],[19,0,[\"visibleItems\"]],[19,0,[\"items\"]]],null],[25,\"action\",[[19,0,[]],\"toggle\"],null],[20,[\"isCollapsed\"]],[20,[\"isCollapsible\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-collapsible-view.hbs"
    }
  });
  _exports.default = _default;
});