define("bd-hybrid-cloud/components/x-share-sample-detail", ["exports", "bd-hybrid-cloud/mixins/component/configuration-sample-detail"], function (_exports, _configurationSampleDetail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_configurationSampleDetail.default, {
    /**
     * Props
     */

    showHeader: true
  });
  _exports.default = _default;
});