define("bd-hybrid-cloud/helpers/has-or-is", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isArray = Ember.isArray;
  var _default = Ember.Helper.extend({
    compute: function compute(params, hash) {
      this.setupRecompute(params[0]);
      return this.computeHook(params, hash);
    },
    computeHook: function computeHook(params) {
      var result;
      if (isArray(params[0])) {
        result = params[0].includes(params[1]);
      } else {
        result = params[0] === params[1];
      }
      return result;
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);
      if (this.teardown) {
        this.teardown();
      }
    },
    setupRecompute: function setupRecompute(parent) {
      if (isArray(parent)) {
        if (this.teardown) {
          this.teardown();
        }
        var args = ['length', this, this.recompute];
        parent.addObserver.apply(parent, args);
        this.teardown = function () {
          return parent.removeObserver.apply(parent, args);
        };
      }
    }
  });
  _exports.default = _default;
});