define("bd-hybrid-cloud/components/x-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed,
    observer = Ember.observer;
  function transKey(key) {
    return "activityLog.".concat(key);
  }
  var _default = Component.extend({
    /**
     * Props
     */

    model: null,
    showProject: false,
    showNotifcation: false,
    /**
     * State
     */

    classNameBindings: ['hasNotificationClass', 'showProjectClass'],
    classNames: ['log'],
    /**
     * Computed
     */

    sentence: computed('model.{action,name,type,project,multiple}', 'showProject', 'trans.locale', function () {
      var trans = this.get('trans');
      var model = this.get('model');
      var action = model.get('action');
      var multiple = model.get('multiple');
      var amount = model.get('amount') || 1;
      var name = model.get('name');
      var type = model.get('type');
      var project = model.get('project');
      var sentence = [trans.t(transKey(action.camelize()))];
      if (action !== 'update-description' && action !== 'update-title') {
        sentence.push(trans.t(transKey(type), {
          count: amount
        }));
      }
      if (!multiple) {
        sentence.push("<b>\"".concat(name, "\"</b>"));
      }
      if (this.get('showProject') && project.get('title')) {
        sentence.push(trans.t(transKey("".concat(action.camelize(), "Project"))));
        sentence.push("<b>\"".concat(project.get('title'), "\"</b>"));
      }
      return sentence.join(' ').htmlSafe();
    }),
    hasNotification: computed('model.{read}', 'showNotifcation', function () {
      var model = this.get('model');
      return !model.get('read') && this.get('showNotifcation');
    }),
    hasNotificationClass: computed('hasNotification', function () {
      var hasNotificationClass;
      if (this.get('hasNotification')) {
        hasNotificationClass = 'log--has-notification';
      }
      return hasNotificationClass;
    }),
    showProjectClass: computed('showProject', function () {
      var showProjectClass;
      if (this.get('showProject')) {
        showProjectClass = 'log--show-project';
      }
      return showProjectClass;
    }),
    /**
     * Methods
     */
    click: function click() {
      if (this.get('showProject')) {
        var model = this.get('model');
        model.set('read', 1);
        model.save();
        this.get('router').transitionTo('app.projects.project', this.get('model.project.id'));
      }
    },
    modelObserver: observer('model', function () {
      var _this = this;
      if (this.get('showProject')) {
        this.get('model').get('project').then(function () {
          _this.notifyPropertyChange('sentence');
        });
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.modelObserver();
    }
  });
  _exports.default = _default;
});