define("bd-hybrid-cloud/components/x-configurator-custom-property-panel", ["exports", "bd-hybrid-cloud/utils/custom-3d-properties"], function (_exports, _custom3dProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var bind = Ember.run.bind;

  /*
   Displays components based on property descriptions inside the config json
  */
  var _default = Ember.Component.extend({
    /**
     * Props
     */
    model: null,
    /**
     * State
     */
    properties: null,
    colorsByType: {},
    /**
     * Hooks
     */
    init: function init() {
      this._super.apply(this, arguments);
      this.configCompleteHandler = bind(this, this.handleConfigComplete);
      this.selectionChangedHandler = bind(this, this.handleSelectionChanged);
      var bd3d = this.get('bd3d');
      bd3d.on('configComplete', this.configCompleteHandler);
      bd3d.on('changedSelection', this.selectionChangedHandler);
      this.updateProperties();
    },
    willDestroyElement: function willDestroyElement() {
      var bd3d = this.get('bd3d');
      bd3d.off('configComplete', this.configChangeHandler);
      bd3d.off('changedSelection', this.selectionChangedHandler);
    },
    /**
     * Methods
     */
    handleConfigComplete: function handleConfigComplete() {
      this.updateProperties();
    },
    handleSelectionChanged: function handleSelectionChanged() {
      this.updateProperties();
    },
    /**
     * @method updateProperties
     * @description - Gets the properties of the selected object and converts
     *  them to internal data, used to render the UI
     * */
    updateProperties: function updateProperties() {
      var bd3d = this.get('bd3d');
      var configurator = bd3d && bd3d.get('mattressConfigurator');
      var props = null;
      var index = 0;
      if (configurator) {
        var properties = configurator.getSelectedDataProperties();
        if (properties) {
          properties.forEach(function (property) {
            var resultProperty = (0, _custom3dProperties.convertPropertyToObject)(property, configurator, index, bd3d);
            if (resultProperty) {
              if (!props) {
                props = [];
              }
              props.push(resultProperty);
              index += 1;
            }
          });
        }
      }
      this.set('properties', props);
    },
    /**
     * Actions
     */

    actions: {
      onOpenSamplePanel: function onOpenSamplePanel(section, params) {
        this.sendAction('openSamplePanel', section, params);
      }
    }
  });
  _exports.default = _default;
});