define("bd-hybrid-cloud/components/forms/x-emails-form", ["exports", "bd-hybrid-cloud/components/x-form"], function (_exports, _xForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = _xForm.default.extend({
    /**
     * State
     */

    model: computed.alias('activeUser.model'),
    /**
     * Hooks
     */
    request: function request() {
      var _this = this;
      return this.get('model').save().then(function () {
        _this.get('flashMessages').success(_this.get('trans').t('alert.success.emailsChanged'));
      });
    },
    /**
     * Hooks
     */
    willDestroy: function willDestroy() {
      this.get('model').rollbackAttributes();
    }
  });
  _exports.default = _default;
});