define("bd-hybrid-cloud/utils/generate-pretty-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generatePrettyJson;
  function generatePrettyJson(json) {
    return JSON.stringify(json, null, '  ');
  }
});