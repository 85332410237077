define("bd-hybrid-cloud/models/brand", ["exports", "ember-data", "bgr-ember-json-api/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _default = _model.default.extend({
    baseLine: attr('string'),
    content: attr('string'),
    name: attr('string'),
    signatureBrand: attr('boolean'),
    sort: attr('number'),
    translations: attr(),
    videoLink1: attr('string'),
    videoLink2: attr('string')
  });
  _exports.default = _default;
});