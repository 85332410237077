define("bd-hybrid-cloud/templates/components/x-password-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8ZZirTAs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"showPassword\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"input\",null,[[\"class\",\"disabled\",\"placeholder\",\"type\",\"value\"],[\"bd-input bd-input--lg\",[20,[\"disabled\"]],[20,[\"placeholder\"]],\"text\",[20,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"input\",null,[[\"class\",\"disabled\",\"placeholder\",\"type\",\"value\"],[\"bd-input bd-input--lg\",[20,[\"disabled\"]],[20,[\"placeholder\"]],\"password\",[20,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[6,\"button\"],[10,\"class\",[26,[\"password-input__button bd-button bd-button--icon-lg \",[18,\"buttonClass\"]]]],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"togglePassword\"]],[7],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"icon-visibility\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"password-input-strength\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"password-input-strength__progress\"],[10,\"style\",[18,\"progressStyle\"],null],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-password-input.hbs"
    }
  });
  _exports.default = _default;
});