define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/section/borders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "geaR65eu",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-panel__section\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n    \"],[1,[18,\"x-configurator-borders-editor-panel\"],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/section/borders.hbs"
    }
  });
  _exports.default = _default;
});