define("bd-hybrid-cloud/templates/components/forms/x-reset-password-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8+pEkWkl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"success\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n    \"],[6,\"p\"],[7],[6,\"strong\"],[7],[1,[25,\"t\",[\"resetPassword.confirmMessage\"],null],false],[8],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"errors\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n      \"],[1,[25,\"x-error-list\",null,[[\"errors\"],[[20,[\"errors\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"class\",\"placeholder\",\"type\",\"value\"],[\"bd-input\",[25,\"t\",[\"auth.password\"],null],\"password\",[20,[\"password\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"class\",\"placeholder\",\"type\",\"value\"],[\"bd-input\",[25,\"t\",[\"auth.confirmPassword\"],null],\"password\",[20,[\"passwordConfirmation\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--regular bd-button--solid bd-button--stretched\"],[9,\"type\",\"submit\"],[7],[1,[25,\"t\",[\"actions.submit\"],null],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n    \"],[4,\"link-to\",[\"login\"],[[\"class\"],[\"link link--grey-darker link--italic link--sm\"]],{\"statements\":[[1,[25,\"t\",[\"auth.login\"],null],false]],\"parameters\":[]},null],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/forms/x-reset-password-form.hbs"
    }
  });
  _exports.default = _default;
});