define("bd-hybrid-cloud/tailwind/config/sizes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    xxs: '5px',
    xs: '15px',
    sm: '25px',
    reg: '30px',
    md: '50px',
    lg: '70px',
    xl: '80px'
  };
  _exports.default = _default;
});