define("bd-hybrid-cloud/templates/components/x-rich-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NjUdFVri",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"pell-editor\",null,[[\"onChange\",\"value\",\"pellOptions\"],[[20,[\"onChange\"]],[20,[\"value\"]],[20,[\"options\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-rich-text.hbs"
    }
  });
  _exports.default = _default;
});