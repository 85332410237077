define("bd-hybrid-cloud/templates/components/projects-multiple-selection-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9yhdH7zU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[25,\"hash\",null,[[\"selectedProjects\"],[[19,0,[\"selectedProjects\"]]]]],[25,\"hash\",null,[[\"archiveSelectedProjects\",\"clearSelectedProjects\",\"deleteSelectedProjects\",\"selectAllProjects\",\"unarchiveSelectedProjects\",\"unshareSelectedProjects\"],[[25,\"action\",[[19,0,[]],[19,0,[\"archiveSelectedProjects\"]]],null],[25,\"action\",[[19,0,[]],[19,0,[\"clearSelectedProjects\"]]],null],[25,\"action\",[[19,0,[]],[19,0,[\"deleteSelectedProjects\"]]],null],[25,\"action\",[[19,0,[]],[19,0,[\"selectAllProjects\"]]],null],[25,\"action\",[[19,0,[]],[19,0,[\"unarchiveSelectedProjects\"]]],null],[25,\"action\",[[19,0,[]],[19,0,[\"unshareSelectedProjects\"]]],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/projects-multiple-selection-state.hbs"
    }
  });
  _exports.default = _default;
});