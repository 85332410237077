define("bd-hybrid-cloud/templates/components/forms/x-delete-account-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "A0AUDjlI",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n  \"],[6,\"p\"],[7],[1,[25,\"t\",[\"settings.account.deleteWarning\"],null],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n  \"],[1,[25,\"x-form-control\",null,[[\"label\",\"bindTo\"],[[25,\"t\",[\"settings.account.deleteConfirm\"],null],[20,[\"isConfirmed\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n  \"],[6,\"label\"],[9,\"class\",\"form__label\"],[7],[1,[25,\"t\",[\"auth.password\"],null],false],[8],[0,\"\\n  \"],[1,[25,\"input\",null,[[\"class\",\"disabled\",\"placeholder\",\"type\",\"value\"],[\"bd-input bd-input--lg\",[25,\"not\",[[20,[\"isConfirmed\"]]],null],[25,\"t\",[\"auth.password\"],null],\"password\",[20,[\"password\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"errors\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n    \"],[1,[25,\"x-error-list\",null,[[\"errors\"],[[20,[\"errors\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n  \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--regular bd-button--solid-red\"],[10,\"disabled\",[25,\"not\",[[20,[\"isConfirmed\"]]],null],null],[9,\"type\",\"submit\"],[7],[1,[25,\"t\",[\"settings.account.deleteAccount\"],null],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/forms/x-delete-account-form.hbs"
    }
  });
  _exports.default = _default;
});