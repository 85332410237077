define("bd-hybrid-cloud/mixins/component/configuration-quilt-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    Mixin = Ember.Mixin;
  var _default = Mixin.create({
    /**
     * State
     */

    model: {},
    /**
     * Computed
     */

    title: computed('quilt', function () {
      var trans = this.get('trans');
      return this.get('quilt.name') || trans.t('actions.selectEntity', {
        entity: trans.t('modelNames.quilt')
      });
    }),
    quilt: computed('model', function () {
      var quilt;
      var id = this.get('model.id');
      if (id) {
        quilt = this.get('store').peekRecord('quiltPattern', id);
      } else if (this.get('model') === 'custom') {
        quilt = {
          name: this.get('trans').t('configurations.customQuilt'),
          thumbUrl: '/assets/images/components/quilt-placeholder.png'
        };
      }
      return quilt;
    })
  });
  _exports.default = _default;
});