define("bd-hybrid-cloud/templates/app/projects/project/add/description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JKu1GcR3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"x-app-content-overlay-view\",null,[[\"title\",\"subtitle\",\"onCloseRoute\"],[[20,[\"model\",\"project\",\"title\"]],[25,\"t\",[\"projects.edit\"],null],\"app.projects.project\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"gr-8 gr-4@desktop gr-centered\"],[7],[0,\"\\n    \"],[1,[25,\"x-edit-description-form\",null,[[\"project\",\"tags\"],[[20,[\"model\",\"project\"]],[20,[\"model\",\"tags\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/projects/project/add/description.hbs"
    }
  });
  _exports.default = _default;
});