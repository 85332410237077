define("bd-hybrid-cloud/controllers/app/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    Controller = Ember.Controller;
  var _default = Controller.extend({
    /**
     * State
     */

    queryParams: ['display', 'previousURL'],
    display: '',
    previousURL: '',
    /**
     * Computed
     */

    hideDesigner: computed.equal('display', 'templates'),
    hideSamples: computed.equal('display', 'templates'),
    shouldDisplayCoverSolutions: computed.or('activeUser.can.createEditCoverSolutions', 'activeUser.can.configureCoverSolutions'),
    shouldDisplayDesigner: computed('activeUser.can.createEditTemplate', 'hideDesigner', function () {
      var condition = false;
      if (this.get('activeUser.can.createEditTemplate') && !this.get('hideDesigner')) {
        condition = true;
      }
      return condition;
    }),
    shouldDisplayPlayground: computed.or('activeUser.can.createEditPlayground', 'activeUser.can.configurePlayground'),
    shouldDisplayPillows: computed.or('activeUser.can.createEditPillowTemplate', 'activeUser.can.configurePillowTemplate'),
    closeRoute: computed('previousURL', function () {
      return decodeURIComponent(this.get('previousURL'));
    })
  });
  _exports.default = _default;
});