define("bd-hybrid-cloud/templates/app/settings/emails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3wGUdhJf",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"section\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"gr-12\"],[7],[0,\"\\n        \"],[1,[18,\"forms/x-emails-form\"],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/settings/emails.hbs"
    }
  });
  _exports.default = _default;
});