define("bd-hybrid-cloud/helpers/has-has-many-records", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasHasManyRecords = hasHasManyRecords;
  function hasHasManyRecords(params /*, hash*/) {
    var record = params[0];
    var relationshipName = params[1];
    return !!record.hasMany(relationshipName).ids().length;
  }
  var _default = Ember.Helper.helper(hasHasManyRecords);
  _exports.default = _default;
});