define("bd-hybrid-cloud/components/x-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;
  var _default = Component.extend({
    /**
     * Props
     */
    paginationConfig: {},
    /**
     * Hooks
     */
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      if (this.onPageChange && typeof this.onPageChange === 'function') {
        this.onPageChange();
      }
    }
  });
  _exports.default = _default;
});