define("bd-hybrid-cloud/mixins/controller/iframe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    /**
     * Props
     */

    pathname: null,
    /**
     * State
     */

    queryParams: ['pathname'],
    /**
     * Actions
     */

    actions: {
      replacePathname: function replacePathname(pathname) {
        this.replaceRoute({
          queryParams: {
            pathname: pathname
          }
        });
      }
    }
  });
  _exports.default = _default;
});