define("bd-hybrid-cloud/templates/app/settings/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EiAJAbtn",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"section\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"gr-6\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n          \"],[6,\"h2\"],[9,\"class\",\"bd-title bd-title--h4\"],[7],[1,[25,\"t\",[\"settings.account.updatePassword\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"activeUser\",\"isEmployee\"]]],null,{\"statements\":[[0,\"            \"],[6,\"p\"],[7],[1,[25,\"t\",[\"settings.account.employeeUpdatePasswordNotice\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[18,\"forms/x-update-password-form\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row section__row--lg\"],[7],[0,\"\\n          \"],[6,\"h2\"],[9,\"class\",\"bd-title bd-title--h4\"],[7],[1,[25,\"t\",[\"settings.account.deleteAccount\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n          \"],[1,[18,\"forms/x-delete-account-form\"],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/settings/account.hbs"
    }
  });
  _exports.default = _default;
});