define("bd-hybrid-cloud/models/style", ["exports", "ember-data", "bgr-ember-json-api/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _default = _model.default.extend({
    code: attr('string'),
    color: attr('string'),
    name: attr('string'),
    sort: attr('number'),
    translations: attr()
  });
  _exports.default = _default;
});