define("bd-hybrid-cloud/templates/components/forms/x-update-password-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jIdhQOr2",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n  \"],[6,\"label\"],[9,\"class\",\"form__label\"],[7],[1,[25,\"t\",[\"auth.currentPassword\"],null],false],[8],[0,\"\\n  \"],[1,[25,\"input\",null,[[\"class\",\"placeholder\",\"type\",\"value\"],[\"bd-input bd-input--lg\",[25,\"t\",[\"auth.currentPassword\"],null],\"password\",[20,[\"oldPassword\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n  \"],[6,\"label\"],[9,\"class\",\"form__label\"],[7],[1,[25,\"t\",[\"auth.newPassword\"],null],false],[8],[0,\"\\n  \"],[1,[25,\"x-password-input\",null,[[\"placeholder\",\"value\"],[[25,\"t\",[\"auth.newPassword\"],null],[20,[\"password\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n  \"],[6,\"label\"],[9,\"class\",\"form__label\"],[7],[1,[25,\"t\",[\"auth.confirmNewPassword\"],null],false],[8],[0,\"\\n  \"],[1,[25,\"x-password-input\",null,[[\"placeholder\",\"value\"],[[25,\"t\",[\"auth.confirmNewPassword\"],null],[20,[\"passwordConfirmation\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"errors\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n    \"],[1,[25,\"x-error-list\",null,[[\"errors\"],[[20,[\"errors\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n  \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--regular bd-button--solid\"],[9,\"type\",\"submit\"],[7],[1,[25,\"t\",[\"settings.account.updatePassword\"],null],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/forms/x-update-password-form.hbs"
    }
  });
  _exports.default = _default;
});