define("bd-hybrid-cloud/templates/components/modals/x-project-sample-note-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aDJhSZLm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bgr-modal-template\",null,null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"modal__hint\"],[7],[0,\"\\n    \"],[6,\"p\"],[7],[1,[18,\"subtitle\"],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal__title\"],[7],[0,\"\\n    \"],[6,\"p\"],[7],[1,[20,[\"model\",\"sample\",\"title\"]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal__row\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"meta\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"x-note-form\",null,[[\"allowEmpty\",\"content\",\"isEditable\",\"onSubmit\"],[true,[20,[\"meta\",\"note\"]],[20,[\"model\",\"project\",\"canAddNote\",\"content\"]],\"updateNote\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"p\"],[9,\"class\",\"u-text-center\"],[7],[1,[25,\"t\",[\"notes.loading\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/modals/x-project-sample-note-modal.hbs"
    }
  });
  _exports.default = _default;
});