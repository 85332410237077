define("bd-hybrid-cloud/serializers/member", ["exports", "bd-hybrid-cloud/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'member';
    }
  });
  _exports.default = _default;
});