define("bd-hybrid-cloud/templates/components/modals/x-add-to-concept-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oOypyKJg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bgr-modal-template\",null,null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"modal__row\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"modal__description\"],[7],[1,[25,\"t\",[\"confirm.addModelWithoutNameToEntity\"],[[\"model\",\"entity\"],[[20,[\"model\",\"title\"]],[25,\"t\",[\"concepts.title\"],null]]]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal__row u-text-center\"],[7],[0,\"\\n    \"],[1,[25,\"x-form-control\",null,[[\"bindTo\",\"label\"],[[20,[\"isInternal\"]],[25,\"t\",[\"projects.internal\"],null]]]],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal__row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align align--centered align--gutter-sm\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--regular bd-button--stroked-blue\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"hide\"],null],null],[7],[1,[25,\"t\",[\"actions.cancel\"],null],false],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--regular bd-button--solid-blue\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"onConfirmClick\"],null],null],[7],[1,[25,\"t\",[\"actions.confirm\"],null],false],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/modals/x-add-to-concept-modal.hbs"
    }
  });
  _exports.default = _default;
});