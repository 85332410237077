define("bd-hybrid-cloud/utils/slideshow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.canNavigateToNextSlide = canNavigateToNextSlide;
  _exports.canNavigateToPreviousSlide = canNavigateToPreviousSlide;
  function canNavigateToNextSlide() {
    var currentIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var collection = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var collectionLength = collection.length;
    return collectionLength && currentIndex < collectionLength - 1;
  }
  function canNavigateToPreviousSlide() {
    var currentIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var collection = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    return collection.length && currentIndex > 0;
  }
});