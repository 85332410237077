define("bd-hybrid-cloud/controllers/app/projects/project/add/quilt-patterns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller;
  var _default = Controller.extend({
    /**
     * State
     */
    isUploading: false
  });
  _exports.default = _default;
});