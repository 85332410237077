define("bd-hybrid-cloud/templates/components/app-content-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SE73X6Vf",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"app-content-loader\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"loader loader--md\"],[7],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/app-content-loader.hbs"
    }
  });
  _exports.default = _default;
});