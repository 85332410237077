define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel-section/component/unit-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ti5Kq7zp",
    "block": "{\"symbols\":[],\"statements\":[[6,\"tr\"],[9,\"class\",\"table__row\"],[7],[0,\"\\n  \"],[6,\"td\"],[9,\"colspan\",\"2\"],[7],[0,\" \"],[8],[0,\"\\n  \"],[6,\"td\"],[9,\"class\",\"table__cell u-text-center bd-text-xs\"],[7],[1,[20,[\"activeUser\",\"model\",\"units\"]],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel-section/component/unit-indicator.hbs"
    }
  });
  _exports.default = _default;
});