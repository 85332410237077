define("bd-hybrid-cloud/controllers/styleguide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var availableColors = [{
    hex: '#ababab'
  }, {
    hex: '#dad4ff'
  }, {
    hex: '#dadfad'
  }, {
    hex: '#5f5f2d'
  }, {
    hex: '#e4d5a3'
  }, {
    hex: '#4f45ff'
  }, {
    hex: '#4a6b4b'
  }, {
    hex: '#a3f5b5'
  }, {
    hex: '#3a8d8a'
  }, {
    hex: '#a333aa'
  }];
  var additionalColors = [{
    hex: '#333999'
  }, {
    hex: '#999333'
  }, {
    hex: '#aa3d43'
  }];
  var _default = Ember.Controller.extend({
    colors: availableColors,
    selectedColor: availableColors[0],
    selectedColors: [availableColors[0], availableColors[1]],
    checkboxTestValue: false,
    radioButtonTestValue: 'Han',
    switchTestValue: false,
    actions: {
      toggleColors: function toggleColors() {
        this.set('selectedColor', null);
        this.set('selectedColors', null);
        this.set('colors', this.get('colors') === availableColors ? additionalColors : availableColors);
      }
    }
  });
  _exports.default = _default;
});