define("bd-hybrid-cloud/templates/partials/general/logos/bd-logo-blue-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mUUaC3xw",
    "block": "{\"symbols\":[],\"statements\":[[6,\"img\"],[9,\"src\",\"/assets/images/logos/bd-cloud-text-blue-horizontal.svg\"],[9,\"alt\",\"BekaertDeslee Logo\"],[9,\"width\",\"265\"],[9,\"height\",\"72\"],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/general/logos/bd-logo-blue-horizontal.hbs"
    }
  });
  _exports.default = _default;
});