define("bd-hybrid-cloud/templates/partials/configuration/create/cover-solutions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LyGu8NkT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"displayCategoryList\"]]],null,{\"statements\":[[0,\"  \"],[12,\"partials/configuration/categories-list\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[12,\"partials/configuration/configurations-list\",[]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/create/cover-solutions.hbs"
    }
  });
  _exports.default = _default;
});