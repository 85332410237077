define("bd-hybrid-cloud/templates/components/x-edit-description-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IxmkGs6/",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"if\",[[20,[\"errors\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n    \"],[1,[25,\"x-error-list\",null,[[\"errors\"],[[20,[\"errors\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[6,\"div\"],[9,\"class\",\"section__row section__row--xs\"],[7],[0,\"\\n  \"],[1,[25,\"input\",null,[[\"class\",\"placeholder\",\"value\"],[\"bd-input\",[25,\"t\",[\"projects.create.form.name\"],null],[20,[\"title\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"section__row section__row--xs\"],[7],[0,\"\\n\"],[4,\"x-select-multiple\",null,[[\"onchange\",\"onkeydown\",\"class\",\"options\",\"placeholder\",\"search\",\"selected\"],[[25,\"action\",[[19,0,[]],\"onTagsChange\",\"selectedTags\"],null],[25,\"action\",[[19,0,[]],\"onTagsCreate\"],null],\"filter-select__select filter-select__select--capitalize\",[20,[\"tagOptions\"]],[25,\"t\",[\"projects.tags.search\"],null],[25,\"action\",[[19,0,[]],\"searchTags\"],null],[20,[\"selectedTags\"]]]],{\"statements\":[[0,\"    \"],[1,[19,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"section__row section__row--xs\"],[7],[0,\"\\n  \"],[4,\"x-rich-text\",null,[[\"onChange\",\"value\"],[[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"description\"]]],null]],null],[20,[\"description\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"section__row section__row--md\"],[7],[0,\"\\n  \"],[6,\"button\"],[9,\"type\",\"submit\"],[9,\"class\",\"bd-button bd-button--regular bd-button--solid-blue\"],[7],[0,\"\\n    \"],[1,[25,\"t\",[\"actions.save\"],null],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-edit-description-form.hbs"
    }
  });
  _exports.default = _default;
});