define("bd-hybrid-cloud/mixins/component/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    Mixin = Ember.Mixin;
  var _default = Mixin.create({
    /**
     * Props
     */

    renderInPlace: true,
    searchField: 'name',
    size: '',
    /**
     * State
     */

    classNameBindings: ['sizeClass'],
    classNames: ['select'],
    tagName: 'div',
    /**
     * Computed
     */

    sizeClass: computed('size', function () {
      var size = this.get('size');
      return size ? "select--".concat(size) : '';
    })
  });
  _exports.default = _default;
});