define("bd-hybrid-cloud/components/x-grid-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed,
    inject = Ember.inject,
    isArray = Ember.isArray,
    isBlank = Ember.isBlank,
    isPresent = Ember.isPresent,
    set = Ember.set;
  var TECHNOLOGIES = ['knit', 'woven'];
  var PER_PAGE = 1000;
  var FILTER_MODELS = [{
    condition: 'includeProjects',
    dataProp: 'projects',
    fetch: 'fromRecord',
    modelName: 'project',
    queryParamProp: 'selectedProjects',
    queryOptions: {
      filter: {
        archived: false,
        include_shared: true
      },
      per_page: PER_PAGE
    }
  }, {
    dataProp: 'brands',
    fetch: 'fromRecord',
    modelName: 'brand',
    queryParamProp: 'selectedBrands'
  }, {
    dataProp: 'collections',
    fetch: false,
    modelName: 'collection',
    queryParamProp: 'selectedCollections'
  }, {
    apiQuery: 'color',
    dataProp: 'colors',
    fetch: 'throughApi',
    modelName: 'color',
    queryParamProp: 'selectedColors'
  }, {
    apiQuery: 'composition',
    dataProp: 'compositions',
    fetch: 'throughApi',
    modelName: 'composition',
    queryParamProp: 'selectedCompositions'
  }, {
    apiQuery: 'endsWoven',
    dataProp: 'ends',
    fetch: 'throughApi',
    modelName: 'ends',
    queryParamProp: 'selectedEnds',
    type: 'range'
  }, {
    apiQuery: 'finishing',
    dataProp: 'finishing',
    fetch: 'throughApi',
    modelName: 'finishing',
    queryParamProp: 'selectedFinishing'
  }, {
    apiQuery: 'widthKnit',
    dataProp: 'knitWidths',
    fetch: 'throughApi',
    modelName: 'width',
    queryParamProp: 'selectedWidth',
    type: 'range'
  }, {
    dataProp: 'private',
    modelName: 'private',
    queryParamProp: 'private'
  }, {
    apiQuery: 'picksWoven',
    dataProp: 'picks',
    fetch: 'throughApi',
    modelName: 'picks',
    queryParamProp: 'selectedPicks',
    type: 'range'
  }, {
    modelName: 'q',
    queryParamProp: 'query'
  }, {
    dataProp: 'regions',
    fetch: 'fromRecord',
    modelName: 'region',
    queryParamProp: 'selectedRegions'
  }, {
    dataProp: 'styles',
    fetch: 'fromRecord',
    modelName: 'style',
    queryParamProp: 'selectedStyles'
  }, {
    dataProp: 'technologies',
    modelName: 'technology',
    queryParamProp: 'selectedTechnology'
  }, {
    dataProp: 'tag',
    fetch: 'fromRecord',
    modelName: 'tag',
    queryParamProp: 'selectedTags'
  }, {
    dataProp: 'themes',
    fetch: false,
    modelName: 'theme',
    queryParamProp: 'selectedThemes'
  }, {
    apiQuery: 'weight',
    dataProp: 'weights',
    fetch: 'throughApi',
    modelName: 'weight',
    queryParamProp: 'selectedWeight',
    type: 'range'
  }];
  var SEARCHABLE_FILTERS = {
    composition: {
      stateProperty: 'compositions'
    },
    color: {
      stateProperty: 'colors'
    },
    endsWoven: {
      stateProperty: 'ends'
    },
    finishing: {
      stateProperty: 'finishings'
    },
    weight: {
      stateProperty: 'weights'
    },
    widthKnit: {
      stateProperty: 'knitWidths'
    },
    picksWoven: {
      stateProperty: 'picks'
    }
  };
  var _default = Component.extend({
    /**
     * Props
     */

    searchPlaceholder: '',
    /**
     * Injections
     */

    filterQuery: inject.service(),
    /**
     * State
     */

    brands: [],
    classNameBindings: ['standalone:grid-view__filter--standalone'],
    classNames: ['grid-view__filter'],
    collections: [],
    colors: [],
    compositions: [],
    density: 'warp',
    ends: [],
    finishings: [],
    hasProductsFilter: true,
    hasRegionsFilter: false,
    includeProjects: false,
    knitWidths: [],
    onlyProducts: false,
    picks: [],
    private: false,
    projects: [],
    query: '',
    regions: [],
    selectedBrands: [],
    selectedCollections: [],
    selectedColors: [],
    selectedCompositions: [],
    selectedEnds: '',
    selectedFinishing: [],
    selectedPicks: '',
    selectedProjects: [],
    selectedRegions: [],
    selectedStyles: [],
    selectedTechnology: null,
    selectedTags: [],
    selectedThemes: [],
    selectedWeight: '',
    selectedWidth: '',
    shouldCacheQueryParams: false,
    shouldSetCachedQueryParams: false,
    sortedBrands: computed.sort('brands', 'sortedConfig'),
    sortedConfig: ['name:asc'],
    sortedCollectionsConfig: ['sort:asc'],
    sortedProjectsConfig: ['title:asc'],
    sortedCollections: computed.sort('collections', 'sortedCollectionsConfig'),
    sortedProjects: computed.sort('projects', 'sortedProjectsConfig'),
    sortedStyles: computed.sort('styles', 'sortedConfig'),
    sortedTags: computed.sort('tag', 'sortedConfig'),
    sortedThemes: computed.sort('themes', 'sortedConfig'),
    standalone: false,
    technologies: TECHNOLOGIES,
    tag: [],
    themes: [],
    weights: [],
    width: 'knit',
    /**
     * Events
     */
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      if (this.get('shouldSetCachedQueryParams')) {
        this.get('filterQuery').applyCachedQueryParamsToController('samples');
      }
      this.setFilterModels().then(function () {
        _this.readQueryParams();
      });
    },
    willDestroy: function willDestroy() {
      if (this.get('shouldCacheQueryParams')) {
        this.get('filterQuery').storeQueryParams('samples');
      }
      this.send('clearFilter');
    },
    /**
     * Methods
     */
    fetchFilterData: function fetchFilterData(apiQuery, q) {
      var _this2 = this;
      var stateProperty = SEARCHABLE_FILTERS[apiQuery].stateProperty;
      this.get('api').request('samples/search-parameters', {
        param: apiQuery,
        q: q
      }).then(function (response) {
        set(_this2, stateProperty, response.data);
      });
    },
    readQueryParams: function readQueryParams() {
      var _this3 = this;
      var filterQueryController = this.get('filterQuery.controller');
      FILTER_MODELS.forEach(function (item) {
        var queryValue;
        var modelName = item.modelName;
        var queryParamProp = item.queryParamProp;
        var queryParam = _this3.get(queryParamProp);
        if (item.type === 'range') {
          var minQueryVal = filterQueryController.get("".concat(modelName, "Min"));
          var maxQueryVal = filterQueryController.get("".concat(modelName, "Max"));
          if (isPresent(minQueryVal) && isPresent(maxQueryVal)) {
            queryValue = minQueryVal === maxQueryVal ? minQueryVal : "".concat(minQueryVal, "-").concat(maxQueryVal);
          }
        } else {
          queryValue = filterQueryController.get(modelName);
        }
        if (!isBlank(queryValue)) {
          if (isArray(queryParam)) {
            var selectedQueryItems = _this3.get(item.dataProp).filter(function (dataItem) {
              var filterProp = Number(dataItem.id) || dataItem.id || dataItem;
              return queryValue.includes(filterProp);
            });
            _this3.set(queryParamProp, selectedQueryItems);
          } else {
            _this3.set(queryParamProp, queryValue);
          }
        }
      });
    },
    setFilterModels: function setFilterModels() {
      var _this4 = this;
      var queue = [];
      var store = this.get('store');
      FILTER_MODELS.forEach(function (item) {
        var apiQuery = item.apiQuery,
          condition = item.condition,
          dataProp = item.dataProp,
          fetch = item.fetch,
          modelName = item.modelName,
          _item$queryOptions = item.queryOptions,
          queryOptions = _item$queryOptions === void 0 ? {} : _item$queryOptions;
        if (fetch && (!condition || _this4.get(condition))) {
          if (fetch === 'fromRecord') {
            queue.push(store.query(modelName, queryOptions).then(function (model) {
              _this4.set(dataProp, model);
            }));
          } else if (fetch === 'throughApi') {
            queue.push(_this4.fetchFilterData(apiQuery, ''));
          }
        }
      });
      queue.push(store.query('collection', {
        per_page: PER_PAGE
      }).then(function (collections) {
        _this4.set('collections', collections);
        return Ember.RSVP.all(collections.map(function (collection) {
          return collection.hasMany('themes').reload();
        })).then(function (response) {
          _this4.set('themes', response.reduce(function (a, b) {
            return a.pushObjects(b.toArray());
          }, []));
        });
      }));
      return Promise.all(queue);
    },
    submitFilter: function submitFilter() {
      var _this5 = this;
      var queryParams = {
        page: 1
      };
      FILTER_MODELS.forEach(function (item) {
        var queryValue;
        var modelName = item.modelName;
        var queryParam = _this5.get(item.queryParamProp);
        if (item.type === 'range') {
          if (queryParam) {
            var split = queryParam.split('-');
            var min = split[0];
            var max = split[1] || min;
            queryParams["".concat(modelName, "Min")] = min;
            queryParams["".concat(modelName, "Max")] = max;
          } else {
            _this5.set(item.queryParamProp, '');
            queryParams["".concat(modelName, "Min")] = '';
            queryParams["".concat(modelName, "Max")] = '';
          }
        } else {
          if (isArray(queryParam)) {
            queryValue = queryParam.map(function (queryItem) {
              var id = queryItem.id;
              return Number(id) || id || queryItem;
            });
          } else {
            queryValue = queryParam;
          }
          queryParams[modelName] = queryValue;
        }
      });
      this.get('filterQuery.controller').setProperties(queryParams);
    },
    /**
     * Actions
     */

    actions: {
      clearFilter: function clearFilter() {
        var _this6 = this;
        FILTER_MODELS.forEach(function (item) {
          var queryParamProp = item.queryParamProp;
          var queryParam = _this6.get(queryParamProp);
          if (!isBlank(queryParam)) {
            var resetValue = isArray(queryParam) ? [] : '';
            _this6.set(queryParamProp, resetValue);
          }
        });
        this.submitFilter();
      },
      onChange: function onChange(propertyName, value) {
        this.set(propertyName, value);
        this.submitFilter();
      },
      submitFilter: function submitFilter() {
        this.submitFilter();
      }
    }
  });
  _exports.default = _default;
});