define("bd-hybrid-cloud/templates/components/modals/x-activity-log-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SkZXLeEs",
    "block": "{\"symbols\":[\"log\"],\"statements\":[[4,\"bgr-modal-template\",null,null,{\"statements\":[[0,\"  \"],[6,\"h2\"],[9,\"class\",\"modal__title\"],[7],[1,[25,\"t\",[\"activityLog.title\"],null],false],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"logs\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"logs\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"x-log\",null,[[\"model\"],[[19,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[6,\"p\"],[9,\"class\",\"u-text-center u-text-sm\"],[7],[0,\"\\n      \"],[6,\"em\"],[7],[1,[25,\"t\",[\"activityLog.loading\"],null],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"p\"],[9,\"class\",\"u-text-center u-text-sm\"],[7],[0,\"\\n      \"],[6,\"em\"],[7],[1,[25,\"t\",[\"activityLog.placeholder\"],null],false],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/modals/x-activity-log-modal.hbs"
    }
  });
  _exports.default = _default;
});