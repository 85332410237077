define("bd-hybrid-cloud/templates/partials/configuration/configuration-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "C/5iUF9i",
    "block": "{\"symbols\":[],\"statements\":[[6,\"li\"],[9,\"class\",\"editor-panel-tree__tile\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"editor-panel-tree__tile-thumbnail\"],[10,\"style\",[25,\"style\",null,[[\"backgroundImage\"],[[20,[\"configuration\",\"thumbUrl\"]]]]],null],[7],[8],[0,\"\\n  \"],[6,\"p\"],[9,\"class\",\"editor-panel-tree__tile-title\"],[7],[1,[20,[\"configuration\",\"title\"]],false],[8],[0,\"\\n  \"],[6,\"button\"],[9,\"class\",\"editor-panel-tree__tile-button\"],[3,\"action\",[[19,0,[]],\"selectModel\",[20,[\"configuration\"]]]],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"editor-panel-tree__tile-actionables\"],[7],[0,\"\\n    \"],[6,\"ul\"],[9,\"class\",\"inline-list\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"allowDeleteConfigurations\"]]],null,{\"statements\":[[0,\"        \"],[6,\"li\"],[7],[0,\"\\n          \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--icon-sm bd-button--grey-dark\"],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"deleteConfiguration\",[20,[\"configuration\"]]]],[7],[0,\"\\n            \"],[6,\"span\"],[9,\"class\",\"icon-trash\"],[7],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/configuration-tile.hbs"
    }
  });
  _exports.default = _default;
});