define("bd-hybrid-cloud/routes/app/configuration/configuration/edit", ["exports", "bd-hybrid-cloud/mixins/route/permissions", "bd-hybrid-cloud/mixins/route/filter-query", "bd-hybrid-cloud/mixins/route/configuration", "bd-hybrid-cloud/mixins/route/reset-controller", "bd-hybrid-cloud/mixins/route/model-loading-state"], function (_exports, _permissions, _filterQuery, _configuration, _resetController, _modelLoadingState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var Route = Ember.Route,
    RSVP = Ember.RSVP;
  var _default = Route.extend(_filterQuery.default, _permissions.default, _configuration.default, _resetController.default, _modelLoadingState.default, {
    /**
     * State
     */

    activeViewMode: 'edit',
    preventRouteTransition: true,
    routeController: 'app.configuration.configuration',
    /**
     * Model
     */
    model: function model(params) {
      var store = this.get('store');
      var filter = this.generateFilterObject(params);
      var component = params.component;
      if (filter.project && filter.project.length) {
        filter.drafts = 1;
      }
      return RSVP.hash({
        colors: store.seekAll('color'),
        configuration: this.modelFor('app.configuration.configuration'),
        quiltPatternCategories: store.findAll('quiltPatternCategory', {
          include: 'quiltPatterns'
        }),
        quiltPatterns: store.loadRecords('quiltPattern', {
          filter: _objectSpread(_objectSpread({}, filter), {}, {
            isBorder: Number(component === 'fabric'),
            isPanel: Number(component === 'panel')
          }),
          page: params.page,
          search: true,
          sort: params.sort
        }),
        samples: store.loadRecords('sample', {
          filter: filter,
          page: params.page,
          search: true,
          sort: params.sort
        })
      });
    },
    /**
     * Setup
     */
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.initConfigureSample();
    },
    /**
     * Actions
     */

    actions: {
      error: function error(e) {
        this.get('flashMessages').danger(this.get('trans').t('alert.danger.configurationDataNoLoaded'));
        throw e;
      },
      willTransition: function willTransition(transition) {
        var _this = this;
        if (transition.targetName !== 'app.configuration.configuration.edit' && this.get('bd3d').configurationIsDirty) {
          if (this.get('preventRouteTransition')) {
            transition.abort();
            this.get('modal').confirm(this.get('trans').t('confirm.leaveConfigurator')).then(function () {
              _this.set('preventRouteTransition', false);
              transition.retry();
            });
          } else {
            this.set('preventRouteTransition', true);
          }
        }
      }
    }
  });
  _exports.default = _default;
});