define("bd-hybrid-cloud/routes/app/configuration/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      if (!model) {
        this.transitionTo('app.create');
      }
    },
    model: function model(params) {
      var id = params.id;
      var model;
      if (id !== 'new') {
        model = this.get('store').loadRecord('configuration', id, {
          include: 'colors,concept,project,project.members,quiltPatterns,samples'
        });
      }
      return model;
    },
    serialize: function serialize(model) {
      var id = model.get('id') || 'new';
      return {
        id: id
      };
    }
  });
  _exports.default = _default;
});