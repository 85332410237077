define("bd-hybrid-cloud/models/log", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    Model = _emberData.default.Model;
  var _default = Model.extend({
    /**
     * Attributes
     */

    action: attr('string'),
    createdAt: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    name: attr('string'),
    type: attr('string'),
    updatedAt: attr('string'),
    /**
     * Relationships
     */

    project: belongsTo('project')
  });
  _exports.default = _default;
});