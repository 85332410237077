define("bd-hybrid-cloud/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "E7H4VHG5",
    "block": "{\"symbols\":[],\"statements\":[[6,\"section\"],[9,\"class\",\"section section--lg u-text-center\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n      \"],[12,\"partials/general/logos/bd-logo-blue\",[]],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row section__row--xs\"],[7],[0,\"\\n      \"],[6,\"p\"],[7],[1,[25,\"t\",[\"forgotPassword.reset\"],null],false],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"section__row section__row--lg\"],[7],[0,\"\\n    \"],[1,[25,\"forms/x-reset-password-form\",null,[[\"class\",\"identifier\"],[\"form--constrained-width\",[20,[\"identifier\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/reset-password.hbs"
    }
  });
  _exports.default = _default;
});