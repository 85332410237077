define("bd-hybrid-cloud/models/notification", ["exports", "ember-data", "bgr-ember-json-api/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _model.default.extend({
    /**
     * Attributes
     */

    ownerUserId: attr('number'),
    firstName: attr('string'),
    lastName: attr('string'),
    type: attr('string'),
    action: attr('string'),
    name: attr('string'),
    read: attr('number'),
    multiple: attr('number'),
    amount: attr('number'),
    createdAt: attr('string'),
    updatedAt: attr('string'),
    lastLogDate: attr('string'),
    /**
     * Relationships
     */

    project: belongsTo('project'),
    user: belongsTo('user')
  });
  _exports.default = _default;
});