define("bd-hybrid-cloud/controllers/share/configuration/detail", ["exports", "bd-hybrid-cloud/mixins/filter-query", "bd-hybrid-cloud/mixins/controller/configuration-detail"], function (_exports, _filterQuery, _configurationDetail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    Controller = Ember.Controller;
  var _default = Controller.extend(_filterQuery.default, _configurationDetail.default, {
    /**
     * Computed
     */

    detailPanelPartialName: computed('component', function () {
      var path = '';
      var component = this.get('component');
      if (component) {
        path = '/component';
      }
      return "partials/share/detail-panel".concat(path);
    }),
    mattressDimensions: computed('bd3d.mattressConfig', function () {
      var mattressDimensions = '';
      var dimensionProperties = [];
      var mattressConfig = this.get('bd3d.mattressConfig');
      if (mattressConfig) {
        dimensionProperties = mattressConfig.getDimensionProperties()[0].properties.splice(0);
        mattressDimensions = "".concat(dimensionProperties[0].value, " x ").concat(dimensionProperties[1].value, " cm");
      }
      return mattressDimensions;
    }),
    modelName: computed('model.title', function () {
      return this.get('model.title');
    }),
    /**
     * Methods
     */
    discardSelection: function discardSelection() {
      this.set('component', '');
    },
    /**
     * Actions
     */

    actions: {
      discardSelection: function discardSelection() {
        this.discardSelection();
      }
    }
  });
  _exports.default = _default;
});