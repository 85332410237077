define("bd-hybrid-cloud/helpers/file-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fileSize = fileSize;
  function fileSize(params) {
    var size = (params[0] / 1000000).toFixed(2);
    return "".concat(size, " MB");
  }
  var _default = Ember.Helper.helper(fileSize);
  _exports.default = _default;
});