define("bd-hybrid-cloud/templates/app/configuration/configuration/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SABzuYXE",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"edit-body__cell editor-panel\"],[7],[0,\"\\n  \"],[12,[20,[\"editorPanelPartialName\"]],[]],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"filterIsVisible\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"x-grid-filter\",null,[[\"includeProjects\",\"searchPlaceholder\",\"shouldCacheQueryParams\",\"shouldSetCachedQueryParams\",\"standalone\"],[true,[25,\"t\",[\"samples.searchPlaceholder\"],null],true,true,true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[20,[\"filterQuiltsIsVisible\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"x-grid-quilts-filter\",null,[[\"includeProjects\",\"searchPlaceholder\",\"shouldCacheQueryParams\",\"shouldSetCachedQueryParams\",\"standalone\"],[true,[25,\"t\",[\"quilts.searchPlaceholder\"],null],true,true,true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/configuration/configuration/edit.hbs"
    }
  });
  _exports.default = _default;
});