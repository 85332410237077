define("bd-hybrid-cloud/models/collection", ["exports", "ember-data", "bgr-ember-json-api/model", "ember-data-storefront/mixins/loadable-model"], function (_exports, _emberData, _model, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany;
  var _default = _model.default.extend(_loadableModel.default, {
    /**
     * Props
     */
    active: attr(),
    description: attr('string'),
    name: attr('string'),
    showOnCloud: attr('boolean'),
    sort: attr('number'),
    translations: attr(),
    thumbUrl: attr('string'),
    releaseDate: attr('string'),
    videoLink_1: attr(),
    videoLink_2: attr(),
    /**
     * Relationships
     */

    samples: hasMany('sample'),
    themes: hasMany('theme'),
    collectionThemes: hasMany('collectionTheme', {
      async: false
    }),
    /**
     * Computed
     */

    displayOnFilter: Ember.computed.and('active', 'showOnCloud')
  });
  _exports.default = _default;
});