define("bd-hybrid-cloud/templates/components/x-app-content-overlay-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "geOGSPEE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"app-content__overlay-view\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"section section--md\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"row u-relative\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"gr-12\"],[7],[0,\"\\n            \"],[6,\"header\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"previous\"]]],null,{\"statements\":[[0,\"                \"],[6,\"button\"],[9,\"class\",\"back-button back-button--back bd-button bd-button--round-sm bd-button--stroked-grey-light\"],[3,\"action\",[[19,0,[]],\"back\"]],[7],[0,\"\\n                  \"],[6,\"span\"],[9,\"class\",\"icon-arrow-left2\"],[7],[8],[0,\"\\n                \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"              \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n                \"],[6,\"h1\"],[9,\"class\",\"app-content__overlay-view__title\"],[7],[1,[18,\"title\"],false],[8],[0,\"\\n              \"],[8],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n                \"],[6,\"h2\"],[9,\"class\",\"app-content__overlay-view__subtitle\"],[7],[1,[18,\"subtitle\"],false],[8],[0,\"\\n              \"],[8],[0,\"\\n              \"],[6,\"button\"],[9,\"class\",\"back-button back-button--close bd-button bd-button--grey bd-button--icon-lg\"],[3,\"action\",[[19,0,[]],\"close\"]],[7],[0,\"\\n                \"],[6,\"span\"],[9,\"class\",\"icon-close\"],[7],[8],[0,\"\\n              \"],[8],[0,\"\\n            \"],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row section__row--md\"],[7],[0,\"\\n      \"],[11,1],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-app-content-overlay-view.hbs"
    }
  });
  _exports.default = _default;
});