define("bd-hybrid-cloud/components/modals/x-download-configuration-modal", ["exports", "bgr-ember-modal/components/bgr-modal", "bd-hybrid-cloud/utils/file"], function (_exports, _bgrModal, _file) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _bgrModal.default.extend({
    /**
     * State
     */

    isHiResLoading: false,
    isLowResLoading: false,
    /**
     * Methods
     */
    downloadImage: function downloadImage(base64, type) {
      var fileName = "".concat(this.get('model.title'), ".").concat(type);
      (0, _file.downloadFile)(base64, fileName);
    },
    downloadPdf: function downloadPdf(type, base64) {
      var _this = this;
      var pdfType = 'specifications';
      var hiRes = false;
      if (type === 'specifications-hi-res') {
        hiRes = true;
        this.set('isHiResLoading', true);
      } else if (type === 'specifications-low-res') {
        this.set('isLowResLoading', true);
      }
      var identifier = this.get('model.identifier');
      var path = "configurations/".concat(identifier, "/pdf/").concat(pdfType);
      var fileName = "".concat(this.get('model.title'), ".pdf");
      var singles = this.get('bd3d').getSinglesInformation();
      singles = this.parseSingles(singles);
      this.get('api').post(path, {
        image: base64,
        singles: singles,
        hiRes: hiRes
      }, {
        xhrFields: {
          responseType: 'arraybuffer'
        }
      }).then(function (response) {
        (0, _file.downloadFile)(response, fileName);
        _this.set('isLowResLoading', false);
        _this.set('isHiResLoading', false);
      });
    },
    parseSingles: function parseSingles(singles) {
      var data = [];
      for (var s = 0; s < singles.length; s += 1) {
        var single = singles[s];
        var components = [];
        for (var c = 0; c < single.components.length; c += 1) {
          var component = single.components[c];
          if ((component.type === 'sample' || component.type === 'fabric') && component.sample) {
            var sample = component.sample;
            var sampleData = {
              id: sample.get('id')
            };
            component.sample = sampleData;
          }
          components.push(component);
        }
        data.push(single);
      }
      return data;
    },
    /**
     * Actions
     */

    actions: {
      download: function download(type) {
        var base64 = this.get('bd3d').generateBase64Image(3000, 1500, type === 'pdf' ? 'jpeg' : type, false);
        if (type === 'jpeg' || type === 'png') {
          this.downloadImage(base64, type);
        } else {
          this.downloadPdf(type, base64);
        }
      }
    }
  });
  _exports.default = _default;
});