define("bd-hybrid-cloud/mixins/google-pageview", ["exports", "bd-hybrid-cloud/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    beforePageviewToGA: function beforePageviewToGA(ga) {},
    pageviewToGA: Ember.on('didTransition', function (page, title) {
      var page = page ? page : this.get('url');
      var title = title ? title : this.get('url');
      if (Ember.get(_environment.default, 'googleAnalytics.webPropertyId') != null) {
        var trackerType = Ember.getWithDefault(_environment.default, 'googleAnalytics.tracker', 'analytics.js');
        if (trackerType === 'analytics.js') {
          var globalVariable = Ember.getWithDefault(_environment.default, 'googleAnalytics.globalVariable', 'ga');
          this.beforePageviewToGA(window[globalVariable]);
          window[globalVariable]('send', 'pageview', {
            page: page,
            title: title
          });
        } else if (trackerType === 'ga.js') {
          window._gaq.push(['_trackPageview']);
        }
      }
    })
  });
  _exports.default = _default;
});