define("bd-hybrid-cloud/templates/components/x-scroll-to-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MLaBDnW0",
    "block": "{\"symbols\":[],\"statements\":[[6,\"button\"],[9,\"class\",\"bd-button bd-button--text bd-button--grey\"],[3,\"action\",[[19,0,[]],\"scroll\"]],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"align align--gutter-xxs\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n      \"],[1,[25,\"t\",[\"general.top\"],null],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"icon-arrow-top\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-scroll-to-top.hbs"
    }
  });
  _exports.default = _default;
});