define("bd-hybrid-cloud/components/x-size-input", ["exports", "bgr-ember-numeric-input/components/bgr-numeric-input", "bd-hybrid-cloud/utils/math"], function (_exports, _bgrNumericInput, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on;
  var INCH = 0.393700787;
  var _default = _bgrNumericInput.default.extend({
    /**
     * Props
     */

    precision: 0,
    /**
     * Handlers
     */

    initHandler: on('init', function () {
      if (this.get('activeUser.model.units') === 'inch') {
        this.setProperties({
          deserialize: function deserialize(serialized) {
            return (0, _math.round)(serialized * INCH, 2);
          },
          serialize: function serialize(deserialized) {
            return (0, _math.round)(deserialized / INCH, this.get('precision'));
          }
        });
      }
    })
  });
  _exports.default = _default;
});