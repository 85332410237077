define("bd-hybrid-cloud/mixins/model/generate-share-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    /**
     * Methods
     */
    generateShareUrl: function generateShareUrl() {
      var modelName = this.constructor.modelName.pluralize();
      return this.get('api').request("".concat(modelName, "/").concat(this.get('id'), "/share-link"));
    }
  });
  _exports.default = _default;
});