define("bd-hybrid-cloud/templates/components/x-type-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qnaa+tZy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"type-card\"],[7],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",[26,[\"type-card__icon icon-\",[18,\"icon\"]]]],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"type-card__title\"],[7],[0,\"\\n    \"],[1,[25,\"t\",[[20,[\"title\"]]],null],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"type-card__text bd-text-sm\"],[7],[0,\"\\n    \"],[1,[25,\"t\",[[20,[\"text\"]]],null],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[11,1],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-type-card.hbs"
    }
  });
  _exports.default = _default;
});