define("bd-hybrid-cloud/components/modals/x-project-sample-note-modal", ["exports", "bgr-ember-modal/components/bgr-modal"], function (_exports, _bgrModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _bgrModal.default.extend({
    /**
     * State
     */

    meta: null,
    /**
     * Computed
     */

    subtitle: Ember.computed('model.sample.draft', function () {
      var sample = this.get('model.sample');
      return sample.get('draft') ? this.get('trans').t('general.draft') : sample.get('subtitle');
    }),
    /**
     * Actions
     */

    actions: {
      updateNote: function updateNote(content) {
        var _this = this;
        var meta = this.get('meta');
        meta.set('note', content);
        meta.save().then(function () {
          _this.resolve(content);
        });
      }
    },
    /**
     * Hooks
     */
    afterShow: function afterShow() {
      var _this2 = this;
      var sample = this.get('model.sample');
      var project = this.get('model.project');
      project.findMeta('samples', sample.get('id')).then(function (meta) {
        _this2.set('meta', meta);
      });
    }
  });
  _exports.default = _default;
});