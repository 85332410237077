define("bd-hybrid-cloud/templates/partials/share/detail-panel-section/component/detail-zipper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ezR1C1wO",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-panel__section u-capitalize\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n    \"],[6,\"table\"],[9,\"class\",\"table\"],[7],[0,\"\\n      \"],[6,\"tr\"],[9,\"class\",\"table__row\"],[7],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"table__cell\"],[7],[0,\"\\n          \"],[1,[25,\"t\",[\"general.type\"],null],false],[0,\":\\n        \"],[8],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"table__cell\"],[7],[0,\"\\n          \"],[1,[20,[\"selectedZipperType\",\"name\"]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/share/detail-panel-section/component/detail-zipper.hbs"
    }
  });
  _exports.default = _default;
});