define("bd-hybrid-cloud/templates/app/projects/project/add/sketchbook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Q28MqACu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"x-app-content-overlay-view\",null,[[\"title\",\"subtitle\",\"previous\",\"onBackRoute\",\"onCloseRoute\"],[[20,[\"model\",\"title\"]],[25,\"t\",[\"projects.add.sketchbook\"],null],true,\"app.projects.project.add\",\"app.projects.project\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"gr-12 gr-8@desktop gr-centered\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n          \"],[1,[25,\"x-file-upload\",null,[[\"endpoint\",\"model\",\"type\",\"onFileUploadComplete\",\"onFileUploadStart\"],[[20,[\"endpoint\"]],[20,[\"model\"]],\"images\",[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"isUploading\"]]],null],false],null],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"isUploading\"]]],null],true],null]]]],false],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row u-text-center\"],[7],[0,\"\\n          \"],[4,\"link-to\",[\"app.projects.project\"],[[\"disabled\",\"class\"],[[20,[\"isUploading\"]],[25,\"concat\",[\"bd-button bd-button--regular \",[25,\"if\",[[25,\"not\",[[20,[\"isUploading\"]]],null],\"bd-button--solid-blue\",\"bd-button--solid-grey-dark disabled\"],null]],null]]],{\"statements\":[[1,[25,\"t\",[\"actions.done\"],null],false]],\"parameters\":[]},null],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/projects/project/add/sketchbook.hbs"
    }
  });
  _exports.default = _default;
});