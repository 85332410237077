define("bd-hybrid-cloud/templates/partials/share/detail-panel-section/component/detail-piping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dENPXaXR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"pipingMaterial\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"editor-panel__section u-capitalize\"],[7],[0,\"\\n    \"],[6,\"table\"],[9,\"class\",\"table\"],[7],[0,\"\\n      \"],[6,\"tr\"],[9,\"class\",\"table__row\"],[7],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"table__cell\"],[7],[0,\"\\n          \"],[1,[25,\"t\",[\"configurations.general.material\"],null],false],[0,\":\\n        \"],[8],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"table__cell\"],[7],[0,\"\\n          \"],[1,[18,\"pipingMaterialName\"],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"tr\"],[9,\"class\",\"table__row\"],[7],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"table__cell\"],[7],[0,\"\\n          \"],[1,[25,\"t\",[\"configurations.general.diameter\"],null],false],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"table__cell\"],[7],[0,\"\\n          \"],[1,[20,[\"selectedPipingDiameter\",\"label\"]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/share/detail-panel-section/component/detail-piping.hbs"
    }
  });
  _exports.default = _default;
});