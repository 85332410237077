define("bd-hybrid-cloud/models/collection-theme", ["exports", "ember-data", "bgr-ember-json-api/model", "bd-hybrid-cloud/mixins/model/project", "ember-data-storefront/mixins/loadable-model"], function (_exports, _emberData, _model, _project, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _model.default.extend(_loadableModel.default, _project.default, {
    isInternal: attr('boolean'),
    collection: belongsTo('collection')
  });
  _exports.default = _default;
});