define("bd-hybrid-cloud/mixins/route/reset-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }
  });
  _exports.default = _default;
});