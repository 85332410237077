define("bd-hybrid-cloud/mixins/route/model-loading-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    /**
     * Injections
     */

    loadingState: Ember.inject.service(),
    /**
     * Actions
     */

    actions: {
      loading: function loading(transition) {
        var _this = this;
        this.set('loadingState.isLoading', true);
        transition.finally(function () {
          _this.set('loadingState.isLoading', false);
        });
      }
    }
  });
  _exports.default = _default;
});