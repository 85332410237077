define("bd-hybrid-cloud/components/x-form-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed;
  var CLASS_NAME = 'form-control';
  var _default = Component.extend({
    /**
     * Props
     */

    bindTo: null,
    disabled: false,
    label: '',
    name: '',
    onChange: null,
    type: 'checkbox',
    value: null,
    /**
     * State
     */

    classNameBindings: ['typeClass'],
    classNames: [CLASS_NAME],
    tagName: 'label',
    /**
     * Computed
     */

    typeClass: computed('type', function () {
      return "".concat(CLASS_NAME, "--").concat(this.get('type'));
    }),
    visualClass: computed('type', function () {
      return "".concat(CLASS_NAME, "__visual--").concat(this.get('type'));
    }),
    /**
     * Actions
     */

    actions: {
      change: function change() {
        if (this.get('type') !== 'radio') {
          this.toggleProperty('bindTo');
        }
        this.sendAction('onChange');
      }
    }
  });
  _exports.default = _default;
});