define("bd-hybrid-cloud/utils/glide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = glide;
  function glide(url, hash) {
    var query = Object.keys(hash).map(function (key) {
      return "".concat(key, "=").concat(hash[key]);
    });
    var glideURL = url;
    if (query.length) {
      glideURL += "?".concat(query.join('&'));
    }
    return encodeURI(glideURL);
  }
});