function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
define("bd-hybrid-cloud/components/x-edit-description-form", ["exports", "bd-hybrid-cloud/components/x-form"], function (_exports, _xForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var _default = _xForm.default.extend({
    /**
     * Props
     */

    project: null,
    tags: null,
    /**
     * State
     */

    description: '',
    title: '',
    selectedTags: [],
    private: false,
    /*
    * Actions
    */

    actions: {
      onTagsChange: function onTagsChange(name, tags) {
        this.set('selectedTags', tags);
      },
      onTagsCreate: function onTagsCreate(select) {
        var tag = select.searchText;
        if (tag && event.keyCode === 13 && select.isOpen && !select.highlighted) {
          this.createTag(tag);
        }
      },
      searchTags: function searchTags(q) {
        var tags = [];
        tags = this.get('tags').filter(function (tag) {
          return tag.get('name').toLowerCase().includes(q.toLowerCase());
        });
        this.set('tagOptions', tags);
      }
    },
    /**
     * Hooks
     */
    request: function request() {
      var _this = this;
      var project = this.get('project');
      project.setProperties({
        description: this.get('description'),
        title: this.get('title'),
        isPrivate: this.get('private')
      });
      return project.save().then(function () {
        project.set('tags', _this.get('selectedTags'));
        project.saveRelationship('tags').then(function () {
          _this.get('router').transitionTo('app.projects.project', project.id);
        });
      });
    },
    /*
    * Methods
    */
    createTag: function createTag(input) {
      var _this2 = this;
      var store = this.get('store');
      var tag = store.createRecord('tag', {
        name: input
      });
      tag.save().then(function () {
        _this2.addTag(tag);
      });
    },
    addTag: function addTag(tag) {
      var selectedTags = this.get('selectedTags');
      this.set('selectedTags', [].concat(_toConsumableArray(selectedTags), [tag]));
    },
    /**
     * Init
     */
    init: function init() {
      this._super.apply(this, arguments);
      var project = this.get('project');
      var properties = {
        tagOptions: this.get('tags')
      };
      if (!project.get('isNew')) {
        properties = _objectSpread(_objectSpread({}, properties), {}, {
          description: project.get('description'),
          title: project.get('title'),
          private: project.get('isPrivate'),
          selectedTags: project.get('tags').toArray(),
          tagOptions: this.get('tags')
        });
      }
      this.setProperties(properties);
    }
  });
  _exports.default = _default;
});