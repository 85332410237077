define("bd-hybrid-cloud/templates/components/x-editor-instruction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vxGIc+7i",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-instruction__content\"],[7],[0,\"\\n  \"],[11,1],[0,\"\\n  \"],[6,\"p\"],[9,\"class\",\"editor-instruction__text\"],[7],[1,[18,\"text\"],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"arrowPosition\"]]],null,{\"statements\":[[0,\"  \"],[6,\"img\"],[10,\"src\",[26,[\"/assets/images/interface/graphics/coachmark-\",[18,\"arrowPosition\"],\".svg\"]]],[10,\"class\",[26,[\"editor-instruction__arrow editor-instruction__arrow--\",[18,\"arrowPosition\"]]]],[9,\"width\",\"150\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-editor-instruction.hbs"
    }
  });
  _exports.default = _default;
});