define("bd-hybrid-cloud/helpers/cx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cx = cx;
  _exports.default = void 0;
  function cx(classNames) {
    return classNames.join(' ');
  }
  var _default = Ember.Helper.helper(cx);
  _exports.default = _default;
});