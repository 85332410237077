define("bd-hybrid-cloud/components/modals/x-save-configuration-as-cover-modal", ["exports", "bgr-ember-modal/components/bgr-modal"], function (_exports, _bgrModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = _bgrModal.default.extend({
    /**
     * State
     */

    configurationCategories: [],
    selectedCategories: null,
    title: '',
    /**
     * Computed
     */

    allowSubmit: computed.and('title.length', 'selectedCategories.length'),
    categories: computed('configurationCategories.length', function () {
      return this.get('configurationCategories').filter(function (category) {
        return category.get('isParent');
      }).map(function (filteredCategory) {
        return {
          groupName: filteredCategory.get('name'),
          options: filteredCategory.get('children').map(function (childCategory) {
            return childCategory;
          })
        };
      });
    }),
    /**
     * Hooks
     */
    afterShow: function afterShow() {
      var _this = this;
      this.get('store').findAll('configurationCategory', {
        include: 'children'
      }).then(function (configurationCategories) {
        _this.set('configurationCategories', configurationCategories);
      });
    },
    /**
     * Actions
     */

    actions: {
      submit: function submit() {
        var _this2 = this;
        var title = this.get('title');
        var configData = this.get('model.configuration');
        var configuration = this.get('store').createRecord('configuration', {
          backgroundImage: configData.backgroundImage,
          categories: this.get('selectedCategories'),
          coverTemplate: true,
          json: configData.json,
          thumb: configData.thumb,
          title: title
        });
        configuration.addRelationships({
          quilts: configData.quilts,
          samples: configData.samples
        }).then(function () {
          configuration.save().then(function () {
            var successMessage = _this2.get('trans').t('alert.success.savedName', {
              name: title
            });
            _this2.hide();
            _this2.get('flashMessages').success(successMessage);
            _this2.get('router').transitionTo('app.configuration.configuration.edit', configuration);
          });
        });
      }
    }
  });
  _exports.default = _default;
});