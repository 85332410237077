define("bd-hybrid-cloud/helpers/dasherize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dasherize = dasherize;
  _exports.default = void 0;
  function dasherize(params) {
    return params[0].dasherize();
  }
  var _default = Ember.Helper.helper(dasherize);
  _exports.default = _default;
});