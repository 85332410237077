define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/component/piping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5KAs3ue6",
    "block": "{\"symbols\":[],\"statements\":[[12,\"partials/configuration/edit/editor-panel-section/component/edit-piping-material\",[]],[0,\"\\n\"],[12,\"partials/configuration/edit/editor-panel-section/component/edit-piping-color\",[]],[0,\"\\n\"],[12,\"partials/configuration/edit/editor-panel-section/component/edit-piping-diameter\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/component/piping.hbs"
    }
  });
  _exports.default = _default;
});