define("bd-hybrid-cloud/components/x-note-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed,
    on = Ember.on;
  var _default = Component.extend({
    /**
     * Props
     */

    allowEmpty: false,
    content: '',
    isEditable: true,
    onSubmit: null,
    /**
     * State
     */

    classNames: ['form'],
    tagName: 'form',
    /**
     * Computed
     */

    isDisabled: computed('allowEmpty', 'content', function () {
      return !this.get('allowEmpty') && !this.get('content');
    }),
    /**
     * Events
     */

    submitHandler: on('submit', function (event) {
      event.preventDefault();
      this.sendAction('onSubmit', this.get('content'));
    })
  });
  _exports.default = _default;
});