define("bd-hybrid-cloud/templates/components/modals/x-image-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "72lZvzoq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bgr-modal-template\",null,null,{\"statements\":[[4,\"if\",[[19,0,[\"canNavigateToPreviousSlide\"]]],null,{\"statements\":[[0,\"    \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--icon-lg bd-button--blue navigate-button navigate-button--previous\"],[3,\"action\",[[19,0,[]],[19,0,[\"onPreviousClick\"]]]],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"icon-chevron-left-light\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[6,\"img\"],[9,\"class\",\"mt-xs u-center u-fluid-image\"],[10,\"alt\",[26,[[19,0,[\"activeImage\",\"caption\"]]]]],[10,\"src\",[26,[[19,0,[\"activeImage\",\"url\"]]]]],[7],[8],[0,\"\\n  \\n\"],[4,\"if\",[[19,0,[\"canNavigateToNextSlide\"]]],null,{\"statements\":[[0,\"    \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--icon-lg bd-button--blue navigate-button navigate-button--next\"],[3,\"action\",[[19,0,[]],[19,0,[\"onNextClick\"]]]],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"icon-chevron-right-light\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/modals/x-image-modal.hbs"
    }
  });
  _exports.default = _default;
});