define("bd-hybrid-cloud/serializers/user", ["exports", "bd-hybrid-cloud/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    attrs: {
      registrationDate: {
        serialize: false
      },
      sha: {
        serialize: false
      }
    }
  });
  _exports.default = _default;
});