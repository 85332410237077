define("bd-hybrid-cloud/templates/components/x-confirmation-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fGGzdyfq",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"input\",null,[[\"class\",\"enter\",\"escape-press\",\"focus-out\",\"value\"],[\"confirmation-input__input bd-input\",\"confirm\",\"cancel\",\"confirm\",[20,[\"internalValue\"]]]]],false],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"confirmation-input__icon icon-pencil\"],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-confirmation-input.hbs"
    }
  });
  _exports.default = _default;
});