define("bd-hybrid-cloud/models/course", ["exports", "ember-data", "bgr-ember-json-api/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _model.default.extend({
    content: attr(),
    documentPath: attr(),
    thumbUrl: attr(),
    time: attr(),
    title: attr(),
    vimeoId: attr(),
    courseCategory: belongsTo('courseCategory')
  });
  _exports.default = _default;
});