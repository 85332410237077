define("bd-hybrid-cloud/templates/components/x-configurator-custom-property-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "g3lnh9LF",
    "block": "{\"symbols\":[\"prop\"],\"statements\":[[4,\"each\",[[20,[\"properties\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"x-configurator-custom-property-item\",null,[[\"property\",\"model\",\"openSamplePanel\"],[[19,1,[]],[20,[\"model\"]],[25,\"action\",[[19,0,[]],\"onOpenSamplePanel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-configurator-custom-property-panel.hbs"
    }
  });
  _exports.default = _default;
});