define("bd-hybrid-cloud/templates/partials/share/detail-panel/component/3d-border", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1VnFB8/o",
    "block": "{\"symbols\":[],\"statements\":[[12,\"partials/share/detail-panel-section/component/detail-color\",[]],[0,\"\\n\"],[12,\"partials/share/detail-panel-section/component/detail-quilt\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/share/detail-panel/component/3d-border.hbs"
    }
  });
  _exports.default = _default;
});