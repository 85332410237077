define("bd-hybrid-cloud/components/x-scroll-to-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = Ember.$,
    Component = Ember.Component;
  var _default = Component.extend({
    /**
     * Actions
     */

    actions: {
      scroll: function scroll() {
        $('.app-content').animate({
          scrollTop: 0
        }, 500);
      }
    }
  });
  _exports.default = _default;
});