define("bd-hybrid-cloud/templates/components/x-bd3d-canvas-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jdnCF4On",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"bd3d-canvas-loader__bar-container\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"bd3d-canvas-loader__bar\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"bd3d-canvas-loader__bar\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"bd3d-canvas-loader__bar\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"bd3d-canvas-loader__bar\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-bd3d-canvas-loader.hbs"
    }
  });
  _exports.default = _default;
});