define("bd-hybrid-cloud/components/x-app-content-overlay-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Props
     */

    previous: false,
    subtitle: '',
    title: '',
    onCloseRoute: null,
    onBackRoute: null,
    /**
     * Actions
     */

    actions: {
      back: function back() {
        var onBackRoute = this.get('onBackRoute');
        if (onBackRoute) {
          this.get('router').transitionTo(onBackRoute);
        } else {
          this.get('router').transitionTo('app');
        }
      },
      close: function close() {
        var onCloseRoute = this.get('onCloseRoute');
        if (onCloseRoute) {
          this.get('router').transitionTo(onCloseRoute);
        } else {
          this.get('router').transitionTo('app');
        }
      }
    }
  });
  _exports.default = _default;
});