define("bd-hybrid-cloud/utils/type-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isEmail;
  function isEmail(string) {
    var re = new RegExp('[^@]+@[^@]+\\.[^@]+');
    return re.test(string);
  }
});