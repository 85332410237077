define("bd-hybrid-cloud/mixins/controller/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    Mixin = Ember.Mixin;
  var _default = Mixin.create({
    /**
     * State
     */

    viewMode: 'view',
    /**
     * Computed
     */

    configurationFilePath: computed('model.category', function () {
      var filePath = '';
      var model = this.get('model');
      var modelName = this.get('model.title');
      if (model.get('standardTemplate')) {
        filePath = 'Playground';
      } else if (model.get('coverTemplate')) {
        filePath = 'Cover Solutions';
      } else if (model.get('pillowTemplate')) {
        filePath = 'Pillow Templates';
      }
      return "".concat(filePath, " / <b>").concat(modelName, "</b>");
    }),
    isShareable: computed.bool('model.project.id'),
    /**
     * Methods
     */
    setViewMode: function setViewMode(mode) {
      this.set('viewMode', mode);
      this.get('bd3d').autoSize();
    },
    showShareError: function showShareError() {
      this.get('flashMessages').warning(this.get('trans').t('alert.warning.shareConfiguration'));
    },
    /**
     * Actions
     */

    actions: {
      openConfigurationInfoModal: function openConfigurationInfoModal() {
        this.get('modal').show('configuration-info');
      },
      openDownloadConfigurationModal: function openDownloadConfigurationModal() {
        if (this.get('isShareable')) {
          this.get('modal').show('download-configuration', this.get('model'));
        } else {
          this.showShareError();
        }
      }
    }
  });
  _exports.default = _default;
});