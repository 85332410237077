define("bd-hybrid-cloud/routes/app/projects/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP;
  var _default = Ember.Route.extend({
    actions: {
      willTransition: function willTransition() {
        this.get('controller.model.project').rollbackAttributes();
      }
    },
    model: function model() {
      return RSVP.hash({
        project: this.get('store').createRecord('project'),
        tags: this.get('store').findAll('tag')
      });
    }
  });
  _exports.default = _default;
});