define("bd-hybrid-cloud/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    sessionAuthenticated: function sessionAuthenticated() {
      var redirectUri = this.get('session.redirectUriAfterAuthentication');
      if (redirectUri) {
        this.set('session.redirectUriAfterAuthentication', null);
        this.transitionTo("/".concat(decodeURIComponent(redirectUri)));
      } else {
        this._super();
      }
    }
  });
  _exports.default = _default;
});