define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZeIr0sqq",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"x-configurator-editor-panel\",null,[[\"componentType\",\"discardSelection\",\"model\",\"openSectionPanel\",\"sectionParams\"],[[20,[\"component\"]],\"discardSelection\",[20,[\"selectedComponentData\"]],\"openSectionPanel\",[20,[\"sectionParams\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/component.hbs"
    }
  });
  _exports.default = _default;
});