define("bd-hybrid-cloud/mixins/component/add-to-project-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    isBlank = Ember.isBlank,
    Mixin = Ember.Mixin;
  var _default = Mixin.create({
    /**
     * Services
     */

    storeService: Ember.inject.service('store'),
    /**
     * State
     */

    addToNewProject: false,
    newProjectTitle: '',
    projects: [],
    selectedProject: null,
    /**
     * Computed
     */

    hasNoProject: computed('addToNewProject', 'newProjectTitle', 'selectedProject', function () {
      return this.get('addToNewProject') ? isBlank(this.get('newProjectTitle')) : isBlank(this.get('selectedProject'));
    }),
    isDisabled: computed.alias('hasNoProject'),
    /**
     * Methods
     */
    getRelatedProject: function getRelatedProject() {
      var _this = this;
      return new Promise(function (resolve) {
        if (_this.get('addToNewProject')) {
          var newProject = _this.get('store').createRecord('project', {
            title: _this.get('newProjectTitle')
          });
          newProject.save().then(function () {
            resolve(newProject);
          });
        } else {
          resolve(_this.get('selectedProject'));
        }
      });
    },
    /**
     * Hooks
     */
    afterShow: function afterShow() {
      var _this2 = this;
      var query = {
        filter: {
          archived: false,
          include_shared: true
        },
        per_page: 1000,
        sort: 'title'
      };

      // TODO: perform async search
      this.get('storeService').loadRecords('project', query).then(function (projects) {
        _this2.set('projects', projects);
      });
    }
  });
  _exports.default = _default;
});