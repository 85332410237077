define("bd-hybrid-cloud/controllers/app/collections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COLLECTION_ROUTE = 'app.collections.collection';
  var _default = Ember.Controller.extend({
    /**
     * Services
     */

    routerService: Ember.inject.service('router'),
    /**
     * Actions
     */
    onCollectionsLoadedHandler: function onCollectionsLoadedHandler(collections) {
      var owner = Ember.getOwner(this);
      var collectionRoute = owner.lookup("route:".concat(COLLECTION_ROUTE));
      var collectionRouteParams = collectionRoute.paramsFor(COLLECTION_ROUTE);
      if (!collectionRouteParams.id) {
        var firstCollection = collections && collections.get('firstObject');
        if (firstCollection) {
          this.get('routerService').replaceWith(COLLECTION_ROUTE, firstCollection.get('id'));
        }
      }
    }
  });
  _exports.default = _default;
});