define("bd-hybrid-cloud/initializers/injections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    // factory:name(:alias)
    var injections = {
      'router:main:router': ['component', 'controller', 'route'],
      'service:active-user': ['component', 'controller', 'model', 'route'],
      'service:api': ['component', 'controller', 'model', 'route'],
      'service:bd3d': ['component', 'controller', 'route'],
      'service:flashMessages': ['component', 'controller', 'route'],
      'service:i18n:trans': ['component', 'controller', 'helper', 'model', 'route'],
      'service:bgr-modal:modal': ['component', 'controller', 'route'],
      'service:session': ['component', 'controller'],
      'service:store': ['component']
    };
    Object.keys(injections).forEach(function (injection) {
      var segments = injection.split(':');
      var factory = segments[0];
      var name = segments[1];
      var namedFactory = "".concat(factory, ":").concat(name);
      var alias = (segments[2] || name).camelize();
      injections[injection].forEach(function (target) {
        application.inject(target, alias, namedFactory);
      });
    });
  }
  var _default = {
    initialize: initialize,
    name: 'injections'
  };
  _exports.default = _default;
});