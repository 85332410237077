define("bd-hybrid-cloud/templates/app/collection-theme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "I7Y6WWik",
    "block": "{\"symbols\":[\"isLoading\",\"collectionTheme\"],\"statements\":[[4,\"load-record\",null,[[\"modelName\",\"id\",\"reload\"],[\"collection-theme\",[19,0,[\"model\",\"id\"]],true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[19,1,[]]],null,{\"statements\":[[0,\"    \"],[1,[18,\"app-content-loader\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[19,2,[]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"x-project-view\",null,[[\"model\",\"parentRoute\"],[[19,2,[]],\"app.collections\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/collection-theme.hbs"
    }
  });
  _exports.default = _default;
});