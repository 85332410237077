define("bd-hybrid-cloud/templates/components/configuration-import-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8TUV3ikQ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-panel__section\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"u-text-sm\"],[7],[0,\"\\n      \"],[1,[25,\"t\",[\"configurations.importJson.instructions\"],null],true],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n    \"],[6,\"textarea\"],[9,\"class\",\"bd-textarea\"],[10,\"oninput\",[25,\"action\",[[19,0,[]],[19,0,[\"updateJsonValue\"]]],[[\"value\"],[\"target.value\"]]],null],[10,\"placeholder\",[25,\"t\",[\"configurations.importJson.textareaPlaceholder\"],null],null],[10,\"value\",[19,0,[\"jsonValue\"]],null],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"if\",[[19,0,[\"isInvalidJson\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"section__row section__row--xs\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"error\"],[7],[1,[25,\"t\",[\"configurations.importJson.errorMessage\"],null],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[6,\"div\"],[9,\"class\",\"section__row section__row--xs\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--regular bd-button--solid\"],[10,\"disabled\",[25,\"is-empty\",[[19,0,[\"jsonValue\"]]],null],null],[10,\"onclick\",[25,\"action\",[[19,0,[]],[19,0,[\"applyJsonConfig\"]]],null],null],[7],[0,\"\\n      \"],[1,[25,\"t\",[\"actions.apply\"],null],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/configuration-import-json.hbs"
    }
  });
  _exports.default = _default;
});