define("bd-hybrid-cloud/templates/components/x-configuration-quilt-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xXn5E8Jb",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"editor__subtitle u-text-center u-capitalize\"],[7],[0,\"\\n    \"],[1,[25,\"t\",[\"modelNames.quilt\"],null],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"editor-panel__button editor-panel__button--stretch is-disabled\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align align--fluid align--gutter\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"quilt\",\"thumbUrl\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"align__cell align__cell--shrink\"],[7],[0,\"\\n          \"],[6,\"img\"],[10,\"src\",[26,[[20,[\"quilt\",\"thumbUrl\"]]]]],[9,\"width\",\"90\"],[9,\"height\",\"90\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n        \"],[1,[18,\"title\"],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-configuration-quilt-detail.hbs"
    }
  });
  _exports.default = _default;
});