define("bd-hybrid-cloud/mixins/controller/selected-samples", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var Mixin = Ember.Mixin;
  var _default = Mixin.create({
    /**
     * State
     */

    selectedSamples: [],
    /**
     * Actions
     */

    actions: {
      onChange: function onChange(sample) {
        var checked = sample.get('checked');
        var selectedSamples = _toConsumableArray(this.get('selectedSamples'));
        var index = selectedSamples.findIndex(function (item) {
          return item.get('id') === sample.get('id');
        });
        if (checked && index < 0) {
          selectedSamples.push(sample);
        } else if (!checked && index >= 0) {
          selectedSamples.splice(index, 1);
        }
        this.set('selectedSamples', selectedSamples);
      },
      onClick: function onClick(sample) {
        var selectedSamples = _toConsumableArray(this.get('selectedSamples'));
        var index = selectedSamples.findIndex(function (item) {
          return item.get('id') === sample.get('id');
        });
        if (index < 0) {
          selectedSamples.push(sample);
          sample.set('checked', true);
        } else if (index >= 0) {
          selectedSamples.splice(index, 1);
          sample.set('checked', false);
        }
        this.set('selectedSamples', selectedSamples);
      },
      clearSelectedSamples: function clearSelectedSamples() {
        this.get('selectedSamples').setEach('checked', false);
        this.set('selectedSamples', []);
      },
      selectAllSamples: function selectAllSamples(samples) {
        var allSamples = samples || this.get('model.samples');
        var selectedSamples = allSamples.toArray();
        selectedSamples.setEach('checked', true);
        this.set('selectedSamples', selectedSamples);
      }
    }
  });
  _exports.default = _default;
});