define("bd-hybrid-cloud/templates/components/multiple-selection-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mvqackoa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"multiple-selection-toolbar__content\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"align align--fluid align--gutter-xs\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align__cell align__cell--shrink\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--icon-sm bd-button--green\"],[3,\"action\",[[19,0,[]],\"clearSelectedItems\"]],[7],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"icon-discard\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align__cell bd-text-grey-dark\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"align align--gutter-xs\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"align__cell align__cell--shrink\"],[7],[0,\"\\n          \"],[1,[25,\"t\",[[19,0,[\"itemsSelectedTranslationKey\"]]],[[\"count\"],[[19,0,[\"totalSelectedItems\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n\"],[4,\"if\",[[25,\"gt\",[[19,0,[\"totalAvailableItems\"]],[19,0,[\"totalSelectedItems\"]]],null]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"align__cell align__cell--shrink\"],[7],[0,\"\\n          \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--text bd-button--grey-dark\"],[3,\"action\",[[19,0,[]],\"selectAllItems\"]],[7],[0,\"\\n            (\"],[1,[25,\"t\",[\"actions.selectAll\"],null],false],[0,\")\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align__cell align__cell--shrink u-text-no-wrap u-text-right\"],[7],[0,\"\\n      \"],[11,1],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/multiple-selection-toolbar.hbs"
    }
  });
  _exports.default = _default;
});