define("bd-hybrid-cloud/templates/components/x-grid-sorting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dWbj4zoZ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"ul\"],[9,\"class\",\"inline-list u-text-right\"],[7],[0,\"\\n  \"],[6,\"li\"],[9,\"class\",\"inline-list__item\"],[7],[0,\"\\n    \"],[6,\"button\"],[10,\"class\",[26,[\"bd-button bd-button--icon-sm bd-button--grey \",[18,\"chronologicalButtonClass\"]]]],[10,\"disabled\",[18,\"disableSorting\"],null],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"toggleSorting\",\"chronological\"]],[7],[0,\"\\n      \"],[6,\"span\"],[10,\"class\",[26,[[18,\"chronologicalIconClass\"]]]],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"li\"],[9,\"class\",\"inline-list__item\"],[7],[0,\"\\n    \"],[6,\"button\"],[10,\"class\",[26,[\"bd-button bd-button--icon-sm bd-button--grey \",[18,\"alphabeticalButtonClass\"]]]],[10,\"disabled\",[18,\"disableSorting\"],null],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"toggleSorting\",\"alphabetical\"]],[7],[0,\"\\n      \"],[6,\"span\"],[10,\"class\",[26,[[18,\"alphabeticalIconClass\"]]]],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-grid-sorting.hbs"
    }
  });
  _exports.default = _default;
});