define("bd-hybrid-cloud/models/photo", ["exports", "ember-data", "bd-hybrid-cloud/utils/glide", "bgr-ember-json-api/model"], function (_exports, _emberData, _glide, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _model.default.extend({
    /**
     * Props
     */

    caption: attr('string'),
    crop: attr('json'),
    identifier: attr('string'),
    project: belongsTo('project'),
    url: attr('string'),
    /**
     * Computed
     */

    croppedImage: computed('crop', 'url', function () {
      var url = this.get('url');
      var crop = this.get('crop');
      var croppedImage;
      if (crop) {
        var co = crop.coordinates;
        var or = crop.orientation;
        croppedImage = (0, _glide.default)(url, {
          crop: [co.width, co.height, co.x, co.y].toString(),
          or: or
        });
      } else {
        croppedImage = url;
      }
      return croppedImage;
    }),
    title: computed.alias('caption')
  });
  _exports.default = _default;
});