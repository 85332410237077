define("bd-hybrid-cloud/tailwind/config/margin", ["exports", "bd-hybrid-cloud/tailwind/config/sizes"], function (_exports, _sizes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  |-----------------------------------------------------------------------------
  | Margin                                  https://tailwindcss.com/docs/margin
  |-----------------------------------------------------------------------------
  |
  | Here is where you define your margin utility sizes. These can be
  | percentage based, pixels, rems, or any other units. By default we
  | provide a sensible rem based numeric scale plus a couple other
  | common use-cases like "1px". You can, of course, modify these
  | values as needed.
  |
  | Class name: .m{side?}-{size}
  | CSS property: margin
  |
  */
  var _default = Object.assign({
    auto: 'auto',
    '0': 0
  }, _sizes.default);
  _exports.default = _default;
});