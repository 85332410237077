define("bd-hybrid-cloud/helpers/glide", ["exports", "bd-hybrid-cloud/utils/glide"], function (_exports, _glide) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.glide = glide;
  function glide(params, hash) {
    return (0, _glide.default)(params[0], hash);
  }
  var _default = Ember.Helper.helper(glide);
  _exports.default = _default;
});