define("bd-hybrid-cloud/models/theme", ["exports", "ember-data", "bgr-ember-json-api/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _model.default.extend({
    collection: belongsTo('collection'),
    description: attr('string'),
    name: attr('string'),
    sort: attr('number'),
    style: belongsTo('style'),
    tagline: attr('string'),
    translations: attr()
  });
  _exports.default = _default;
});