define("bd-hybrid-cloud/components/forms/x-register-form", ["exports", "moment", "bd-hybrid-cloud/components/x-form"], function (_exports, _moment, _xForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on;
  var _default = _xForm.default.extend({
    /**
     * Props
     */

    countries: [],
    instance: null,
    /**
     * State
     */

    country: null,
    model: Ember.Object.create({
      company: '',
      countryId: null,
      email: '',
      firstName: '',
      lastName: '',
      newsletter: false,
      password: '',
      passwordConfirmation: '',
      role: ''
    }),
    now: _moment.default.now(),
    salesRep: null,
    terms: false,
    /**
     * Events
     */

    didInsertElementHandler: on('didInsertElement', function () {
      this.set('instance', this);
    }),
    /**
     * Hooks
     */
    request: function request() {
      var _this = this;
      var salesRepId;
      var model = this.get('model');
      if (this.salesRep) {
        salesRepId = this.salesRep.id;
      }
      model.setProperties({
        countryId: this.get('country.id'),
        passwordConfirmation: model.get('password'),
        salesRepId: salesRepId
      });
      return this.get('api').post('users/register', model).then(function () {
        _this.get('router').transitionTo('login');
        _this.get('flashMessages').success(_this.get('trans').t('alert.success.registration'));
      });
    },
    actions: {
      onCountrySelect: function onCountrySelect(country) {
        this.setProperties({
          country: country,
          salesRep: null
        });
      }
    }
  });
  _exports.default = _default;
});