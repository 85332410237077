define("bd-hybrid-cloud/components/x-configurator-edit-sample", ["exports", "bd-hybrid-cloud/mixins/component/configuration-sample-detail"], function (_exports, _configurationSampleDetail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TEXTURE_ALIGN_MAP = {
    x: {
      bottom: 'getBottomSampleAlignX',
      fabric: 'getBorderComponentTextureAlignX',
      top: 'getTopSampleAlignX'
    },
    y: {
      bottom: 'getBottomSampleAlignY',
      fabric: 'getBorderComponentTextureAlignY',
      top: 'getTopSampleAlignY'
    }
  };
  var Component = Ember.Component,
    on = Ember.on;
  var _default = Component.extend(_configurationSampleDetail.default, {
    /**
     * Props
     */

    allowOffsetEdit: true,
    allowRotationEdit: true,
    sectionPanelName: 'select-sample',
    x: 0,
    y: 0,
    /**
     * Events
     */

    initHandler: on('init', function () {
      this.getSampleAlignment();
    }),
    /**
     * Methods
     */
    getSampleAlignment: function getSampleAlignment() {
      var x = 0;
      var y = 0;
      var mattressConfig = this.get('bd3d.mattressConfig');
      var mattressConfigurator = this.get('bd3d.mattressConfigurator');
      if (mattressConfig && mattressConfigurator) {
        var data = mattressConfigurator.getSelectedData();
        var info = mattressConfig.getObjectInfo(data);
        if (data && info) {
          var componentType = info.type;
          var selectedSingle = info.single;
          if (componentType === 'top' || componentType === 'bottom') {
            x = mattressConfig[TEXTURE_ALIGN_MAP.x[componentType]](selectedSingle);
            y = mattressConfig[TEXTURE_ALIGN_MAP.y[componentType]](selectedSingle);
          } else if (componentType === 'fabric') {
            x = mattressConfig[TEXTURE_ALIGN_MAP.x[componentType]](selectedSingle, data);
            y = mattressConfig[TEXTURE_ALIGN_MAP.y[componentType]](selectedSingle, data);
          } else if (componentType === 'custom') {
            this.setProperties({
              x: x,
              y: y
            });
          }
        }
      }
    },
    /**
     * Actions
     */

    actions: {
      openSectionPanel: function openSectionPanel(section) {
        this.sendAction('openSectionPanel', section, this.get('params'));
      },
      onAlignChange: function onAlignChange() {
        this.sendAction('onAlignChange');
      },
      onAlignSlide: function onAlignSlide(axis, value) {
        this.set(axis, value);
        this.sendAction('onAlignSlide', axis, value);
      },
      onRotateClick: function onRotateClick(direction) {
        this.sendAction('onRotate', direction);
      }
    }
  });
  _exports.default = _default;
});