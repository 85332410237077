define("bd-hybrid-cloud/routes/share/sample", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.get('store').queryRecord('sample', {
        identifier: params.identifier,
        include: 'brands,collection,colors,style,theme',
        share: true
      });
    }
  });
  _exports.default = _default;
});