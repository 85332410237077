define("bd-hybrid-cloud/controllers/app/settings/configurator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var filterBy = computed.filterBy;
  var BACKGROUND_IMAGES_ENDPOINT = 'background-images';
  var GENERAL_BACKGROUND_IMAGES_ENDPOINT = 'background-images/create-general-background';
  function getEndpoint(isGeneral) {
    return isGeneral ? GENERAL_BACKGROUND_IMAGES_ENDPOINT : BACKGROUND_IMAGES_ENDPOINT;
  }
  var _default = Ember.Controller.extend({
    /**
     * Computed
     */

    myBackgroundImages: filterBy('model', 'global', false),
    generalBackgroundImages: filterBy('model', 'global', true),
    canManageGeneralBackgrounds: computed.alias('activeUser.can.createEditGeneralBackground'),
    /**
     * Actions
     */

    actions: {
      deleteBackgroundImage: function deleteBackgroundImage(model) {
        var message = this.get('trans').t('confirm.delete', {
          name: model.get('title')
        });
        this.get('modal').confirm(message).then(function () {
          model.destroyRecord();
        });
      },
      openFileUploadModal: function openFileUploadModal(isGeneral) {
        var trans = this.get('trans');
        var modal = this.get('modal').create('file-upload', {
          allowCrop: false,
          endpoint: getEndpoint(isGeneral),
          title: trans.t('upload.modalTitle'),
          type: 'images'
        });
        modal.show();
      },
      updateUnits: function updateUnits() {
        var _this = this;
        this.get('activeUser.model').saveSettings().then(function () {
          _this.get('flashMessages').success(_this.get('trans').t('alert.success.settingsChanged'));
        });
      }
    }
  });
  _exports.default = _default;
});