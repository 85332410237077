define("bd-hybrid-cloud/locales/en/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    actions: {
      addHandles: 'Add handles',
      addToEntity: 'Add to {{entity}}',
      addToNewEntity: 'Add to New {{entity}}',
      addWithEntity: 'Add {{entity}}',
      addTo: 'Add to {{entity}}',
      apply: 'Apply',
      archive: 'Archive project',
      backToEntity: 'Back to {{entity}}',
      cancel: 'Cancel',
      chooseAEntity: 'Choose a {{entity}}',
      clear: 'Clear',
      clearAll: 'Clear all',
      confirm: 'Confirm',
      copy: 'Copy',
      create: 'Create',
      createCrop: 'Create Crop',
      cropImage: 'Crop Image',
      done: 'Done',
      download: 'Download',
      downloadAsEntity: 'Download as {{entity}}',
      duplicateEntity: 'Duplicate {{entity}}',
      edit: 'Edit',
      editCrop: 'Edit Crop',
      export: 'Export',
      filter: 'Apply Filter',
      finish: 'Finish',
      hideDetails: 'Hide details',
      logout: 'Logout',
      nextStep: 'Next Step',
      open: 'Open',
      previousStep: 'Previous Step',
      redo: 'Redo',
      remove: "Remove",
      removeHandles: 'Remove handles',
      removeWithEntity: 'Remove {{entity}}',
      rename: "Rename",
      save: 'Save',
      saveAsNewEntity: 'Save as new {{entity}}',
      saveChanges: 'Save changes',
      saveChangesToEntity: 'Save changes to {{entity}}',
      saveTemplateChanges: 'Overwrite template',
      saveToEntity: 'Save to {{entity}}',
      saveToNewEntity: 'Save to new {{entity}}',
      searchName: 'Search {{name}}',
      selectAll: 'select all',
      selectEntity: 'Select {{entity}}',
      send: 'Send',
      sendEntityByEmail: 'Send {{entity}} by email',
      share: 'Share',
      showDetails: 'Show details',
      submit: 'Submit',
      unarchive: 'Unarchive project',
      undo: 'Undo',
      unshare: 'Unshare',
      viewAll: 'View All',
      viewLess: 'View Less'
    },
    activityLog: {
      add: 'added',
      addProject: 'to project',
      archived: 'archived',
      at: 'at',
      configuration: 'configuration',
      create: 'added',
      createProject: 'to project',
      delete: 'deleted',
      deleteProject: 'from project',
      document: {
        one: 'document',
        other: '{{count}} documents'
      },
      draft: {
        one: 'draft',
        other: '{{count}} drafts'
      },
      loading: 'Loading activity...',
      note: {
        one: 'note',
        other: '{{count}} notes'
      },
      placeholder: 'This project does not have any activity.',
      project: 'project',
      sample: {
        one: 'sample',
        other: '{{count}} samples'
      },
      sketchbook: {
        one: 'sketchbook',
        other: '{{count}} sketchbooks'
      },
      title: 'Activity',
      unarchived: 'unarchived',
      unarchivedProject: 'of project',
      update: 'edited',
      updateProject: 'of project',
      updateDescription: 'changed the description to',
      updateDescriptionProject: 'of project',
      updateTitle: 'changed the title to',
      updateTitleProject: 'of project',
      updateNote: 'edited note of',
      updateNoteProject: 'of project',
      upload: 'uploaded',
      uploadProject: 'to project',
      view: 'View all activity'
    },
    alert: {
      danger: {
        addToCapsules: 'Failed to add project to Capsules. Please try again.',
        addToScoops: 'Failed to add project to Scoops. Please try again.',
        configurationDataNoLoaded: 'Failed to load the requested data using the current filter. Please reset or try again.',
        exportDownload: 'The showroom export could not be downloaded. Please try again.',
        general: 'Something went wrong. Please try again.',
        loginError: 'The username or password did not match. Please try again.',
        projectNotFound: 'This project was not found. Please verify if you created the project under this account.'
      },
      success: {
        addedModelToEntity: '<b>{{model}}</b> was added to <b>{{entity}}</b>.',
        assetRefreshed: 'Asset refreshed.',
        copiedToClipboard: 'Copied to clipboard!',
        deletedEntityWithName: '{{entity}} <b>{{name}}</b> has been deleted.',
        emailsChanged: 'Your email preferences have successfully been updated.',
        passwordChanged: 'Your password has successfully been updated. You will receive an email for confirmation.',
        profileChanged: 'Your profile information has successfully been modified.',
        registration: 'Registration successful.<br>You will receive an email soon to confirm your registration.',
        savedModelToEntity: '<b>{{model}}</b> was saved to <b>{{entity}}</b>.',
        savedName: '<b>{{name}}</b> has been saved.',
        settingsChanged: 'Your settings have successfully been updated.',
        sharedModelWithEntity: '<b>{{model}}</b> was shared with <b>{{entity}}</b>.'
      },
      warning: {
        configuratorMissingSampleAsset: 'This sample could not be applied on the mattress due to missing sample assets.',
        reportDimensions: 'Selected asset cannot be previewed on 3D mattress due to missing repeat sizes.',
        shareConfiguration: 'Save this configuration to a project if you would like to share it.',
        saveConfigurationBeforeSharing: 'Always save your configuration before sharing to ensure your recipients receive the latest changes.'
      }
    },
    auth: {
      browserRecommendationTitle: 'Browser recommendation',
      browserRecommendationText: 'Use Google Chrome or Mozilla Firefox for the best experience',
      centimeter: 'Centimeter',
      city: 'City',
      company: 'Company',
      confirmPassword: 'Confirm Password',
      confirmNewPassword: 'Confirm New Password',
      contact: 'My BekaertDeslee contact',
      country: 'Country',
      currentPassword: 'Current Password',
      email: 'Email address',
      firstName: 'First name',
      forgotPassword: 'Forgot your password?',
      inch: 'Inch',
      invalidToken: 'The provided token was invalid.',
      language: 'Language',
      lastName: 'Last name',
      login: 'Login',
      loginWithOffice: 'Login as BekaertDeslee Employee',
      measurement: 'Measurement unit',
      newPassword: 'New Password',
      newsletter: 'I want to receive the newsletter',
      noAccount: "Don't have an account yet?",
      number: 'No.',
      password: 'Password',
      phone: 'Telephone number',
      resetPassword: 'Reset Password',
      role: 'Role',
      roleAtCompany: 'Role at my company',
      signUp: 'Sign up',
      street: 'Street',
      termsAndConditions: 'I agree to the terms and conditions',
      welcome: 'Welcome to BD Cloud',
      zip: 'ZIP'
    },
    technologies: {
      technology: 'Technology',
      technologies: 'Technologies',
      title: 'Technologies'
    },
    cameraViewInput: {
      placeholder: 'Enter Camera View Name'
    },
    cameraViewList: {
      add: 'Add Custom Camera View',
      defaultButton: 'Default Camera View',
      defaultName: 'My Custom Camera View'
    },
    capsules: {
      title: 'Capsules'
    },
    collections: {
      collection: 'Collection',
      title: 'Collections',
      onlyBD: 'Only BD',
      noCollectionThemes: 'No Collection Themes.'
    },
    concepts: {
      confirmDelete: 'Are you sure you want to delete <b>{{name}}</b>?',
      new: 'New Inspiration',
      onlyBD: 'Only BD',
      title: 'Inspirations',
      sectionTitle: 'Inspiration',
      searchPlaceholder: 'Inspiration name'
    },
    configurations: {
      configuration: 'configuration',
      cover: 'Cover',
      customQuilt: 'Custom quilt',
      general: {
        color: 'color',
        diameter: 'diameter',
        fabric: 'Fabric',
        height: 'height',
        material: 'material',
        tape: 'Tape',
        pillowSample: 'Pillow sample'
      },
      customPropertyHeaders: {
        sample: 'Sample',
        color: 'Color'
      },
      customProperties: {
        width: 'Width',
        length: 'Length',
        thickness: 'Thickness',
        sample: 'Select sample',
        sampleXAlign: 'X-offset',
        sampleYAlign: 'Y-offset',
        sampleRotation: 'Rotation',
        sampleColor: 'Color'
      },
      importJson: {
        errorMessage: 'Your JSON appears to be invalid.',
        instructions: "Please use the field below to copy or paste your configuration JSON data. You can edit or create your new configuration JSON data at <a href=\"https://bd3d-buh4hzc4hedrffbz.z01.azurefd.net\" class=\"link link--blue\" target=\"_blank\">https://bd3d-buh4hzc4hedrffbz.z01.azurefd.net</a>.",
        textareaPlaceholder: 'Paste your configuration JSON here...'
      },
      instructions: {
        selectComponent: 'Please select a mattress component on the left to apply samples',
        selectModel: 'Please start by selecting a model in the menu on the right'
      },
      menu: {
        dimensions: 'Dimensions',
        environment: 'Environment',
        generalEnvironment: 'General',
        fabrics: 'Fabrics &amp; Colors',
        importConfigJson: 'Configuration JSON data',
        accessories: 'Accessories',
        borders: 'Border Components',
        model: 'Model'
      },
      environment: {
        backgroundColor: 'Background Color',
        backgroundImage: 'Background Image'
      },
      borders: {
        loading: 'Retreiving components...',
        empty: 'No borders available for this component.',
        add: 'Add',
        types: {
          piping: 'Piping',
          tape: 'Tape',
          zipper: 'Zipper',
          gusset: 'Gusset',
          fabric: 'Fabric',
          border3d: '3D border'
        }
      },
      title: 'Configurations',
      verifyDisclaimer: 'If you want to verify if your mattress creation is producible at BekaertDeslee Solutions, please contact <a class="link--blue" href="mailto:solutionsEU@bekaertdeslee.com">solutionsEU@bekaertdeslee.com</a> or <a class="link--blue" href="mailto:solutionsusa@bekaertdeslee.com">solutionsusa@bekaertdeslee.com</a>'
    },
    confirm: {
      addModelWithoutNameToEntity: 'Are you sure you want to add <b>{{model}}</b> to {{entity}}?',
      delete: 'Are you sure you want to delete <b>{{name}}</b>?',
      deleteModelWithNameFromEntity: 'Are you sure you want to delete {{model}} <b>{{name}}</b> from this {{entity}}?',
      deleteModelWithoutNameFromEntity: 'Are you sure you want to delete {{model}} from this {{entity}}?',
      deleteWithEntity: 'Are you sure you want to delete {{entity}} <b>{{name}}</b>?',
      leaveConfigurator: 'Are you sure you want to leave the configurator? All possible changes will not be saved.',
      saveAndOverwriteName: 'Are you sure you want to save and overwrite <b>{{name}}</b>? This action can not be undone.'
    },
    cookieStatement: {
      iUnderstand: 'I understand',
      link: 'Learn more in our Cookie Statement.',
      optOut: 'Opt out',
      text: 'This website uses cookies to ensure you get the best experience on our website. If you continue browsing this site, you agree to the use of cookies.'
    },
    create: {
      coverSolutions: {
        text: 'Apply samples, quilts, ...<br>to an existing mattress template',
        title: 'Cover Solutions'
      },
      playground: {
        text: 'Adapt a standard mattress<br>to your wishes',
        title: 'Playground Mattress'
      },
      pillows: {
        text: 'Adapt a standard pillow<br>to your wishes',
        title: 'Playground Pillow'
      },
      sample: {
        title: 'Create sample',
        text: 'Create a sample in the<br>showroom'
      },
      subtitle: 'What would you like to create?',
      template: {
        text: 'Assemble a mattress<br>cover from scratch',
        title: 'Template'
      },
      title: 'Create'
    },
    customization: {
      title: 'Complete Customization &amp; Co-creation',
      text: 'The BD Cloud is also a co-creation platform, where you can easily collaborate with our Sales and Designers. The integration of different design and manufacturing steps into one application in the cloud helps you to streamline the design process and save both time and money.<br/></br>The 3D configurator in the BD Cloud allows you to configure any bed concept, save the configurations and refine them afterwards - just in a few simple clicks. The greatest advantage of this tool is that it is available in the cloud and can be reached from anywhere in the world.'
    },
    details: {
      bottomPanel: 'Bottom panel',
      color: 'Color',
      handles: 'Handles',
      legs: 'Legs',
      piping: 'Piping',
      quiltIntensity: 'Intensity',
      quilts: 'Quilts',
      ribbon: 'Ribbon',
      ribbonColor: 'Ribbon color:',
      ribbonMaterial: 'Ribbon material:',
      samples: 'Showroom',
      topPanel: 'Top panel',
      zipper: 'Zipper',
      handleType: 'Handle type:',
      maxHandles: {
        title: 'Maximum count',
        headFoot: 'Head & foot',
        side: 'Side'
      },
      handleLogo: {
        title: 'Logo'
      },
      singleHandleSelectSingle: 'Select a single (translation)',
      singleHandleSelectType: 'Choose a handle type (translation)'
    },
    documents: {
      downloads: 'Document downloads',
      title: 'Documents'
    },
    draftSamples: {
      disclaimer: {
        format: 'Format: JPG image',
        resolution: 'Resolution: 96 dpi <span>(e.g.: a 1000 pixel image will be 26.45 cm on the mattress)</span>',
        repeat: 'Your image should correspond to the design repeat'
      },
      title: 'My Designs'
    },
    elearning: {
      new: 'New E-learnings'
    },
    filter: {
      keyword: 'Keyword'
    },
    footer: {
      termsAndConditions: 'Terms & Conditions',
      privacyPolicy: 'Privacy Policy',
      disclaimer: 'Disclaimer',
      cookieStatement: 'Cookie Statement'
    },
    forgotPassword: {
      back: 'Back to login page',
      confirmMessage: 'An email has been sent to you with further instructions.',
      label: "Enter your email address and we'll send you a link with instructions",
      reset: 'Reset your password'
    },
    forms: {
      placeholders: {
        addEntityName: 'Add {{entity}} name',
        nameYourEntity: 'Name your {{entity}}',
        nameYourNewEntity: 'Name your new {{entity}}',
        selectEntity: 'Select {{entity}}'
      }
    },
    general: {
      all: 'All',
      back: 'Back',
      copyrightNotice: '&copy; {{date}} BekaertDeslee',
      countries: 'Market',
      csv: 'CSV',
      draft: 'Draft',
      excel: 'Excel worksheet',
      fairPdf: 'Fair PDF',
      generatingShareUrl: 'Generatic share url',
      hastag: '#',
      image: 'image',
      jpeg: 'JPEG',
      manage: 'Manage',
      message: 'message',
      new: 'New',
      notAvailable: 'N/A',
      noSearchResults: 'Sorry, no results seem to match your search.',
      or: 'or',
      people: 'people',
      pdf: 'PDF',
      pdfHiRes: 'high resolution PDF',
      pdfLowRes: 'low resolution PDF',
      png: 'PNG',
      recent: 'Recent',
      top: 'Top',
      type: 'Type',
      uploadedByYou: 'Uploaded by you',
      providedByDB: 'Provided by BD',
      unavailable: 'Unavailable',
      url: 'URL'
    },
    gridFilter: {
      projects: 'Projects',
      brands: 'Technologies',
      collections: 'Collection',
      regions: 'Regions',
      technology: 'Type',
      styles: 'Style',
      themes: 'Theme',
      density: {
        label: 'Density',
        warp: 'Warp',
        weft: 'Weft'
      },
      ends: 'Ends',
      picks: 'Picks',
      width: 'Width',
      finishing: 'Finishing',
      weight: 'Weight',
      tags: 'Tags',
      color: 'Color'
    },
    header: {
      back: 'Back to bekaertdeslee.com',
      description: '<b>Access our virtual showroom</b> and create your own mattress concepts here.',
      logIn: 'Log in',
      signUp: 'Sign up',
      signUpForFree: 'Sign up for free'
    },
    modelNames: {
      capsules: 'capsules',
      concept: 'inspiration',
      concepts: 'inspirations',
      collection: 'collection',
      configuration: 'configuration',
      coverSolution: 'cover solution',
      coverSolutionCategory: 'cover solution category',
      note: 'note',
      photo: 'image',
      pillowTemplate: 'pillow template',
      playground: 'playground',
      project: 'project',
      projects: 'projects',
      quilt: 'quilt',
      region: 'region',
      sample: 'sample',
      scoops: 'scoops',
      sketchbook: 'sketchbook',
      thisNote: 'this note',
      thisPhoto: 'this image',
      'collection-themes': 'collections' // This should remain dasherized as this is based on the record's model name.
    },

    news: {
      publishedOn: 'Published on {{date}}',
      recent: 'Recent News',
      title: 'News'
    },
    notes: {
      createPlaceholder: 'Note',
      edit: 'Edit note',
      loading: 'Loading note...',
      note: 'Note',
      title: 'Notes'
    },
    notFound: {
      action: 'Home',
      description: 'Oops, this page cannot be found. You can go back to <b>"Home"</b> or double check the link.',
      title: 'Page Not Found'
    },
    register: {
      confirmed: 'Registration confirmed'
    },
    resetPassword: {
      confirmMessage: 'Your password has successfully been reset.'
    },
    pages: {
      help: {
        description: 'On this page you will find informative documents as well as videos that guide you through the different parts of the BD Cloud.',
        documents: {
          pages: 'pages',
          title: 'Documents'
        },
        title: 'Help',
        tutorials: {
          title: 'Tutorials'
        }
      }
    },
    products: {
      new: 'New Products',
      title: 'Showroom'
    },
    projects: {
      actions: {
        archive: {
          one: 'Archive project',
          other: 'Archive projects'
        },
        delete: {
          one: 'Delete project',
          other: 'Delete projects'
        },
        unarchive: {
          one: 'Unarchive project',
          other: 'Unarchive projects'
        },
        unshare: {
          one: 'Unshare project',
          other: 'Unshare projects'
        }
      },
      add: {
        configurations: 'Create a new configuration to add to your project',
        documents: 'Add presentations, pdf, doc and other files to your project',
        draftSamples: 'Upload your own design drafts into a project',
        notes: 'Add notes to your project',
        quilts: 'Upload your own quilt patterns into a project',
        samples: 'Browse samples to add them to your project',
        sketchbook: 'Add inspirational images to your project',
        title: 'What would you like to add?'
      },
      archive: {
        archived: 'Archived',
        archiveWarning: 'Are you sure you want to archive project <b>{{name}}</b>? The project will only be visible under ‘Archive’ on the projects page',
        archivedSuccess: 'Project <b>{{name}}</b> has been archived',
        title: 'Archive',
        unarchiveWarning: 'Are you sure you want to unarchive project <b>{{name}}</b>? The project will be visible under ‘My Projects‘ on the projects page',
        unarchivedSuccess: 'Project <b>{{name}}</b> has been unarchived'
      },
      create: {
        form: {
          description: 'Description',
          name: 'Add a project name',
          submit: 'Create Project'
        },
        subtitle: 'Name your new project',
        title: 'Create New Project'
      },
      confirm: {
        archive: 'Are you sure you want to archive these project(s)?',
        delete: 'Are you sure you want to delete these project(s)?',
        unarchive: 'Are you sure you want to unarchive these project(s)?',
        unshare: 'Are you sure you want to unshare these project(s)?'
      },
      confirmDelete: 'Are you sure you want to delete <b>{{name}}</b>? <br>If you delete this project, then it will be deleted in the accounts of all your contacts with whom you shared this project with.',
      createdBy: 'Created by {{{user}}} on {{{date}}}',
      description: 'Description',
      download: 'Download configurations as PDF',
      duplicate: {
        success: 'Project <b>{{name}}</b> has been duplicated',
        warning: 'Are you sure you want to duplicate project <b>{{name}}</b>?'
      },
      edit: 'Edit project',
      empty: {
        cloud: {
          new: 'Create your<br>first project',
          empty: 'Add content<br>to your project'
        },
        create: {
          action: 'Learn how',
          text: 'You can save anything you want inside a project: samples, configurations, images, ...',
          title: 'Create'
        },
        share: {
          action: 'Soon',
          text: 'Share your projects with colleagues or clients to collaborate and create together.',
          title: 'Share'
        }
      },
      existing: 'Existing Project',
      export: {
        concept: 'Add to Inspirations',
        conceptConfirm: 'Are you sure you want to add <b>{{{name}}}</b> to your inspirations?'
      },
      internal: 'Internal use only',
      mine: {
        new: 'My Recent Projects',
        title: 'My Projects'
      },
      new: 'New Project',
      private: 'Private',
      project: 'Project',
      recent: 'Recent projects',
      searchPlaceholder: 'Project name',
      selected: {
        one: '{{count}} project selected',
        other: '{{count}} projects selected'
      },
      setThumb: 'Set as Project Thumb',
      shared: {
        title: 'Shared with Me'
      },
      tags: {
        search: 'Add project tags'
      },
      thumbUpdated: "The project's thumb has been updated.",
      title: 'Projects',
      visibility: 'Visibility'
    },
    quilts: {
      disclaimer: '<ul><li>Your image should correspond to the design repeat</li><li>Format: JPEG image</li><li>Resolution: 96 dpi (e.g.: a 1000 pixel wide image will be 26.45 cm on the mattress)</li><li>Files uploaded must be 20 MB or smaller</li></ul>',
      searchPlaceholder: 'Search quilts',
      title: 'Quilt Patterns'
    },
    samples: {
      assetNames: {
        repeatFourLayers: 'Sim4',
        repeatSingleLayer: 'Sim1',
        repeatSim4: '4 layer',
        sampleImage: 'Thumb',
        simulationOverlay: 'Overlay',
        simulationPhotograph: 'Photo'
      },
      create: {
        action: 'Create Sample',
        description: 'Please enter the material number.',
        error: 'The id has already been taken.',
        title: 'Create Sample'
      },
      colorList: 'Color List',
      colors: 'Color Refs',
      composition: 'Composition',
      design: 'Design',
      designClass: 'Design Class',
      designNumber: 'Design Number',
      ends: 'Ends',
      exportFileName: 'Showroom Export {{date}}.{{ext}}',
      filter: {
        search: 'Quality Name or Design Number'
      },
      finishing: 'Finishing',
      loadingSampleData: 'Loading sample data...',
      mainColors: 'Main Colors',
      materialNumber: 'Material Number',
      method: 'Type',
      new: 'New Sample',
      picks: 'Picks',
      quality: 'Quality',
      qualityName: 'Quality Name',
      qualityNumber: 'Quality Number',
      searchPlaceholder: 'Material, Quality or Design',
      selected: {
        one: '{{count}} sample selected',
        other: '{{count}} samples selected'
      },
      title: 'Showroom',
      warpColor: 'Warp Color',
      weftColor: 'Weft Color',
      weight: 'Weight',
      width: 'Width',
      wizard: {
        chooseAsset: 'Choose Your Asset',
        finish: {
          action: 'Finish Sample',
          createAnother: 'Create Another Sample',
          description: 'Your sample with material number <b>{{id}}</b> has been created.',
          goBack: 'Go Back and Edit',
          title: 'Finish'
        },
        masterData: {
          additionalInformation: 'Additional Information',
          countries: 'Markets',
          keyInformation: 'Key Information',
          selectBrands: 'Select Technologies or Leave Blank',
          selectCollection: 'Select a Collection or Leave Blank',
          selectCountries: 'Select Markets or Leave Blank',
          selectStyle: 'Select a Style or Leave Blank',
          selectTheme: 'Select a Theme or Leave Blank',
          title: 'Master Data'
        },
        noAssets: 'No assets available.',
        physicalDimensions: 'Physical Dimensions',
        reportSize: 'Repeat Size',
        textureSlots: {
          alignX: 'Align X',
          alignY: 'Align Y',
          borderFabric: 'Border Fabric',
          double: 'Double',
          king: 'King',
          panels: 'Panels',
          queen: 'Queen',
          single: 'Single',
          title: 'Texture Slots'
        },
        thumbnail: {
          adjustments: 'Adjustments',
          brightness: 'Brightness',
          contrast: 'Contrast',
          title: 'Thumbnail'
        }
      }
    },
    scoops: {
      title: "Scoops"
    },
    settings: {
      configurator: {
        title: 'Configurator'
      },
      environment: {
        description: 'Here you can upload and manage your 2D environment images that you can use in the configurator.'
      },
      account: {
        deleteAccount: 'Delete Account',
        deleteConfirm: 'I understand that I will not be able to recover my content.',
        deleteWarning: 'All of your projects and configurations will be deleted.<br><strong>THIS CANNOT BE UNDONE.</strong>',
        employeeUpdatePasswordNotice: 'To update your password, please sign in to Office 365 and go to your general account settings.',
        title: 'Account',
        updatePassword: 'Update Password'
      },
      profile: {
        action: 'Save Profile',
        companyDetails: 'Company Details',
        personalDetails: 'Personal Details',
        title: 'Profile'
      },
      emails: {
        action: 'Save email preferences',
        newsletter: 'Newsletter',
        notificationPeriod: {
          title: 'Email notifications',
          daily: 'I want to receive daily email notifications',
          weekly: 'I want to receive weekly email notifications',
          none: "I don't want to receive email notifications"
        },
        title: 'Email preferences'
      },
      title: 'Settings'
    },
    share: {
      createOwn: 'Do you want to create your own?',
      entityAlreadySharedWith: 'You already shared this {{entity}} with:',
      enterEmailAddresses: 'Enter email addresses',
      expirationNotice: 'This public link will be valid for one year.',
      instructions: {
        selectComponent: 'Select a mattress component to see its details.'
      },
      noModificationsToEntity: 'People that you share your {{entity}} with cannot make any modifications but will be able to see any change you make to the {{entity}}',
      selectUsersToShareWith: 'Please select one or more email addresses to which you would like to send this {{entity}}.',
      settings: 'Share project',
      shareByEmail: 'Share by email',
      sharedWithOthers: 'Shared',
      unshare: 'Are you sure you want to unshare yourself from this project?'
    },
    shareSettings: {
      accessPlaceholder: 'This project does not have any members.',
      accessTitle: 'Who has access?',
      canAddNote: 'can add note',
      canEdit: 'can edit',
      canView: 'can view',
      inviteAlert: "You've made changes that need to be saved.",
      inviteTitle: 'Invite people',
      loadingMembers: 'Loading members...',
      messagePlaceholder: 'Include a personal message...',
      title: 'Share settings'
    },
    showroom: {
      title: 'Virtual Showroom and Concept Library',
      text: 'The online BD Cloud is your selection platform for BekaertDeslee fabric designs and bed concepts. The tool reveals a virtual showroom with all BekaertDeslee fabric designs, a concept library that inspires with trendy mattress concepts, an overview of all our brands and innovations, and so much more.'
    },
    sketchbook: {
      title: 'Sketchbook'
    },
    styles: {
      style: 'Style',
      title: 'Styles'
    },
    themes: {
      theme: 'Theme',
      title: 'Themes'
    },
    upload: {
      caption: 'Caption',
      files: 'Drag your files here or browse.',
      images: 'Drag your images here or browse.',
      sizeInfo: 'Files uploaded must be 20 MB or smaller',
      modalTitle: 'Upload files',
      thumb: 'Upload thumb',
      fileTooLarge: 'This file exceeds the 20 mb file size limit.'
    },
    components: {
      customColorPicker: {
        addButton: 'Add color',
        cancelButton: 'Cancel',
        discardEditButton: 'Discard',
        editButton: 'Edit colors',
        noColors: 'No custom colors available',
        saveButton: 'Save color'
      }
    }
  };
  _exports.default = _default;
});