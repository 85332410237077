define("bd-hybrid-cloud/components/x-configurator-handle-editor-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  // Panel for adding/removing handles to/from a single
  var _default = Ember.Component.extend({
    /**
     * State
     */
    singlesAllowingHandles: null,
    allowedSingleHandles: null,
    selectedSingle: null,
    selectedHandleType: null,
    configLoaded: false,
    /**
     * Hooks
     */
    init: function init() {
      this._super.apply(this, arguments);
      var bd3d = this.get('bd3d');
      var config = this.get('bd3d.mattressConfig');
      var cfgLoaded = config && config.getData();
      if (bd3d) {
        this.onChangeConfig = Ember.run.bind(this, this.onChangeConfig);
        bd3d.on('configComplete', this.onChangeConfig);
      }
      this.set('configLoaded', cfgLoaded);
      this.reloadModel();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var bd3d = this.get('bd3d');
      if (bd3d) {
        bd3d.off('configComplete', this.onChangeConfig);
      }
      this.set('selectedSingle', null);
      this.set('allowedSingleHandles', null);
      this.set('singlesAllowingHandles', null);
    },
    /**
     * Methods
     */
    onChangeConfig: function onChangeConfig() {
      var config = this.get('bd3d.mattressConfig');
      var configLoaded = config && config.getData();
      this.set('configLoaded', configLoaded);
      this.reloadModel();
    },
    reloadModel: function reloadModel() {
      var _window = window,
        BD3D = _window.BD3D;
      var config = this.get('bd3d.mattressConfig');
      var singles = BD3D.mattressConfigUtils.getMattressSinglesAllowingHandles(config);
      var selectedSingle = singles ? singles[0] : null;
      this.set('selectedSingle', selectedSingle);
      this.set('singlesAllowingHandles', singles);
      this.updateAllowedHandles();
    },
    updateAllowedHandles: function updateAllowedHandles() {
      var config = this.get('bd3d.mattressConfig');
      var selectedSingle = this.get('currentSingle');
      var allowedSingleHandles = config && config.getAvailableHandles(selectedSingle);
      var handleType = config ? config.getHandleType(selectedSingle) : null;

      // Get the current handle by name
      var selectedHandleType = handleType ? allowedSingleHandles.find(function (handle) {
        return handle && handle.name === handleType;
      }) : null;
      if (!selectedHandleType) {
        selectedHandleType = allowedSingleHandles ? allowedSingleHandles[0] : null;
      }
      this.setProperties({
        allowedSingleHandles: allowedSingleHandles,
        selectedHandleType: selectedHandleType
      });
      return allowedSingleHandles;
    },
    selectHandles: function selectHandles() {
      var configurator = this.get('bd3d.mattressConfigurator');
      if (!configurator) {
        return;
      }
      var cfg = this.get('bd3d.mattressConfig');
      if (!cfg) {
        return;
      }
      var single = this.get('currentSingle');
      var handleData = cfg.getHandleData(single);
      configurator.setSelectedData(handleData);
    },
    /**
     * Computed
     */
    currentSingle: computed('selectedSingle', 'singlesAllowingHandles', function () {
      var singles = this.get('singlesAllowingHandles');
      var num = singles ? singles.length : 0;
      if (!num) {
        return null;
      }
      if (num === 1) {
        return singles[0];
      }
      return this.get('selectedSingle');
    }),
    hasMultipleSinglesAllowingHandles: computed('singlesAllowingHandles', function () {
      var singles = this.get('singlesAllowingHandles');
      var num = singles ? singles.length : 0;
      return num > 1;
    }),
    hasAnySinglesAllowingHandles: computed('singlesAllowingHandles', function () {
      var singles = this.get('singlesAllowingHandles');
      var num = singles ? singles.length : 0;
      return num > 0;
    }),
    canAddCurrentHandle: Ember.computed.notEmpty('selectedHandleType'),
    canEditHandles: Ember.computed.reads('configLoaded'),
    canRemoveHandle: computed('configLoaded', 'bd3d.mattressConfig', 'selectedHandleType', function () {
      if (!this.get('configLoaded')) {
        return false;
      }
      var cfg = this.get('bd3d.mattressConfig');
      return cfg && cfg.hasSingleWithHandles();
    }),
    /**
     * Actions
     */
    actions: {
      onChangeSelectedSingle: function onChangeSelectedSingle(single) {
        this.set('selectedSingle', single);
        this.set('selectedHandleType', null);
        this.updateAllowedHandles();
      },
      onChangeSingleHandleType: function onChangeSingleHandleType(type) {
        this.set('selectedHandleType', type);
      },
      onAddHandles: function onAddHandles() {
        var _this = this;
        var bd3d = this.get('bd3d');
        var config = bd3d && bd3d.get('mattressConfig');
        var single = this.get('currentSingle');
        if (!single || !config) {
          return;
        }
        var handleType = this.get('selectedHandleType');
        config.setHandleType(single, handleType.name);
        bd3d.one('configComplete', function () {
          _this.selectHandles();
        });
      },
      onRemoveHandles: function onRemoveHandles() {
        var config = this.get('bd3d.mattressConfig');
        if (!config) {
          return;
        }
        var selectedSingle = this.get('currentSingle');
        config.setHandleType(selectedSingle, null);
        this.set('selectedHandleType', null);
      }
    }
  });
  _exports.default = _default;
});