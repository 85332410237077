define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NKghjO8F",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"x-configurator-editor-panel\",null,[[\"componentType\",\"allowDimensionAdjustment\",\"discardSelection\",\"filterIsVisible\",\"filterQuiltsIsVisible\",\"model\",\"openSectionPanel\",\"paginationConfigQuiltPatterns\",\"paginationConfigSamples\",\"quiltPatternCategories\",\"quiltPatternCategoryId\",\"samples\",\"quilts\",\"sectionName\",\"sectionParams\",\"sort\",\"disableFilters\"],[[20,[\"component\"]],[20,[\"allowDimensionAdjustment\"]],\"discardSelection\",[20,[\"filterIsVisible\"]],[20,[\"filterQuiltsIsVisible\"]],[20,[\"selectedComponentData\"]],\"openSectionPanel\",[20,[\"model\",\"quiltPatterns\",\"meta\",\"pagination\"]],[20,[\"model\",\"samples\",\"meta\",\"pagination\"]],[20,[\"model\",\"quiltPatternCategories\"]],[20,[\"quiltPatternCategoryId\"]],[20,[\"filteredSamples\"]],[20,[\"filteredQuilts\"]],[20,[\"section\"]],[20,[\"sectionParams\"]],[20,[\"sort\"]],[20,[\"disableFilters\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/section.hbs"
    }
  });
  _exports.default = _default;
});