define("bd-hybrid-cloud/components/x-frame", ["exports", "bd-hybrid-cloud/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    _Ember$run = Ember.run,
    bind = _Ember$run.bind,
    next = _Ember$run.next;
  var _default = Component.extend({
    /**
     * Props
     */

    onNavigate: null,
    pathname: null,
    /**
     * State
     */

    attributeBindings: ['frameborder', 'height', 'src', 'width'],
    cachedPathname: null,
    frameborder: 0,
    height: '100%',
    src: null,
    tagName: 'iframe',
    width: '100%',
    /**
     * Hooks
     */
    didInsertElement: function didInsertElement() {
      this.messageHandler = bind(this, this.messageHandler);
      window.addEventListener('message', this.messageHandler);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      var pathname = this.get('pathname');
      var cachedPathname = this.get('cachedPathname');
      if (pathname === cachedPathname) {
        return;
      }
      var accessToken = this.get('session.data.authenticated.access_token');
      var newSrc = "".concat(_environment.default.api.host).concat(pathname, "?standalone=true&bearer=").concat(accessToken);
      var oldSrc = this.get('src');
      if (oldSrc) {
        this.set('src', '');
        next(function () {
          _this.set('src', newSrc);
        });
      } else {
        this.set('src', newSrc);
      }
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('message', this.messageHandler);
    },
    /**
     * Methods
     */
    loadHandler: function loadHandler(data) {
      this.set('cachedPathname', data.pathname);
      this.get('onNavigate')(data.pathname);
    },
    messageHandler: function messageHandler(event) {
      var message;
      try {
        message = JSON.parse(event.data);
      } catch (error) {
        return;
      }
      if (message.sender !== 'bd-core') {
        return;
      }
      if (this[message.type + 'Handler']) {
        this[message.type + 'Handler'](message.data);
      }
    },
    resizeHandler: function resizeHandler(data) {
      this.set('height', data.scrollHeight);
    }
  });
  _exports.default = _default;
});