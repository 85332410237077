define("bd-hybrid-cloud/templates/components/x-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KybNtzQL",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"align align--gutter-sm\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"align__cell align__cell--grow\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"note__content\"],[7],[0,\"\\n      \"],[1,[20,[\"model\",\"content\"]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"note__meta\"],[7],[0,\"\\n      \"],[1,[25,\"moment-format\",[[20,[\"model\",\"createdAt\"]]],null],false],[0,\" - \"],[1,[20,[\"model\",\"user\",\"fullName\"]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"canEdit\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"app.projects.project.note\",[20,[\"model\",\"id\"]]],[[\"class\"],[\"bd-button bd-button--icon-sm bd-button--grey\"]],{\"statements\":[[0,\"        \"],[6,\"span\"],[9,\"class\",\"icon-pencil\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--icon-sm bd-button--grey\"],[3,\"action\",[[19,0,[]],\"delete\"]],[7],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"icon-trash\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-note.hbs"
    }
  });
  _exports.default = _default;
});