define("bd-hybrid-cloud/models/course-category", ["exports", "ember-data", "bgr-ember-json-api/model", "ember-data-storefront/mixins/loadable-model"], function (_exports, _emberData, _model, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany;
  var _default = _model.default.extend(_loadableModel.default, {
    courses: hasMany('course', {
      async: false
    })
  });
  _exports.default = _default;
});