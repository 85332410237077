define("bd-hybrid-cloud/models/user", ["exports", "ember-data", "bgr-ember-json-api/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    hasMany = _emberData.default.hasMany;
  var _default = _model.default.extend({
    /**
     * Attributes
     */

    address: attr('string'),
    city: attr('string'),
    company: attr('string'),
    email: attr('string'),
    firstName: attr('string'),
    isEmployee: attr('boolean'),
    language: attr('string'),
    lastName: attr('string'),
    locale: attr('string'),
    mobile: attr('string'),
    newsletter: attr('boolean'),
    notificationPeriod: attr('string'),
    // 'daily' / 'weekly' / 'none'
    nr: attr('string'),
    registrationDate: attr('string'),
    role: attr('string'),
    salesContact: attr('string'),
    sha: attr('string'),
    tel: attr('string'),
    thumbUrl: attr('string'),
    units: attr('string'),
    zip: attr('string'),
    /**
     * Relationships
     */

    cameraViews: hasMany('cameraView'),
    country: belongsTo('country'),
    salesCountry: hasMany('country', {
      inverse: 'salesReps'
    }),
    salesRep: belongsTo('user', {
      inverse: null
    }),
    notes: hasMany('note'),
    /**
     * Computed
     */

    fullName: computed('firstName', 'lastName', function () {
      return "".concat(this.get('firstName'), " ").concat(this.get('lastName'));
    }),
    /**
     * Methods
     */
    saveSettings: function saveSettings() {
      return this.save({
        adapterOptions: {
          settings: true
        }
      });
    }
  });
  _exports.default = _default;
});