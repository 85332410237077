define("bd-hybrid-cloud/initializers/bugsnag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var BS = window.Bugsnag;
  var BD3DBuildInfo = window.BD3D.BD3DBuildInfo;
  function initialize() {
    if (BS) {
      BS.metaData = {
        BD3D: {
          buildDate: BD3DBuildInfo.getBuildDate(),
          version: BD3DBuildInfo.getVersion()
        }
      };
      Ember.onerror = function (error) {
        BS.notifyException(error);
      };
    }
  }
  var _default = {
    initialize: initialize,
    name: 'bugsnag'
  };
  _exports.default = _default;
});