define("bd-hybrid-cloud/authenticators/azure", ["exports", "bd-hybrid-cloud/config/environment", "bd-hybrid-cloud/authenticators/application"], function (_exports, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = Ember.$;
  var api = _environment.default.api;
  var _default = _application.default.extend({
    authenticate: function authenticate(token) {
      return $.get("".concat(api.host, "/").concat(api.azurePath, "/").concat(token, "/token"));
    }
  });
  _exports.default = _default;
});