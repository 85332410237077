define("bd-hybrid-cloud/components/modals/x-add-to-concept-modal", ["exports", "bgr-ember-modal/components/bgr-modal"], function (_exports, _bgrModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _bgrModal.default.extend({
    /**
     * State
     */

    isInternal: false,
    /**
     * Actions
     */

    actions: {
      onConfirmClick: function onConfirmClick() {
        this.resolve({
          isInternal: this.get('isInternal')
        });
      }
    }
  });
  _exports.default = _default;
});