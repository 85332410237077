define("bd-hybrid-cloud/utils/custom-3d-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertPropertyToObject = convertPropertyToObject;
  _exports.getCustomDimensionProperties = getCustomDimensionProperties;
  _exports.getCustomDimensionPropertyGroup = getCustomDimensionPropertyGroup;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  // Transform internal configuration translation keys
  // to the translation keys from locales/<lang>/translations.js
  var TRANSLATION_KEY_MAP = {
    // groups
    'propertygroup-sample': 'configurations.customPropertyHeaders.sample',
    'propertygroup-sample-color': 'configurations.customPropertyHeaders.color',
    // Single part pillow
    'property-pillow-sample': 'configurations.customProperties.sample',
    // Old keys, but still used in some configs
    'property-pillow-x-align': 'configurations.customProperties.sampleXAlign',
    'property-pillow-y-align': 'configurations.customProperties.sampleYAlign',
    'property-pillow-rotate': 'configurations.customProperties.sampleRotation',
    // Should use these keys in future configs
    'property-pillow-sample-x-align': 'configurations.customProperties.sampleXAlign',
    'property-pillow-sample-y-align': 'configurations.customProperties.sampleYAlign',
    'property-pillow-sample-rotation': 'configurations.customProperties.sampleRotation',
    'property-pillow-width': 'configurations.customProperties.width',
    'property-pillow-length': 'configurations.customProperties.length',
    'property-pillow-thickness': 'configurations.customProperties.thickness',
    // Multi-part pillow
    // - pillow top part
    'property-pillow-top-sample': 'configurations.customProperties.sample',
    'property-pillow-top-x-align': 'configurations.customProperties.sampleXAlign',
    'property-pillow-top-y-align': 'configurations.customProperties.sampleYAlign',
    'property-pillow-top-rotation': 'configurations.customProperties.sampleRotation',
    // - pillow middle piping
    'property-pillow-piping-sample': 'configurations.customProperties.sample',
    'property-pillow-piping-sample-color': 'configurations.customProperties.sampleColor',
    // - pillow bottom part
    'property-pillow-bottom-sample': 'configurations.customProperties.sample',
    'property-pillow-bottom-x-align': 'configurations.customProperties.sampleXAlign',
    'property-pillow-bottom-y-align': 'configurations.customProperties.sampleYAlign',
    'property-pillow-bottom-rotation': 'configurations.customProperties.sampleRotation'
  };
  var colorsByType = {};
  function convertToColorObject(color) {
    // Just a copy for now, maybe add some other properties later
    return _objectSpread({}, color);
  }
  function getColorsByType(type, bd3d) {
    if (!type) {
      return null;
    }
    var result = colorsByType[type];
    if (result) {
      return result;
    }
    // Get & create the list of colors and store it in the dictionary (colorsByType)
    var array = bd3d.getColorsByType(type);
    result = array ? array.map(convertToColorObject) : null;
    colorsByType[type] = result;
    return result;
  }

  /**
   * @method convertPropertyToObject
   * @description - Converts a property object from the 3D configurator
   * to a property object used while rendering the properties panel.
   * (Used only internally)
   * @param {object} property - Property object returned by the 3D configurator
   * @param {object} configurator - 3D configurator instance
   * @param {number} index - Index of the property, used to identify the property
   * @return {Object}
   * */
  function convertPropertyToObject(property, configurator, index, bd3d) {
    var propertyData = property && property.data;
    if (!propertyData) {
      return null;
    }
    var title = propertyData.title,
      type = propertyData.type,
      key = propertyData.key;
    var value = null;
    // Initial object
    var result = {
      property: property,
      titleTranslationKey: title ? TRANSLATION_KEY_MAP[title] : null,
      type: type,
      key: key,
      value: value,
      index: index
    };

    // Current value of the property
    if (type !== 'group') {
      value = configurator.getConfigPropertyValue(property);
      result.value = value;
    }

    // Add extra data to the objects based on its type
    switch (type) {
      case 'group':
        {
          var properties = propertyData.properties;
          var convertedProperties = properties.map(function (childProperty, childPropertyIndex) {
            return convertPropertyToObject(childProperty, configurator, childPropertyIndex, bd3d);
          });
          result.properties = convertedProperties;
          break;
        }
      case 'number':
        {
          result.min = propertyData.min;
          result.max = propertyData.max;
          result.step = propertyData.step || 0.01;
          break;
        }
      case 'rotate-actions':
      case 'actions':
        {
          var actions = propertyData.actions;
          if (actions) {
            var resActions = actions.map(function (action) {
              if (!action) {
                return null;
              }
              var resAction = {
                action: action
              };
              if (type === 'rotate-actions') {
                var className = null;
                if (action.key === 'increase') {
                  className = 'icon-rotate-left icon icon--blue';
                } else if (action.key === 'decrease') {
                  className = 'icon-rotate-right icon icon--blue';
                }
                if (className) {
                  resAction.className = className;
                }
              }
              return resAction;
            });
            result.actions = resActions;
          }
          break;
        }
      case 'color':
        {
          // 'colortype' should be one of the values returned by BD3D.Colors.getColorTypes()
          var colortype = propertyData.colortype,
            allowCustom = propertyData.allowCustom;
          var colors = getColorsByType(colortype, bd3d);
          result.colors = colors;

          // allowCustom: whether the custom color component should be rendered (true)
          // or just the predefined palette (false)
          result.allowCustom = allowCustom;
          var valueObject = null;
          if (colors) {
            // Find the color object by id or color
            // Example: input=0100 or #FF0000 or ... => output = { id: '0100', color: '#FF0000' }

            // result.value needs to be an object of the colors list
            // to show which color is selected in the colors list
            valueObject = colors.find(function (o) {
              return o.id === value || o.color === value;
            });
            result.value = valueObject;
          }

          // Find the hex value of the color. (Used as initial value for the custom color part)
          // Search in the found valueObject first, if that is null, use the current property value
          // If the color value does not start with a #, set it to a default color
          if (allowCustom) {
            // 'colorValue' is the color to be shown in the custom color component
            var colorValue = valueObject ? valueObject.color : null;
            if (!colorValue && value && value.charAt && value.charAt(0) === '#') {
              // 'value' is already a hex value, assign to colorValue
              colorValue = value;
            }
            if (!colorValue || !colorValue.charAt || colorValue.charAt(0) !== '#') {
              // No color value could be found, use default
              colorValue = '#000000';
            }
            result.colorValue = colorValue;
          }
          break;
        }
      default:
        break;
    }
    return result;
  }
  function isCustomObjectDimensionsProperty(property) {
    var data = property && property.data;
    var metadata = data && data.metadata;
    return !!(metadata && metadata.isDimension);
  }
  function getCustomPropertyType(property) {
    return property && (property.getType ? property.getType() : property.type);
  }
  function isCustomObjectPropertyGroup(property) {
    return getCustomPropertyType(property) === 'group';
  }

  /**
   * @function collectDimensionProperties
   * @description Collects all dimension properties of a property group recursively
   * @param {Object} property - property group
   * @param {Array} result - optional preallocated array
   * @return {Array}
   */
  function collectDimensionProperties(param) {
    var result = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    if (!param) {
      return result;
    }
    var res = result;
    if (isCustomObjectDimensionsProperty(param)) {
      // Property is a dimension property -> push to collection
      res = result || [];
      res.push(param);
      return res;
    }
    if (isCustomObjectPropertyGroup(param)) {
      var properties = param.getProperties ? param.getProperties() : param.properties || param.data && param.data.properties;
      properties.forEach(function (prop) {
        res = collectDimensionProperties(prop, res);
      });
    }
    if (Array.isArray(param)) {
      param.forEach(function (item) {
        res = collectDimensionProperties(item, res);
      });
    }
    return res;
  }

  /**
   * @function getGlobalCustomObjectProperties
   * @description Returns true if the parameter is
   * a property group that contains dimension properties
   * @param {BD3D.MattressConfig} mattressConfig
   * @return {Array}
   */
  function isCustomObjectDimensionsPropertyGroup(property) {
    return property && isCustomObjectPropertyGroup(property) && (isCustomObjectDimensionsProperty(property) || property.data && property.data.title === 'propertygroup-dimensions');
  }

  /**
   * @function getGlobalCustomObjectProperties
   * @description Returns the properties of objects other than mattresses (pillows, ...)
   * that are not linked to a selectable part
   * @param {BD3D.MattressConfig} mattressConfig
   * @return {Array}
   */
  function getGlobalCustomObjectProperties(mattressConfig) {
    var scene = mattressConfig && mattressConfig.getScene();
    return scene && scene.getGlobalProperties();
  }

  /**
   * @function getCustomDimensionPropertyGroup
   * @description Returns the property group of a mattress config that contains
   * the dimension properties of objects other than mattresses (pillows, scenery objects, ...)
   * @param {BD3D.MattressConfig} mattressConfig
   * @return {Array}
   */
  function getCustomDimensionPropertyGroup(mattressConfig) {
    var properties = getGlobalCustomObjectProperties(mattressConfig);
    return properties && properties.find(isCustomObjectDimensionsPropertyGroup);
  }

  /**
   * @function getCustomDimensionProperties
   * @description Returns the dimension properties of objects other than mattresses (pillows, scenery objects, ...)
   * @param {BD3D.MattressConfig} mattressConfig
   * @return {Array}
   */
  function getCustomDimensionProperties(mattressConfig) {
    // Search for properties that are flagged as dimension property (isDimension: true in meta data)
    var properties = collectDimensionProperties(getGlobalCustomObjectProperties(mattressConfig));
    if (properties) {
      return properties;
    }
    // Fallback to the property group that has 'propertygroup-dimensions' as title
    var dimensionPropertyGroup = getCustomDimensionPropertyGroup(mattressConfig);
    return dimensionPropertyGroup && dimensionPropertyGroup.getProperties();
  }
});