define("bd-hybrid-cloud/adapters/user", ["exports", "bd-hybrid-cloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assert = Ember.assert;
  var _default = _application.default.extend({
    deleteRecord: function deleteRecord(store, type, snapshot) {
      assert("The 'force' adapter option is required when destroying a user record.", snapshot.adapterOptions && snapshot.adapterOptions.force);
      return this._super.apply(this, arguments);
    },
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var urlForQueryRecord;
      if (query.active) {
        Reflect.deleteProperty(query, 'active');
        urlForQueryRecord = this.createEndpoint('user');
      } else {
        urlForQueryRecord = this._super(query, modelName);
      }
      return urlForQueryRecord;
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var urlForUpdateRecord;
      if (snapshot.adapterOptions && snapshot.adapterOptions.settings) {
        urlForUpdateRecord = this.createEndpoint("users/".concat(id, "/settings"));
      } else {
        urlForUpdateRecord = this._super(id, modelName, snapshot);
      }
      return urlForUpdateRecord;
    }
  });
  _exports.default = _default;
});