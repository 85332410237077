define("bd-hybrid-cloud/templates/app/configuration/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KuINI+f8",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"flex h-full items-center justify-center\"],[7],[0,\"\\n  \"],[1,[18,\"app-content-loader\"],false],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/configuration/loading.hbs"
    }
  });
  _exports.default = _default;
});