define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel-section/component/edit-zipper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "utPQSUmY",
    "block": "{\"symbols\":[\"zipperType\"],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-panel__section-title\"],[7],[0,\"\\n  \"],[1,[25,\"t\",[\"general.type\"],null],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"editor-panel__section\"],[7],[0,\"\\n\"],[4,\"x-select\",null,[[\"onchange\",\"options\",\"placeholder\",\"searchEnabled\",\"selected\"],[[25,\"action\",[[19,0,[]],\"onZipperTypeChange\"],null],[20,[\"zipperTypes\"]],\"Choose a zipper type\",false,[20,[\"selectedZipperType\"]]]],{\"statements\":[[0,\"    \"],[1,[19,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel-section/component/edit-zipper.hbs"
    }
  });
  _exports.default = _default;
});