define("bd-hybrid-cloud/controllers/welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = Ember.$,
    Controller = Ember.Controller,
    observer = Ember.observer;
  var _default = Controller.extend({
    /**
     * State
     */

    queryParams: ['register'],
    register: false,
    registerForm: null,
    /**
     * Actions
     */

    actions: {
      scrollToRegisterForm: function scrollToRegisterForm() {
        this.set('register', true);
        this.scrollToRegisterForm(500);
      }
    },
    /**
     * Observers
     */

    registerFormObserver: observer('registerForm', function () {
      if (this.get('register')) {
        this.scrollToRegisterForm(0);
      }
    }),
    /**
     * Methods
     */
    scrollToRegisterForm: function scrollToRegisterForm(duration) {
      $('html, body').animate({
        scrollTop: this.get('registerForm').$().offset().top
      }, duration);
    }
  });
  _exports.default = _default;
});