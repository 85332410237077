define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel-section/component/edit-sample", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9V275BVZ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-panel__section-title\"],[7],[0,\"\\n  \"],[1,[25,\"t\",[\"samples.design\"],null],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[10,\"class\",[26,[\"editor-panel__section editor-panel__section--collapse-top \",[25,\"if\",[[20,[\"isFabricLayer\"]],\"\",\"editor-panel__section--collapse-bottom\"],null]]]],[7],[0,\"\\n  \"],[1,[25,\"x-configurator-edit-sample\",null,[[\"allowOffsetEdit\",\"allowRotationEdit\",\"model\",\"onAlignChange\",\"onAlignSlide\",\"onRotate\",\"openSectionPanel\"],[[20,[\"allowOffsetEdit\"]],[20,[\"allowRotationEdit\"]],[20,[\"model\",\"sample\"]],\"onAlignChange\",\"onAlignSlide\",\"onRotate\",\"openSectionPanel\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel-section/component/edit-sample.hbs"
    }
  });
  _exports.default = _default;
});