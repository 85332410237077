define("bd-hybrid-cloud/components/modals/x-download-project-modal", ["exports", "bgr-ember-modal/components/bgr-modal", "bd-hybrid-cloud/config/environment"], function (_exports, _bgrModal, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _bgrModal.default.extend({
    /**
     * Methods
     */
    downloadExcel: function downloadExcel() {
      var identifier = this.get('model.identifier');
      window.open("".concat(_environment.default.api.url, "/projects/").concat(identifier, "/xlsx"), '_blank');
    },
    downloadPdf: function downloadPdf() {
      var identifier = this.get('model.identifier');
      window.open("".concat(_environment.default.api.url, "/projects/").concat(identifier, "/export"), '_blank');
    },
    /**
     * Actions
     */
    actions: {
      download: function download(type) {
        if (type === 'excel') {
          this.downloadExcel();
        } else if (type === 'pdf') {
          this.downloadPdf();
        }
      }
    }
  });
  _exports.default = _default;
});