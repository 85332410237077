define("bd-hybrid-cloud/components/multiple-selection-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Props
     */

    itemsSelectedTranslationKey: null,
    totalAvailableItems: null,
    totalSelectedItems: null,
    /**
     * State
     */

    classNameBindings: ['totalSelectedItems:is-visible'],
    classNames: ['multiple-selection-toolbar'],
    /**
     * Hooks
     */
    willDestroyElement: function willDestroyElement() {
      this.sendAction('onDestroyElement');
    },
    /**
     * Actions
     */

    actions: {
      clearSelectedItems: function clearSelectedItems() {
        this.sendAction('onClearSelectedItems');
      },
      selectAllItems: function selectAllItems() {
        this.sendAction('onSelectAllItems');
      }
    }
  });
  _exports.default = _default;
});