define("bd-hybrid-cloud/models/project", ["exports", "bgr-ember-json-api/model", "bd-hybrid-cloud/mixins/model/project", "ember-data-storefront/mixins/loadable-model"], function (_exports, _model, _project, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var computed = Ember.computed;
  var _default = _model.default.extend(_loadableModel.default, _project.default, {
    /**
     * Computed
     */

    draftSamples: computed.filterBy('samples', 'draft', 1),
    materialSamples: computed.filterBy('samples', 'draft', 0),
    /**
     * Methods
     */
    // TODO: Perform full rewrite of code below. Just do it.
    sendShareEmail: function sendShareEmail(recipients, message) {
      var _this = this;
      var emailAddresses = [];
      recipients.forEach(function (user) {
        emailAddresses.push(user.attributes.email);
        if (user.id) {
          var userModel = _this.get('store').push({
            data: _objectSpread({}, user)
          });
          _this.get('members').pushObject(userModel);
        }
      });
      return this.get('api').post("projects/".concat(this.get('id'), "/share"), {
        emailAddresses: emailAddresses,
        message: message
      });
    },
    unshare: function unshare(member) {
      this.get('members').removeObject(member);
      return this.get('api').post("projects/".concat(this.get('id'), "/").concat(member.id, "/unshare"));
    },
    archive: function archive() {
      var _this2 = this;
      return this.get('api').post("projects/".concat(this.get('id'), "/archive")).then(function () {
        _this2.set('archived', true);
      });
    },
    unarchive: function unarchive() {
      var _this3 = this;
      return this.get('api').post("projects/".concat(this.get('id'), "/unarchive")).then(function () {
        _this3.set('archived', false);
      });
    },
    duplicate: function duplicate() {
      return this.get('api').post("projects/".concat(this.get('id'), "/duplicate"));
    }
  });
  _exports.default = _default;
});