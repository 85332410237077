define("bd-hybrid-cloud/templates/components/modals/x-confirm-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VlBp6j7o",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bgr-modal-template\",null,null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"modal__row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"modal__description\"],[7],[1,[20,[\"options\",\"message\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"options\",\"disclaimer\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n        \"],[6,\"p\"],[9,\"class\",\"modal__disclaimer\"],[7],[0,\"\\n          \"],[1,[20,[\"options\",\"disclaimer\"]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal__row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align align--centered align--gutter-sm\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--regular bd-button--stroked-blue\"],[3,\"action\",[[19,0,[]],\"hide\"]],[7],[1,[25,\"t\",[\"actions.cancel\"],null],false],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--regular bd-button--solid-blue\"],[3,\"action\",[[19,0,[]],\"resolve\"]],[7],[1,[25,\"t\",[\"actions.confirm\"],null],false],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/modals/x-confirm-modal.hbs"
    }
  });
  _exports.default = _default;
});