define("bd-hybrid-cloud/templates/components/x-spectrum-color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "T2hMmhHX",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"align__cell\"],[10,\"id\",[26,[[18,\"id\"]]]],[7],[0,\"\\n  \"],[1,[25,\"spectrum-color-picker\",null,[[\"appendTo\",\"color\",\"onChange\",\"preferredFormat\",\"showButtons\"],[[25,\"concat\",[\"#\",[20,[\"id\"]]],null],[20,[\"internalColor\"]],[25,\"action\",[[19,0,[]],\"onChange\"],null],\"hex\",false]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"align__cell align__cell--grow\"],[7],[0,\"\\n  \"],[1,[25,\"input\",null,[[\"class\",\"enter\",\"escape-press\",\"focus-out\",\"placeholder\",\"value\"],[\"bd-input bd-input--sm bd-input--uppercase\",[25,\"action\",[[19,0,[]],\"onChange\"],null],[25,\"action\",[[19,0,[]],\"onCancel\"],null],[25,\"action\",[[19,0,[]],\"onChange\"],null],\"#000000\",[20,[\"internalColor\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-spectrum-color-picker.hbs"
    }
  });
  _exports.default = _default;
});