define("bd-hybrid-cloud/helpers/encode-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.encodeUrl = encodeUrl;
  function encodeUrl(params) {
    return encodeURIComponent(params[0]);
  }
  var _default = Ember.Helper.helper(encodeUrl);
  _exports.default = _default;
});