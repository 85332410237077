define("bd-hybrid-cloud/routes/register-confirmed", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    /**
     * Hooks
     */
    model: function model(params) {
      var _this = this;
      return this.get('api').post('users/confirm', params).catch(function () {
        _this.transitionTo('welcome');
      });
    }
  });
  _exports.default = _default;
});