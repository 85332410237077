define("bd-hybrid-cloud/components/x-configurator-edit-quilt", ["exports", "bd-hybrid-cloud/mixins/component/configuration-quilt-detail"], function (_exports, _configurationQuiltDetail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    on = Ember.on;
  var QUILT_INTENSITY_MAP = {
    border: 'getBorderQuiltFoamValue',
    bottom: 'getBottomQuiltFoamValue',
    top: 'getTopQuiltFoamValue'
  };
  var _default = Component.extend(_configurationQuiltDetail.default, {
    /**
     * Props
     */

    intensity: 0,
    /**
     * Events
     */

    initHandler: on('init', function () {
      this.getQuiltIntensity();
    }),
    /**
     * Methods
     */
    getQuiltIntensity: function getQuiltIntensity() {
      var intensity;
      var mattressConfig = this.get('bd3d.mattressConfig');
      var mattressConfigurator = this.get('bd3d.mattressConfigurator');
      if (mattressConfig && mattressConfigurator) {
        var data = mattressConfigurator.getSelectedData();
        var info = mattressConfig.getObjectInfo(data);
        if (data && info) {
          var componentType = info.type;
          var selectedSingle = info.single;
          if (componentType === 'top' || componentType === 'bottom') {
            intensity = mattressConfig[QUILT_INTENSITY_MAP[componentType]](selectedSingle);
          } else {
            intensity = mattressConfig[QUILT_INTENSITY_MAP.border](selectedSingle, data);
          }
          this.setProperties({
            intensity: intensity || 0
          });
        }
      }
    },
    /**
     * Actions
     */

    actions: {
      onIntensityChange: function onIntensityChange() {
        this.sendAction('onIntensityChange');
      },
      onIntensitySlide: function onIntensitySlide(value) {
        this.set('intensity', value);
        this.sendAction('onIntensitySlide', value);
      },
      openSectionPanel: function openSectionPanel(section) {
        this.sendAction('openSectionPanel', section);
      }
    }
  });
  _exports.default = _default;
});