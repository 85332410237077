define("bd-hybrid-cloud/components/modals/x-copy-url-modal", ["exports", "bgr-ember-modal/components/bgr-modal"], function (_exports, _bgrModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _bgrModal.default.extend({
    /**
     * State
     */

    generatingShareUrl: true,
    shareUrl: '',
    /**
     * Actions
     */

    actions: {
      onCopySuccess: function onCopySuccess() {
        this.get('flashMessages').success(this.get('trans').t('alert.success.copiedToClipboard'));
      },
      openUrl: function openUrl() {
        window.open(this.get('shareUrl'), '_blank');
      }
    },
    /**
     * Hooks
     */
    afterShow: function afterShow() {
      var _this = this;
      this.get('model').generateShareUrl().then(function (response) {
        _this.set('generatingShareUrl', false);
        _this.set('shareUrl', response.data.attributes.url);
      });
    }
  });
  _exports.default = _default;
});