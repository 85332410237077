define("bd-hybrid-cloud/components/forms/x-update-password-form", ["exports", "bd-hybrid-cloud/components/x-form"], function (_exports, _xForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _xForm.default.extend({
    /**
     * State
     */

    oldPassword: '',
    password: '',
    passwordConfirmation: '',
    /**
     * Hooks
     */
    request: function request() {
      var _this = this;
      return this.get('api').post('user/update-password', this.getProperties('oldPassword', 'password', 'passwordConfirmation')).then(function () {
        _this.get('flashMessages').success(_this.get('trans').t('alert.success.passwordChanged'));
      });
    }
  });
  _exports.default = _default;
});