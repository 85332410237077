define("bd-hybrid-cloud/templates/components/x-alert-queue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "muG7XjXf",
    "block": "{\"symbols\":[\"flash\",\"component\",\"flash\"],\"statements\":[[4,\"each\",[[20,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[4,\"flash-message\",null,[[\"flash\"],[[19,1,[]]]],{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"align align--stretched align--gutter-sm\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n        \"],[6,\"p\"],[7],[1,[19,3,[\"message\"]],true],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"alert__icon\"],[7],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"icon-close-small\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-alert-queue.hbs"
    }
  });
  _exports.default = _default;
});