define("bd-hybrid-cloud/utils/file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.downloadFile = downloadFile;
  _exports.getFileExtension = getFileExtension;
  _exports.getFileName = getFileName;
  function downloadFile(objectURL) {
    var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'bekaert-deslee-document.pdf';
    window.download(objectURL, fileName);
  }
  function getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }
  function getFileName(fileName) {
    return fileName.substring(0, fileName.lastIndexOf('.'));
  }
});