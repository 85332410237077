define("bd-hybrid-cloud/components/x-bd3d-canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed,
    isEqual = Ember.isEqual,
    isPresent = Ember.isPresent,
    on = Ember.on,
    next = Ember.run.next;
  var ZOOM_INTENSITY = 5;
  var _default = Component.extend({
    /**
     * Props
     */

    cache: false,
    onChangeConfig: null,
    configComplete: null,
    configuration: null,
    hasControls: true,
    hasUndoRedoControls: true,
    isLoading: false,
    isSelectable: null,
    /**
     * State
     */

    classNames: ['bd3d-canvas'],
    /**
     * Computed
     */

    redoIsAvailable: computed.alias('bd3d.redoIsAvailable'),
    undoIsAvailable: computed.alias('bd3d.undoIsAvailable'),
    /**
     * Events
     */
    didUpdateAttrs: function didUpdateAttrs() {
      var bd3d = this.get('bd3d');
      var configuration = this.get('configuration');
      var activeConfiguration = bd3d.get('configuration');
      if (configuration && !isEqual(configuration, activeConfiguration)) {
        bd3d.setConfiguration(configuration);
      }
    },
    didInsertElementHandler: on('didInsertElement', function () {
      var _this = this;
      var bd3d = this.get('bd3d');
      var cache = this.get('cache');
      var element = this.get('element');
      var isSelectable = this.get('isSelectable');
      var configuration = this.get('configuration');
      var activeConfiguration = bd3d.get('configuration');
      var canvasIsSelectable = isPresent(isSelectable) ? isSelectable : bd3d.get('isSelectable');
      next(function () {
        var bd3dCanvasContainerElement = element.children['bd3d-canvas-container'];
        bd3d.initialize(bd3dCanvasContainerElement);
        bd3d.get('mattressConfigurator.clickSelectionController').setEnabled(canvasIsSelectable);
        bd3d.on('loadingState', function (e) {
          _this.set('isLoading', e.loading);
        });
        if (!activeConfiguration || !cache || !isEqual(configuration, activeConfiguration)) {
          bd3d.one('configComplete', function () {
            next(function () {
              return _this.sendAction('configComplete');
            });
          });
          bd3d.on('changeConfig', function () {
            next(function () {
              return _this.sendAction('onChangeConfig');
            });
          });
          bd3d.setConfiguration(configuration);
        }
      });
    }),
    willDestroyElementHandler: on('willDestroyElement', function () {
      var bd3d = this.get('bd3d');
      bd3d.off('loadingState');
      bd3d.off('changeConfig');
      bd3d.off('configComplete');
      bd3d.off('registerHistoryState');
    }),
    /**
     * Actions
     */

    actions: {
      changeZoom: function changeZoom(action) {
        this.get('bd3d.mattressConfigurator')[action](ZOOM_INTENSITY);
      },
      getView: function getView() {
        return this.get('bd3d.mattressConfigurator').getCameraViewData();
      },
      redo: function redo() {
        this.get('bd3d').redo();
      },
      resetView: function resetView() {
        this.get('bd3d.mattressConfigurator').resetView();
      },
      setView: function setView(settings) {
        return this.get('bd3d.mattressConfigurator').setCameraView(settings);
      },
      undo: function undo() {
        this.get('bd3d').undo();
      }
    }
  });
  _exports.default = _default;
});