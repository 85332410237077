define("bd-hybrid-cloud/components/modals/x-crop-modal", ["exports", "bgr-ember-modal/components/bgr-modal"], function (_exports, _bgrModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CLOCKWISE = 'c';
  var ROTATION_DEGREES = 90;
  var _default = _bgrModal.default.extend({
    /**
     * State
     */

    classNames: ['modal--lg'],
    cropData: null,
    cropper: null,
    /**
     * Actions
     */

    actions: {
      createCrop: function createCrop() {
        var _this = this;
        var model = this.get('model');
        var cropData = this.get('cropData');
        var rotate = cropData.rotate;
        var orientation = (rotate > 0 ? 360 - rotate : Math.abs(rotate)) || 'auto';
        var coordinates = {
          height: parseInt(cropData.height, 10),
          width: parseInt(cropData.width, 10),
          x: Math.max(parseInt(cropData.x, 10), 0),
          y: Math.max(parseInt(cropData.y, 10), 0)
        };
        model.set('crop', {
          coordinates: coordinates,
          orientation: orientation
        });
        model.save().then(function () {
          _this.hide();
        });
      },
      rotateCropper: function rotateCropper(direction) {
        this.get('cropper').rotate(direction === CLOCKWISE ? ROTATION_DEGREES : -ROTATION_DEGREES);
      }
    }
  });
  _exports.default = _default;
});