define("bd-hybrid-cloud/models/configuration-category", ["exports", "ember-data", "bgr-ember-json-api/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany;
  var _default = _model.default.extend({
    children: hasMany('configurationCategory', {
      inverse: null
    }),
    configurations: hasMany('configuration'),
    description: attr('string'),
    isParent: attr('boolean'),
    name: attr('string'),
    parentId: attr('number'),
    /**
     * Computed
     */

    shouldBeDisabled: Ember.computed('configurations.length', 'isParent', function () {
      return !this.get('configurations.length') && !this.get('isParent');
    })
  });
  _exports.default = _default;
});