define("bd-hybrid-cloud/controllers/app/projects/project/add/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    /**
     * Actions
     */

    actions: {
      addNote: function addNote(content) {
        var _this = this;
        var project = this.get('model');
        var note = this.get('store').createRecord('note', {
          content: content
        });
        note.set('project', project);
        note.save().then(function () {
          _this.get('router').transitionTo('app.projects.project');
        });
      }
    }
  });
  _exports.default = _default;
});