define("bd-hybrid-cloud/components/modals/x-activity-log-modal", ["exports", "bgr-ember-modal/components/bgr-modal"], function (_exports, _bgrModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _bgrModal.default.extend({
    /**
     * State
     */

    isLoading: true,
    logs: null,
    /**
     * Hooks
     */
    beforeShow: function beforeShow() {
      var _this = this;
      this.get('model').hasMany('logs').reload().then(function (logs) {
        _this.setProperties({
          isLoading: false,
          logs: logs.reverseObjects()
        });
      });
    }
  });
  _exports.default = _default;
});