define("bd-hybrid-cloud/models/news-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _default = _emberData.default.Model.extend({
    content: attr(),
    date: attr(),
    description: attr(),
    intro: attr(),
    picture: attr(),
    title: attr(),
    url: attr()
  });
  _exports.default = _default;
});