define("bd-hybrid-cloud/models/concept", ["exports", "ember-data", "moment", "bgr-ember-json-api/model", "bd-hybrid-cloud/mixins/model/project", "ember-data-storefront/mixins/loadable-model"], function (_exports, _emberData, _moment, _model, _project, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _default = _model.default.extend(_loadableModel.default, _project.default, {
    isInternal: attr('boolean'),
    pinned: attr('boolean'),
    /**
     * Computed
     */

    isNew: Ember.computed('createdAt', function () {
      var isNew = false;
      var createdAt = this.get('createdAt');
      if (createdAt) {
        isNew = (0, _moment.default)().diff(createdAt, 'days') <= 7;
      }
      return isNew;
    })
  });
  _exports.default = _default;
});