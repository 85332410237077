define("bd-hybrid-cloud/templates/components/embed-video-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "30aBxWRh",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"relative\"],[9,\"style\",\"padding-bottom: 56.25%;\"],[7],[0,\"\\n  \"],[6,\"iframe\"],[9,\"allow\",\"autoplay; fullscreen\"],[9,\"class\",\"absolute h-full pin-l pin-t w-full\"],[9,\"frameborder\",\"0\"],[9,\"height\",\"360\"],[10,\"src\",[26,[[19,0,[\"videoUrl\"]]]]],[9,\"width\",\"640\"],[9,\"allowfullscreen\",\"\"],[7],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/embed-video-player.hbs"
    }
  });
  _exports.default = _default;
});