define("bd-hybrid-cloud/components/x-filter-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed,
    isBlank = Ember.isBlank,
    next = Ember.run.next;
  var COMPONENT_CLASS = 'filter-select';
  var _default = Component.extend({
    /**
     * Props
     */

    allowClear: false,
    capitalize: false,
    closeOnSelect: true,
    onCreate: null,
    label: '',
    onchange: null,
    onsearch: null,
    options: [],
    placeholder: '',
    searchField: '',
    selected: [],
    selectMultipleInstance: null,
    size: '',
    type: 'multiple',
    /**
     * State
     */

    isOpen: false,
    classNameBindings: ["isOpen:".concat(COMPONENT_CLASS, "--is-open:").concat(COMPONENT_CLASS, "--is-closed"), 'hideTagsClass', 'sizeClass'],
    classNames: [COMPONENT_CLASS],
    selectRemoteController: null,
    /**
     * Computed
     */

    hideTagsClass: computed('isOpen', 'selected.length', function () {
      var className;
      if (!this.get('isOpen') && !this.get('selected.length')) {
        className = "".concat(COMPONENT_CLASS, "--hide-tags");
      }
      return className;
    }),
    sizeClass: computed('size', function () {
      var size = this.get('size');
      return size ? "".concat(COMPONENT_CLASS, "--").concat(size) : '';
    }),
    /**
     * Actions
     */

    actions: {
      createOnEnter: function createOnEnter(select, e) {
        if (e.keyCode === 13 && select.isOpen && !select.highlighted && !isBlank(select.searchText)) {
          this.sendAction('onCreate', select.searchText);
        }
      },
      onChange: function onChange(selected) {
        this.sendAction('onchange', selected);
      },
      onClose: function onClose() {
        this.set('isOpen', false);
      },
      onOpen: function onOpen() {
        this.set('isOpen', true);
      },
      openFilterSelect: function openFilterSelect() {
        var _this$get = this.get('selectRemoteController'),
          actions = _this$get.actions;
        var selectMultipleInstance = this.get('selectMultipleInstance');
        actions.open();
        if (selectMultipleInstance) {
          next(function () {
            selectMultipleInstance.focusInput();
          });
        }
      }
    }
  });
  _exports.default = _default;
});