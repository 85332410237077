define("bd-hybrid-cloud/templates/app/projects/project/note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Cf3KNhFh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"x-app-content-overlay-view\",null,[[\"subtitle\",\"onCloseRoute\"],[[25,\"t\",[\"notes.edit\"],null],\"app.projects.project\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"gr-8 gr-4@desktop gr-centered\"],[7],[0,\"\\n    \"],[1,[25,\"x-note-form\",null,[[\"content\",\"onSubmit\"],[[20,[\"model\",\"content\"]],\"updateNote\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/projects/project/note.hbs"
    }
  });
  _exports.default = _default;
});