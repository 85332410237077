define("bd-hybrid-cloud/routes/app/news/news-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.get('store').findRecord('news-item', params.id);
    }
  });
  _exports.default = _default;
});