define("bd-hybrid-cloud/components/modals/x-save-configuration-to-project-modal", ["exports", "bgr-ember-modal/components/bgr-modal", "bd-hybrid-cloud/mixins/component/add-to-project-modal"], function (_exports, _bgrModal, _addToProjectModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = _bgrModal.default.extend(_addToProjectModal.default, {
    /**
     * State
     */

    title: '',
    /**
     * Computed
     */

    hasNoTitle: computed.empty('title'),
    isDisabled: computed.or('hasNoProject', 'hasNoTitle'),
    /**
     * Actions
     */

    actions: {
      submit: function submit() {
        var _this = this;
        var title = this.get('title');
        var configData = this.get('model.configuration');
        this.getRelatedProject().then(function (relatedProject) {
          var configuration = _this.get('store').createRecord('configuration', {
            backgroundImage: configData.backgroundImage,
            json: configData.json,
            project: relatedProject,
            thumb: configData.thumb,
            title: title
          });
          configuration.addRelationships({
            quilts: configData.quilts,
            samples: configData.samples
          }).then(function () {
            configuration.save().then(function () {
              var successMessage = _this.get('trans').t('alert.success.savedModelToEntity', {
                entity: relatedProject.get('title'),
                model: title
              });
              _this.hide();
              _this.get('flashMessages').success(successMessage);
              _this.get('router').transitionTo('app.configuration.configuration.edit', configuration);
            });
          });
        });
      }
    }
  });
  _exports.default = _default;
});