define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/section/components", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rVLsSXZt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"x-editor-instruction\",null,[[\"arrowPosition\",\"centered\",\"theme\",\"text\"],[\"left\",\"true\",\"dark\",[25,\"t\",[\"configurations.instructions.selectComponent\"],null]]],{\"statements\":[[0,\"  \"],[6,\"img\"],[9,\"src\",\"/assets/images/interface/graphics/select-mattress-component.png\"],[9,\"width\",\"94\"],[9,\"height\",\"55\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/section/components.hbs"
    }
  });
  _exports.default = _default;
});