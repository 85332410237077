define("bd-hybrid-cloud/components/x-editor-option", ["exports", "bd-hybrid-cloud/utils/create-style-string"], function (_exports, _createStyleString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed;
  var _default = Component.extend({
    /**
     * Props
     */

    auto: false,
    grey: false,
    image: '',
    isActive: false,
    isHighlighted: false,
    onClick: null,
    tooltip: '',
    /**
     * State
     */

    attributeBindings: ['style'],
    classNameBindings: ['auto:editor-option--auto', 'grey:editor-option--grey', 'isActive:is-active', 'isHighlighted:is-highlighted'],
    classNames: ['editor-option'],
    /**
     * Computed
     */

    style: computed('image', function () {
      return (0, _createStyleString.default)({
        backgroundImage: this.get('image')
      });
    }),
    /**
     * Events
     */
    click: function click() {
      this.sendAction('onClick');
    }
  });
  _exports.default = _default;
});