define("bd-hybrid-cloud/templates/components/x-editor-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2Rkug8Gc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-option__cell\"],[7],[0,\"\\n  \"],[11,1],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"tooltip\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"x-tooltip\",null,[[\"text\"],[[20,[\"tooltip\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-editor-option.hbs"
    }
  });
  _exports.default = _default;
});