define("bd-hybrid-cloud/templates/partials/configuration/categories-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6bzVlGe/",
    "block": "{\"symbols\":[\"category\"],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-panel-tree__branches\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"configurationCategories\"]]],null,{\"statements\":[[0,\"    \"],[6,\"button\"],[9,\"class\",\"bd-button editor-panel-tree__branch u-text-left\"],[10,\"disabled\",[19,1,[\"shouldBeDisabled\"]],null],[3,\"action\",[[19,0,[]],\"openConfigurationCategory\",[19,1,[]]]],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"align align--fluid align--gutter-sm\"],[7],[0,\"\\n\"],[4,\"if\",[[19,1,[\"isParent\"]]],null,{\"statements\":[[0,\"          \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n            \"],[6,\"span\"],[10,\"class\",[26,[\"bd-text-lg bd-text-grey-dark icon-mattress-\",[25,\"dasherize\",[[19,1,[\"name\"]]],null]]]],[7],[8],[0,\"\\n          \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[6,\"div\"],[9,\"class\",\"align__cell align__cell--grow\"],[7],[0,\"\\n          \"],[6,\"p\"],[9,\"class\",\"u-text-sm u-text-left\"],[7],[0,\"\\n            \"],[1,[19,1,[\"name\"]],false],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"icon-chevron-right-light bd-text-grey\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/categories-list.hbs"
    }
  });
  _exports.default = _default;
});