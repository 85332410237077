define("bd-hybrid-cloud/templates/partials/share/detail-panel-section/component/detail-sample", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+/VdWAWg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\",\"sample\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"editor-panel__section\"],[7],[0,\"\\n    \"],[1,[25,\"x-share-sample-detail\",null,[[\"model\"],[[20,[\"model\",\"sample\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/share/detail-panel-section/component/detail-sample.hbs"
    }
  });
  _exports.default = _default;
});