define("bd-hybrid-cloud/templates/components/x-form-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hJ4NPcn2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"eq\",[[20,[\"type\"]],\"radio\"],null]],null,{\"statements\":[[0,\"  \"],[1,[25,\"radio-button\",null,[[\"changed\",\"disabled\",\"groupValue\",\"name\",\"radioClass\",\"value\"],[[25,\"action\",[[19,0,[]],\"change\"],null],[20,[\"disabled\"]],[20,[\"bindTo\"]],[20,[\"name\"]],\"form-control__input\",[20,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"input\",null,[[\"change\",\"checked\",\"class\",\"disabled\",\"name\",\"type\"],[[25,\"action\",[[19,0,[]],\"change\"],null],[20,[\"bindTo\"]],\"form-control__input\",[20,[\"disabled\"]],[20,[\"name\"]],\"checkbox\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[6,\"span\"],[10,\"class\",[26,[\"form-control__visual \",[18,\"visualClass\"]]]],[7],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"url\"]]],null,{\"statements\":[[0,\"  \"],[6,\"a\"],[9,\"class\",\"form-control__link link link--blue\"],[10,\"href\",[26,[[18,\"url\"]]]],[9,\"target\",\"_blank\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"label\"]]],null,{\"statements\":[[0,\"      \"],[1,[18,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[18,\"url\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"form-control__label\"],[7],[1,[18,\"label\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-form-control.hbs"
    }
  });
  _exports.default = _default;
});