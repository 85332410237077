define("bd-hybrid-cloud/templates/components/x-collapsible-view-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uS7JleXN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"isCollapsible\"]]],null,{\"statements\":[[0,\"  \"],[6,\"button\"],[9,\"class\",\"bd-button collapser\"],[3,\"action\",[[19,0,[]],\"toggle\"]],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"isCollapsed\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"collapser__text\"],[7],[1,[25,\"t\",[\"actions.viewAll\"],null],false],[8],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"collapser__icon icon-chevron-down\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"collapser__text\"],[7],[1,[25,\"t\",[\"actions.viewLess\"],null],false],[8],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"collapser__icon icon-chevron-up\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-collapsible-view-toggle.hbs"
    }
  });
  _exports.default = _default;
});