define("bd-hybrid-cloud/helpers/with-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Helper = Ember.Helper,
    isPresent = Ember.isPresent;
  function check(value, falsy) {
    return falsy ? value : isPresent(value);
  }
  var _default = Helper.extend({
    compute: function compute(params, hash) {
      var value = params[0];
      var falsy = hash.falsy;
      var addition = hash.unit ? " ".concat(hash.unit) : '';
      var fallback = isPresent(hash.default) ? hash.default : this.get('trans').t('general.notAvailable');
      return check(value, falsy) ? value + addition : fallback;
    }
  });
  _exports.default = _default;
});