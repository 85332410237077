define("bd-hybrid-cloud/adapters/configuration", ["exports", "bd-hybrid-cloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var urlForCreateRecord;
      if (snapshot.record.get('coverTemplate')) {
        urlForCreateRecord = this.createEndpoint('templates');
      } else {
        urlForCreateRecord = this._super(modelName, snapshot);
      }
      return urlForCreateRecord;
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var urlForDeleteRecord;
      var record = snapshot.record;
      var isTemplate = record.get('coverTemplate') || record.get('standardTemplate');
      if (isTemplate) {
        urlForDeleteRecord = this.buildURL('templates', id);
      } else {
        urlForDeleteRecord = this._super(id, modelName, snapshot);
      }
      return urlForDeleteRecord;
    },
    urlForQuery: function urlForQuery(query, modelName) {
      var urlForQuery;
      if (query.template) {
        // Temporarily preserve the "template" property on the query object
        // As this will currently permanently delete it from the object
        // causing a side effect where the current logic is not executed
        // anymore a second time.
        // Reflect.deleteProperty(query, 'template');

        urlForQuery = this.createEndpoint('templates');
      } else {
        urlForQuery = this._super(query, modelName);
      }
      return urlForQuery;
    },
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var urlForQueryRecord;
      if (query.share) {
        Reflect.deleteProperty(query, 'share');
        urlForQueryRecord = this.buildURL('share/configurations', query.identifier);
      } else {
        urlForQueryRecord = this._super(query, modelName);
      }
      return urlForQueryRecord;
    }
  });
  _exports.default = _default;
});