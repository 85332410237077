define("bd-hybrid-cloud/components/forms/x-delete-account-form", ["exports", "bd-hybrid-cloud/components/x-form"], function (_exports, _xForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var observer = Ember.observer;
  var _default = _xForm.default.extend({
    /**
     * State
     */

    isConfirmed: false,
    password: '',
    /**
     * Observers
     */

    isConfirmedObserver: observer('isConfirmed', function () {
      if (!this.get('isConfirmed')) {
        this.setProperties({
          errors: [],
          password: ''
        });
      }
    }),
    /**
     * Hooks
     */
    request: function request() {
      var _this = this;
      return this.get('api').post('user/check-password', this.getProperties('password')).then(function () {
        _this.get('activeUser.model').destroyRecord({
          adapterOptions: {
            force: true
          }
        }).then(function () {
          _this.get('session').invalidate();
        });
      });
    }
  });
  _exports.default = _default;
});