define("bd-hybrid-cloud/components/x-collapsible-view-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Props
     */

    isCollapsed: true,
    isCollapsible: true,
    onToggle: null,
    tagName: '',
    /**
     * Actions
     */

    actions: {
      toggle: function toggle() {
        this.sendAction('onToggle');
      }
    }
  });
  _exports.default = _default;
});