define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/component/handles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o856UPPU",
    "block": "{\"symbols\":[],\"statements\":[[12,\"partials/configuration/edit/editor-panel-section/component/edit-handle\",[]],[0,\"\\n\"],[4,\"if\",[[20,[\"allowHandleSampleEdit\"]]],null,{\"statements\":[[0,\"  \"],[12,\"partials/configuration/edit/editor-panel-section/component/edit-sample\",[]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/component/handles.hbs"
    }
  });
  _exports.default = _default;
});