define("bd-hybrid-cloud/templates/components/x-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FuVLza2h",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"log__cell\"],[7],[0,\"\\n  \"],[1,[25,\"x-avatar\",null,[[\"firstName\",\"lastName\",\"size\",\"hasNotification\"],[[20,[\"model\",\"firstName\"]],[20,[\"model\",\"lastName\"]],\"lg\",[20,[\"hasNotification\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"log__cell\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"log__desc\"],[7],[0,\"\\n    \"],[6,\"b\"],[7],[1,[20,[\"model\",\"firstName\"]],false],[0,\" \"],[1,[20,[\"model\",\"lastName\"]],false],[8],[0,\" \"],[1,[18,\"sentence\"],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"log__meta\"],[7],[0,\"\\n    \"],[6,\"span\"],[7],[1,[25,\"moment-format\",[[20,[\"model\",\"createdAt\"]],\"DD-MM-YYYY\"],null],false],[8],[0,\"\\n    \"],[6,\"span\"],[7],[1,[25,\"t\",[\"activityLog.at\"],null],false],[8],[0,\"\\n    \"],[6,\"span\"],[7],[1,[25,\"moment-format\",[[20,[\"model\",\"createdAt\"]],\"HH:mm\"],null],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-log.hbs"
    }
  });
  _exports.default = _default;
});