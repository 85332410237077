define("bd-hybrid-cloud/components/x-project-view/note-videos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIMEO_URL = 'https://player.vimeo.com/video/';
  var _default = Ember.Component.extend({
    /**
     * Arguments
     */

    notes: null,
    /**
     * State
     */

    tagName: '',
    /**
     * Computed
     */

    noteVideos: Ember.computed('notes.[]', function () {
      return this.get('notes').filter(function (note) {
        return note.get('content').includes(VIMEO_URL);
      });
    })
  });
  _exports.default = _default;
});