define("bd-hybrid-cloud/templates/share/configuration/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7BeRSye/",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"border-t border-gray-light edit-body__cell editor-panel overflow-hidden lg:border-none\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"flex flex-col-reverse flex-1 overflow-auto lg:overflow-hidden lg:flex-col\"],[7],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",[25,\"cx\",[\"border-t border-gray-light lg:border-none\",\"editor-panel-tree__header text-center\"],null],null],[7],[0,\"\\n      \"],[6,\"img\"],[9,\"alt\",\"BekaertDeslee Logo\"],[9,\"class\",\"w-1/2 md:w-auto\"],[9,\"height\",\"72\"],[9,\"src\",\"/assets/images/logos/bd-cloud-text-blue-horizontal.svg\"],[9,\"width\",\"265\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"editor-panel-tree__sub-header\"],[7],[0,\"\\n        \"],[6,\"p\"],[9,\"class\",\"text-sm\"],[7],[0,\"\\n          \"],[1,[25,\"t\",[\"share.createOwn\"],null],false],[6,\"br\"],[7],[8],[0,\"\\n          \"],[4,\"link-to\",[\"welcome\"],[[\"class\"],[\"link link--blue-darker link--underline\"]],{\"statements\":[[1,[25,\"t\",[\"header.signUpForFree\"],null],false]],\"parameters\":[]},null],[0,\" or \"],[4,\"link-to\",[\"login\"],[[\"class\"],[\"link link--blue-darker link--underline\"]],{\"statements\":[[1,[25,\"t\",[\"header.logIn\"],null],false]],\"parameters\":[]},null],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"flex-1 lg:overflow-hidden\"],[7],[0,\"\\n      \"],[12,[20,[\"detailPanelPartialName\"]],[]],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/share/configuration/detail.hbs"
    }
  });
  _exports.default = _default;
});