define("bd-hybrid-cloud/components/x-camera-view-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    oneWay = Ember.computed.oneWay,
    next = Ember.run.next;
  var _default = Component.extend({
    /**
     * props
     */

    isActive: null,
    onConfirm: null,
    onDelete: null,
    onSet: null,
    value: null,
    /**
     * state
     */

    classNameBindings: ['isActive', 'isEditDisabled:camera-view-input--edit-disabled'],
    classNames: ['camera-view-input'],
    internalValue: oneWay('value'),
    isEditDisabled: true,
    /**
     * actions
     */

    actions: {
      cancel: function cancel() {
        this.cancel();
        this.blur();
      },
      click: function click() {
        if (this.get('isEditDisabled')) {
          Ember.tryInvoke(this, 'onSet');
        }
      },
      confirm: function confirm() {
        this.confirm();
        this.blur();
      },
      delete: function _delete() {
        if (this.get('isEditDisabled')) {
          Ember.tryInvoke(this, 'onDelete');
        }
      },
      edit: function edit() {
        var _this = this;
        this.set('isEditDisabled', false);
        next(function () {
          _this.get('element').querySelector('input').focus();
        });
      }
    },
    /**
     * methods
     */
    blur: function blur() {
      this.set('isEditDisabled', true);
      document.activeElement.blur();
    },
    cancel: function cancel() {
      var value = this.get('value');
      if (value === this.get('internalValue')) {
        return;
      }
      this.set('internalValue', value);
    },
    confirm: function confirm() {
      var internalValue = this.get('internalValue');
      if (internalValue === this.get('value')) {
        return;
      }
      this.get('onConfirm')(internalValue);
    }
  });
  _exports.default = _default;
});