define("bd-hybrid-cloud/mixins/component/configuration-editor-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    Mixin = Ember.Mixin,
    on = Ember.on;
  var INIT_HANDLER_MAPPING = {
    '3d-border': 'init3dBorder',
    environment: 'initEnvironment',
    fabric: 'initFabric',
    handles: 'initHandles',
    legs: 'initLegs',
    piping: 'initPiping',
    tape: 'initTape',
    zipper: 'initZipper'
  };
  var ZIPPER_TYPES = [{
    id: 'spiral_coil',
    name: 'Spiral Coil'
  }, {
    id: 'reverse_coil',
    name: 'Reverse Coil'
  }, {
    id: 'teeth',
    name: 'Teeth'
  }];
  var PIPING_DIAMETERS = [{
    label: '3mm',
    value: 0.3
  }, {
    label: '6mm',
    value: 0.6
  }];
  var _default = Mixin.create({
    /**
     * Props
     */

    componentType: null,
    model: null,
    /**
     * State
     */

    classNames: ['editor-panel-tree'],
    legTypes: [],
    pipingDiameters: PIPING_DIAMETERS,
    pipingMaterial: 'cotton',
    ribbonMaterial: 'cotton',
    selectedColor: null,
    selectedHandleType: null,
    selectedLegType: null,
    selectedPipingDiameter: null,
    selectedZipperType: null,
    zipperTypes: ZIPPER_TYPES,
    /**
     * Computed
     */

    allowOffsetEdit: computed.or('isCustomComponent', 'isFabricLayer'),
    allowRotationEdit: computed.or('isCustomComponent', 'isFabricLayer'),
    hasRibbonComponent: computed.and('isFabricLayer', 'model.object.ribbon'),
    isCustomComponent: computed.equal('componentType', 'custom'),
    isFabricLayer: computed('componentType', function () {
      var componentType = this.get('componentType');
      return !!(componentType === 'fabric' || componentType === 'panel');
    }),
    shouldLoadPartial: computed.or('sectionName', 'componentType'),
    /**
     * Events
     */

    didInsertElementHandler: on('didInsertElement', function () {
      var model = this.get('model');
      var sectionName = this.get('sectionName');
      if (sectionName) {
        var initFunctionName = INIT_HANDLER_MAPPING[sectionName];
        if (initFunctionName) {
          this[initFunctionName]();
        }
      }
      if (model) {
        var _initFunctionName = INIT_HANDLER_MAPPING[model.type];
        if (_initFunctionName) {
          this[_initFunctionName]();
        }
      }
      if (!sectionName && !model) {
        this.sendAction('discardSelection');
      }
    }),
    /**
     * Methods
     */
    deselectMattressComponent: function deselectMattressComponent() {
      this.get('bd3d.mattressConfigurator').setSelectedData(null);
    },
    setBorderComponentColor: function setBorderComponentColor(selectedColor) {
      var color = selectedColor ? selectedColor.id : null;
      this.set('selectedColor', selectedColor);
      this.get('bd3d.mattressConfig').setBorderComponentColor(null, this.get('model.object'), color);
    },
    setColors: function setColors(type) {
      var colors = window.BD3D.Colors.getColorsByType(type);
      this.set('colors', colors);
    },
    getCurrentBorderComponentHeight: function getCurrentBorderComponentHeight() {
      return this.get('bd3d.mattressConfig').getBorderComponentHeight(this.get('model.single'), this.get('model.object'));
    },
    getMaterial: function getMaterial(material) {
      return material === 'cotton' ? 'matte' : 'glossy';
    },
    setInitialPipingDiameter: function setInitialPipingDiameter() {
      var pipingDiameter = this.getCurrentBorderComponentHeight();
      var currentPipingDiameter = this.get('pipingDiameters').findBy('value', pipingDiameter);
      this.set('selectedPipingDiameter', currentPipingDiameter);
    },
    setInitialSelectedZipper: function setInitialSelectedZipper() {
      var model = this.get('model');
      var zipperType = model.object.zipperType;
      var selectedZipperType = ZIPPER_TYPES.find(function (item) {
        return item.id === zipperType;
      });
      this.set('selectedZipperType', selectedZipperType);
    },
    setLegTypeOptions: function setLegTypeOptions() {
      var legTypes = this.get('bd3d.mattressConfig').getLegTypes();
      this.set('legTypes', legTypes);
    },
    setPipingColorOptions: function setPipingColorOptions() {
      var pipingMaterial = this.get('pipingMaterial');
      var suffix = this.getMaterial(pipingMaterial);
      this.setColors("piping".concat(suffix));
    },
    setPipingMaterial: function setPipingMaterial() {
      var pipingMaterial = this.get('model.object.material');
      if (pipingMaterial) {
        this.set('pipingMaterial', pipingMaterial);
      }
    },
    setRibbonColorOptions: function setRibbonColorOptions() {
      var ribbonMaterial = this.get('ribbonMaterial');
      var suffix = this.getMaterial(ribbonMaterial);
      this.setColors("ribbon".concat(suffix));
    },
    setRibbonMaterial: function setRibbonMaterial() {
      var ribbonMaterial = this.get('model.object.ribbon.material');
      if (ribbonMaterial) {
        this.set('ribbonMaterial', ribbonMaterial);
      }
    },
    setSelectedColor: function setSelectedColor() {
      var color;
      if (this.get('hasRibbonComponent')) {
        color = this.get('model.object.ribbon.color');
      } else {
        color = this.get('model.object.color');
      }
      var selectedColor = this.get('colors').findBy('id', color);

      // no color found, assume it's a custom color
      if (!selectedColor) {
        selectedColor = {
          color: color,
          id: color,
          isCustom: true
        };
      }
      this.set('selectedColor', selectedColor);
    },
    setSelectedLegType: function setSelectedLegType() {
      var setSelectedLegTypeId = this.get('model.object.id');
      var selectedLegType = this.get('legTypes').findBy('id', setSelectedLegTypeId);
      this.set('selectedLegType', selectedLegType);
    }
  });
  _exports.default = _default;
});