define("bd-hybrid-cloud/services/store", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Store = _emberData.default.Store;
  var Promise = Ember.RSVP.Promise;
  var _default = Store.extend({
    /**
     * State
     */

    _seekAllCache: [],
    /**
     * Methods
     */
    seekAll: function seekAll(modelName, options) {
      var seekAllCache = this.get('_seekAllCache');
      var promise;
      if (seekAllCache.includes(modelName)) {
        promise = Promise.resolve(this.peekAll(modelName));
      } else {
        seekAllCache.push(modelName);
        promise = this.findAll(modelName, options);
      }
      return promise;
    },
    seekRecord: function seekRecord(modelName, id, options) {
      var record = this.peekRecord(modelName, id);
      var promise;
      if (record) {
        promise = Promise.resolve(record);
      } else {
        promise = this.findRecord(modelName, id, options);
      }
      return promise;
    }
  });
  _exports.default = _default;
});