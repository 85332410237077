define("bd-hybrid-cloud/templates/partials/welcome/showroom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FjXENCYi",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"hero hero--section-2\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"container u-text-center\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"gr-12\"],[7],[0,\"\\n          \"],[6,\"h1\"],[9,\"class\",\"lg:text-5xl bd-title bd-title--light\"],[7],[1,[25,\"t\",[\"showroom.title\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"gr-8 gr-centered\"],[7],[0,\"\\n          \"],[6,\"p\"],[9,\"class\",\"bd-text-grey-dark\"],[7],[1,[25,\"t\",[\"showroom.text\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"gr-12 u-text-center\"],[7],[0,\"\\n          \"],[6,\"img\"],[9,\"src\",\"/assets/images/visual-screen-2.jpg\"],[9,\"class\",\"u-fluid-image\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/welcome/showroom.hbs"
    }
  });
  _exports.default = _default;
});