define("bd-hybrid-cloud/components/modals/x-add-to-project-modal", ["exports", "bgr-ember-modal/components/bgr-modal", "bd-hybrid-cloud/mixins/component/add-to-project-modal"], function (_exports, _bgrModal, _addToProjectModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _bgrModal.default.extend(_addToProjectModal.default, {
    /**
     * Actions
     */

    actions: {
      addToProject: function addToProject() {
        var _this = this;
        this.getRelatedProject().then(function (relatedProject) {
          _this.resolve(relatedProject);
        });
      }
    }
  });
  _exports.default = _default;
});