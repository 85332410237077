define("bd-hybrid-cloud/mixins/model/project", ["exports", "ember-data", "bgr-ember-json-api"], function (_exports, _emberData, _bgrEmberJsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    Mixin = Ember.Mixin,
    Promise = Ember.RSVP.Promise;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    hasMany = _emberData.default.hasMany,
    PromiseObject = _emberData.default.PromiseObject;
  var _default = Mixin.create({
    /**
     * Attributes
     */

    archived: attr('boolean'),
    copiedFromConcept: attr('boolean'),
    copiedProjectId: attr('number'),
    createdAt: attr('string'),
    description: attr('string'),
    identifier: attr('string'),
    isConcept: attr('boolean'),
    isScoop: attr('boolean'),
    isPrivate: attr('boolean'),
    thumbUrl: attr('string'),
    title: attr('string'),
    updatedAt: attr('string'),
    /**
     * Relationships
     */

    configurations: hasMany('configuration', {
      async: false
    }),
    documents: hasMany('file', {
      async: false
    }),
    logs: hasMany('log'),
    members: hasMany('member', {
      meta: _bgrEmberJsonApi.Meta.Model.extend({
        canAddNote: _bgrEmberJsonApi.Meta.attr({
          defaultValue: 0
        }),
        canEdit: _bgrEmberJsonApi.Meta.attr({
          defaultValue: 0
        }),
        date: _bgrEmberJsonApi.Meta.attr({
          defaultValue: null
        })
      })
    }),
    notes: hasMany('note', {
      async: false
    }),
    notifications: hasMany('notification'),
    owner: belongsTo('user'),
    quiltPatterns: hasMany('quiltPattern', {
      async: false
    }),
    samples: hasMany('sample', {
      async: false,
      meta: _bgrEmberJsonApi.Meta.Model.extend({
        note: _bgrEmberJsonApi.Meta.attr({
          defaultValue: null
        })
      })
    }),
    sketchbook: hasMany('photo', {
      async: false
    }),
    tags: hasMany('tag', {
      async: false
    }),
    /**
     * Computed
     */

    canAddNote: computed('activeUser.model.id', 'members.length', 'owner.id', function () {
      var ownerId = this.get('owner.id');
      var activeUserId = this.get('activeUser.model.id');
      var promise;
      if (ownerId === activeUserId) {
        promise = Promise.resolve(true);
      } else {
        promise = this.findMeta('members', activeUserId).then(function (meta) {
          return !!(meta.get('canAddNote') || meta.get('canEdit'));
        }).catch(function () {
          return false;
        });
      }
      return PromiseObject.create({
        promise: promise
      });
    }),
    canEdit: computed('activeUser.model.id', 'members.length', 'owner.id', function () {
      var ownerId = this.get('owner.id');
      var activeUserId = this.get('activeUser.model.id');
      var promise;
      if (ownerId === activeUserId) {
        promise = Promise.resolve(true);
      } else {
        promise = this.findMeta('members', activeUserId).then(function (meta) {
          return !!meta.get('canEdit');
        }).catch(function () {
          return false;
        });
      }
      return PromiseObject.create({
        promise: promise
      });
    })
  });
  _exports.default = _default;
});