define("bd-hybrid-cloud/routes/app/settings/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route,
    RSVP = Ember.RSVP;
  var _default = Route.extend({
    deactivate: function deactivate() {
      this.get('activeUser.model').rollbackAttributes();
    },
    model: function model() {
      return RSVP.hash({
        countries: this.get('store').seekAll('country')
      });
    }
  });
  _exports.default = _default;
});