define("bd-hybrid-cloud/components/modals/x-add-to-capsules-modal", ["exports", "bgr-ember-modal/components/bgr-modal"], function (_exports, _bgrModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _bgrModal.default.extend({
    /**
     * State
     */

    regions: null,
    selectedRegion: null,
    /**
     * Hooks
     */
    afterShow: function afterShow() {
      var _this = this;
      this.get('store').findAll('region').then(function (regions) {
        _this.set('regions', regions);
      });
    },
    /**
     * Actions
     */

    actions: {
      addProjectToCapsules: function addProjectToCapsules() {
        var selectedRegion = this.get('selectedRegion');
        if (selectedRegion) {
          this.resolve({
            regionId: selectedRegion.id
          });
        }
      }
    }
  });
  _exports.default = _default;
});