define("bd-hybrid-cloud/routes/app/capsules/index", ["exports", "bd-hybrid-cloud/mixins/route/filter-query"], function (_exports, _filterQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_filterQuery.default, {
    model: function model(params) {
      return {
        capsulesQuery: {
          filter: this.generateFilterObject(params),
          is_capsule: true,
          page: params.page,
          search: true,
          sort: params.sort
        }
      };
    }
  });
  _exports.default = _default;
});