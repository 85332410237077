define("bd-hybrid-cloud/templates/components/x-note-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+kXBVYXo",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"form__row\"],[7],[0,\"\\n  \"],[1,[25,\"textarea\",null,[[\"class\",\"disabled\",\"placeholder\",\"value\"],[\"bd-textarea bd-textarea--regular bd-textarea--white\",[25,\"not\",[[20,[\"isEditable\"]]],null],[25,\"t\",[\"notes.createPlaceholder\"],null],[20,[\"content\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"isEditable\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"form__row u-text-center\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--regular bd-button--solid\"],[10,\"disabled\",[18,\"isDisabled\"],null],[9,\"type\",\"submit\"],[7],[1,[25,\"t\",[\"actions.save\"],null],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-note-form.hbs"
    }
  });
  _exports.default = _default;
});