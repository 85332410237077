define("bd-hybrid-cloud/controllers/app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller,
    observer = Ember.observer,
    next = Ember.run.next;
  var _default = Controller.extend({
    /**
     * State
     */

    queryParams: ['sampleId'],
    sampleId: null,
    sampleModal: null,
    preserveScrollPosition: false,
    /**
     * Observers
     */

    sampleIdObserver: observer('sampleId', function () {
      var _this = this;
      var sampleId = this.get('sampleId');
      var sampleModal = this.get('sampleModal');
      if (sampleId) {
        sampleModal = this.get('modal').create('sample', {
          sampleId: sampleId
        });
        this.setProperties({
          sampleModal: sampleModal,
          preserveScrollPosition: true
        });
        sampleModal.one('hide', function () {
          _this.setProperties({
            sampleId: null,
            sampleModal: null
          });
          next(function () {
            _this.set('preserveScrollPosition', false);
          });
        });
        sampleModal.show();
      } else if (sampleModal) {
        sampleModal.hide();
      }
    })
  });
  _exports.default = _default;
});