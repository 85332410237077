define("bd-hybrid-cloud/templates/components/load-relationship-meta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0zyMU8Ru",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[19,0,[\"loadRelationshipMetaTask\",\"isRunning\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/load-relationship-meta.hbs"
    }
  });
  _exports.default = _default;
});