define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel-section/component/edit-piping-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yE05TAH0",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-panel__section\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n    Choose a piping color:\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n    \"],[1,[25,\"x-color-picker\",null,[[\"allowCustomColor\",\"class\",\"colors\",\"filterBy\",\"hexProperty\",\"multiple\",\"onChange\",\"selected\"],[true,\"color-picker--fixed\",[20,[\"colors\"]],\"US\",\"color\",false,[25,\"action\",[[19,0,[]],\"onColorSelect\"],null],[20,[\"selectedColor\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel-section/component/edit-piping-color.hbs"
    }
  });
  _exports.default = _default;
});