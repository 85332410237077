define("bd-hybrid-cloud/components/x-select-multiple", ["exports", "ember-power-select/components/power-select-multiple", "bd-hybrid-cloud/mixins/component/select"], function (_exports, _powerSelectMultiple, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _powerSelectMultiple.default.extend(_select.default, {
    instance: null,
    initHandler: Ember.on('didInsertElement', function () {
      this.set('instance', this);
    })
  });
  _exports.default = _default;
});