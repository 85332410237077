define("bd-hybrid-cloud/controllers/app/projects/project/add/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    Controller = Ember.Controller;
  var _default = Controller.extend({
    /**
     * State
     */
    isUploading: false,
    /**
     * Computed
     */
    endpoint: computed('model.id', function () {
      return "projects/".concat(this.get('model.id'), "/documents");
    })
  });
  _exports.default = _default;
});