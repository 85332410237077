define("bd-hybrid-cloud/instance-initializers/ember-cli-tailwind", ["exports", "bd-hybrid-cloud/router"], function (_exports, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var TailwindApplicationRoute = Ember.Route.extend({
    router: Ember.inject.service('-routing'),
    renderTemplate: function renderTemplate() {
      this.render('applicationTailwind', {
        controller: this.controllerFor('applicationTailwind')
      });
    }
  });
  function initialize(appInstance) {
    var fastboot = appInstance.lookup('service:fastboot');
    var fastbootIsInstalled = fastboot;
    var fastbootIsNotInstalled = !fastboot;
    var notUsingFastboot = fastbootIsNotInstalled || fastbootIsInstalled && !fastboot.get('isFastBoot');
    var router = appInstance.lookup('service:router')._router;
    var initialURL = router.initialURL || (window && window.location ? window.location.href : ''); // fastboot guard :/

    if (notUsingFastboot && initialURL.match('/tailwind')) {
      appInstance.register('route:application', TailwindApplicationRoute);
      _router.default.map(function () {
        this.route('tailwind');
      });
    }
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});