define("bd-hybrid-cloud/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zzpW7U/U",
    "block": "{\"symbols\":[],\"statements\":[[6,\"section\"],[9,\"class\",\"section section--lg u-text-center\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"gr-4 gr-centered\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n          \"],[6,\"h1\"],[9,\"class\",\"bd-title bd-title--h4 bd-title--semi-bold\"],[7],[1,[25,\"t\",[\"notFound.title\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n          \"],[6,\"p\"],[7],[1,[25,\"t\",[\"notFound.description\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n          \"],[4,\"link-to\",[\"app\"],[[\"class\"],[\"bd-button bd-button--regular bd-button--solid\"]],{\"statements\":[[1,[25,\"t\",[\"notFound.action\"],null],false]],\"parameters\":[]},null],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/not-found.hbs"
    }
  });
  _exports.default = _default;
});