define("bd-hybrid-cloud/components/x-grid-quilts-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed,
    inject = Ember.inject,
    on = Ember.on;
  var _default = Component.extend({
    /**
     * Injections
     */

    loadingState: inject.service(),
    /**
     * Props
     */

    columnWidth: 4,
    filterable: false,
    filterQuiltsIsVisible: false,
    hasToggleFilterButton: false,
    includeProjects: false,
    isCollapsed: false,
    items: [],
    onlyProducts: false,
    searchPlaceholder: '',
    sort: '',
    sortable: false,
    sortingAlphabetical: '',
    sortingChronological: '-updated_at',
    /**
     * State
     */

    classNames: ['container', 'grid-view'],
    /**
     * Computed
     */

    blockGridClass: computed('isCollapsed', 'blockGridColumnClass', function () {
      var blockGridClass;
      if (this.get('isCollapsed')) {
        blockGridClass = 'block-grid--is-collapsed';
      }
      blockGridClass = "".concat(blockGridClass, " ").concat(this.get('blockGridColumnClass'));
      return blockGridClass;
    }),
    blockGridColumnClass: computed('filterQuiltsIsVisible', 'isCollapsed', function () {
      return this.get('filterQuiltsIsVisible') || this.get('isCollapsed') ? 'block-grid--6' : this.get('defaultColumnClass');
    }),
    canFilter: computed.and('filterQuiltsIsVisible', 'filterable'),
    defaultColumnClass: computed('columnWidth', function () {
      return "block-grid--".concat(this.get('columnWidth'));
    }),
    gridClass: computed('canFilter', 'isCollapsed', function () {
      var gridClass;
      if (this.get('isCollapsed')) {
        gridClass = '';
      } else {
        gridClass = this.get('canFilter') ? 'gr-9' : 'gr-12';
      }
      return gridClass;
    }),
    showToggleFilterButton: computed.or('filterable', 'hasToggleFilterButton'),
    /**
     * Events
     */

    didDestroyElementHandler: on('didDestroyElement', function () {
      this.set('filterQuiltsIsVisible', false);
    }),
    /**
     * Actions
     */

    actions: {
      toggleQuiltsFilter: function toggleQuiltsFilter() {
        this.toggleProperty('filterQuiltsIsVisible');
      }
    }
  });
  _exports.default = _default;
});