define("bd-hybrid-cloud/components/sortable-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    sortableItems: null,
    tagName: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('sortableItems', this.get('items').toArray());
    }
  });
  _exports.default = _default;
});