define("bd-hybrid-cloud/components/x-cookie-statement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * State
     */

    visible: true,
    /**
     * Handlers
     */

    initHandler: Ember.on('init', function () {
      var acceptedCookies = window.Cookies.get('acceptedCookies');
      var optOutCookie = window.Cookies.get('optOutCookie');
      if (optOutCookie === 'true' || acceptedCookies) {
        this.visible = false;
        if (optOutCookie) {
          window['ga-disable-UA-55507403-1'] = true;
        }
      }
    }),
    /**
     * Actions
     */

    actions: {
      confirm: function confirm() {
        this.set('visible', false);
        window.Cookies.set('acceptedCookies', 'true', {
          expires: 356 * 100
        });
      },
      optOut: function optOut() {
        this.set('visible', false);
        window['ga-disable-UA-55507403-1'] = true;
        window.Cookies.set('optOutCookie', 'true', {
          expires: 356 * 100
        });
      }
    }
  });
  _exports.default = _default;
});