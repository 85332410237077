define("bd-hybrid-cloud/components/x-password-input", ["exports", "password-strength", "bd-hybrid-cloud/utils/create-style-string"], function (_exports, _passwordStrength, _createStyleString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed;
  var PASSWORD_STRENGTH_COLORS = ['#bf2e1e', '#e74c3c', '#e7773c', '#e7cd3c', '#2ecc71'];
  var _default = Component.extend({
    /**
     * Props
     */

    disabled: false,
    placeholder: '',
    value: '',
    /**
     * State
     */

    classNames: ['password-input'],
    showPassword: false,
    /**
     * Computed
     */

    buttonClass: computed('showPassword', function () {
      return this.get('showPassword') ? 'bd-button--blue' : 'bd-button--grey';
    }),
    progressStyle: computed('value', function () {
      var score = (0, _passwordStrength.default)(this.get('value')).score;
      return (0, _createStyleString.default)({
        backgroundColor: PASSWORD_STRENGTH_COLORS[score],
        width: "".concat((score + 1) * 20, "%")
      });
    }),
    /**
     * Actions
     */

    actions: {
      togglePassword: function togglePassword() {
        this.toggleProperty('showPassword');
      }
    }
  });
  _exports.default = _default;
});