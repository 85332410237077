define("bd-hybrid-cloud/components/x-configuration-share-panel", ["exports", "bd-hybrid-cloud/mixins/component/configuration-editor-panel"], function (_exports, _configurationEditorPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed;
  var _default = Component.extend(_configurationEditorPanel.default, {
    /**
     * Computed
     */

    partialName: computed('quiltPatternCategoryId', 'componentType', function () {
      var path;
      var componentType = this.get('componentType');
      if (componentType) {
        var partialName = componentType === 'top' || componentType === 'bottom' ? 'fabric' : componentType;
        path = "component/".concat(partialName);
      }
      return "partials/share/detail-panel/".concat(path);
    }),
    panelTitle: computed('model', function () {
      var panelTitle;
      var componentType = this.get('componentType');
      if (componentType) {
        panelTitle = this.get('model.type');
      }
      return panelTitle;
    }),
    pipingMaterialName: computed('pipingMaterial', function () {
      return this.getMaterial(this.get('pipingMaterial'));
    }),
    ribbonMaterialName: computed('ribbonMaterial', function () {
      return this.getMaterial(this.get('pipingMaterial'));
    }),
    /**
     * Methods
     */
    init3dBorder: function init3dBorder() {
      this.setColors('border3d');
      this.setSelectedColor();
    },
    initFabric: function initFabric() {
      if (this.get('model.object.ribbon')) {
        this.initRibbon();
      }
    },
    initHandles: function initHandles() {
      this.setSelectedHandle();
    },
    initLegs: function initLegs() {
      this.setLegTypeOptions();
      this.setSelectedLegType();
    },
    initPiping: function initPiping() {
      this.setInitialPipingDiameter();
      this.setPipingMaterial();
      this.setPipingColorOptions();
      this.setSelectedColor();
    },
    initRibbon: function initRibbon() {
      this.setRibbonMaterial();
      this.setRibbonColorOptions();
      this.setSelectedColor();
    },
    initTape: function initTape() {
      this.setColors('tape');
      this.setSelectedColor();
    },
    initZipper: function initZipper() {
      this.setInitialSelectedZipper();
      this.setColors('zipper');
      this.setSelectedColor();
    },
    setSelectedHandle: function setSelectedHandle() {
      this.set('handleType', this.get('model.object.type'));
    },
    /**
     * Actions
     */

    actions: {
      goBack: function goBack() {
        var queryParams = {
          component: ''
        };
        this.get('router').transitionTo('share.configuration.detail', {
          queryParams: queryParams
        });
      }
    }
  });
  _exports.default = _default;
});