define("bd-hybrid-cloud/components/x-avatar", ["exports", "bd-hybrid-cloud/utils/create-style-string"], function (_exports, _createStyleString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed;
  var sizes = ['sm', 'lg'];
  var sizeError = "Avatar: Invalid size. Please provide one of the following sizes: ".concat(sizes.join(', '), ".");
  var _default = Component.extend({
    /**
     * Props
     */

    firstName: '',
    lastName: '',
    size: '',
    url: '',
    hasNotification: false,
    /**
     * State
     */

    attributeBindings: ['style'],
    classNameBindings: ['sizeModifierClass', 'hasNotificationClass'],
    classNames: ['avatar'],
    /**
     * Computed
     */

    initials: computed('firstName', 'lastName', 'url', function () {
      var initials = '';
      if (!this.get('url')) {
        var firstName = this.get('firstName');
        var lastName = this.get('lastName');
        if (firstName) {
          initials += firstName.charAt(0);
        }
        if (lastName) {
          initials += lastName.charAt(0);
        }
      }
      return initials;
    }),
    sizeModifierClass: computed('size', function () {
      var size = this.get('size');
      var sizeModifierClass;
      if (size) {
        if (sizes.includes(size)) {
          sizeModifierClass = "avatar--".concat(size);
        } else {
          throw new Error(sizeError);
        }
      }
      return sizeModifierClass;
    }),
    hasNotificationClass: computed('hasNotification', function () {
      var hasNotification = this.get('hasNotification');
      var hasNotificationClass;
      if (hasNotification) {
        hasNotificationClass = 'avatar--has-notification';
      }
      return hasNotificationClass;
    }),
    style: computed('url', function () {
      return (0, _createStyleString.default)({
        backgroundImage: this.get('url')
      });
    })
  });
  _exports.default = _default;
});