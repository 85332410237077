define("bd-hybrid-cloud/controllers/app/projects/project/add/samples", ["exports", "bd-hybrid-cloud/mixins/filter-query", "bd-hybrid-cloud/mixins/controller/selected-samples"], function (_exports, _filterQuery, _selectedSamples) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller;
  var _default = Controller.extend(_filterQuery.default, _selectedSamples.default, {
    /**
     * Actions
     */

    actions: {
      addSamplesToProject: function addSamplesToProject() {
        var _this = this;
        this.get('model.project').addRecordsToRelationship('samples', this.get('selectedSamples')).saveRelationship('samples').then(function () {
          _this.transitionToRoute('app.projects.project');
        });
      }
    }
  });
  _exports.default = _default;
});