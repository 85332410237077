define("bd-hybrid-cloud/components/modals/x-configuration-info-modal", ["exports", "bgr-ember-modal/components/bgr-modal"], function (_exports, _bgrModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = _bgrModal.default.extend({
    /**
     * State
     */

    activeSingleId: null,
    classNames: ['modal--lg'],
    singles: [],
    /**
     * Computed
     */

    activeSingleData: computed('activeSingleId', function () {
      var _this = this;
      return this.get('singles').find(function (item) {
        return item.id === _this.get('activeSingleId');
      });
    }),
    showSubNavigation: computed.gt('singles.length', 1),
    /**
     * Methods
     */
    setSinglesInformation: function setSinglesInformation() {
      var singles = this.get('bd3d').getSinglesInformation();
      var activeSingleId = singles && singles[0] ? singles[0].id : null;
      this.setProperties({
        activeSingleId: activeSingleId,
        singles: singles
      });
    },
    /**
     * Action
     */

    actions: {
      setActiveSingleId: function setActiveSingleId(singleId) {
        this.set('activeSingleId', singleId);
      }
    },
    /**
     * Events
     */
    beforeShow: function beforeShow() {
      this.setSinglesInformation();
    }
  });
  _exports.default = _default;
});