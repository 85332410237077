define("bd-hybrid-cloud/controllers/app/projects/project/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    Controller = Ember.Controller;
  var TYPES = ['mine', 'shared'];
  var _default = Controller.extend({
    /**
     * State
     */

    queryParams: ['type'],
    /**
     * Computed
     */

    parentRoute: computed('type', function () {
      var type = this.get('type');
      if (!TYPES.includes(type)) {
        type = TYPES[0];
      }
      return "app.projects.".concat(type);
    })
  });
  _exports.default = _default;
});