define("bd-hybrid-cloud/services/active-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    observer = Ember.observer,
    Service = Ember.Service;
  var GA = window.ga;
  var BS = window.Bugsnag;
  var GTM_DATA_LAYER = window.dataLayer;
  var CREATE_PERMISSIONS = ['configureCoverSolutions', 'configurePlayground', 'createEditCoverSolutions', 'createEditPlayground', 'createEditSamples', 'createEditTemplate'];
  var _default = Service.extend({
    /**
     * State
     */

    can: {},
    model: null,
    permissions: [],
    /**
     * Computed
     */

    isEmployee: computed.readOnly('model.isEmployee'),
    /**
     * Observers
     */

    modelObserver: observer('model', function () {
      this.addUserDataToTrackingTools();
    }),
    permissionsObserver: observer('permissions', function () {
      var can = this.get('can');
      this.get('permissions').forEach(function (permission) {
        can[permission.get('slug').camelize()] = true;
      });
      can.create = CREATE_PERMISSIONS.some(function (permission) {
        return can[permission];
      });
    }),
    /**
     * Methods
     */
    addUserDataToTrackingTools: function addUserDataToTrackingTools() {
      var _this$get$getProperti = this.get('model').getProperties('id', 'isEmployee'),
        id = _this$get$getProperti.id,
        isEmployee = _this$get$getProperti.isEmployee;
      var userType = isEmployee ? 'employee' : 'external';
      if (id) {
        if (BS) {
          BS.user = {
            id: id
          };
        }
        if (GA) {
          GA('set', 'userId', id);

          // Sets the dimension value at index 1
          GA('set', 'dimension1', userType);
        }
        if (GTM_DATA_LAYER) {
          GTM_DATA_LAYER.push({
            userId: id
          });

          // Sets the dimension value at index 1
          GTM_DATA_LAYER.push({
            dimension1: userType
          });
        }
      }
    }
  });
  _exports.default = _default;
});