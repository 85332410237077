define("bd-hybrid-cloud/routes/app/projects/project/add/description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route,
    RSVP = Ember.RSVP;
  var _default = Route.extend({
    model: function model() {
      var projectId = this.modelFor('app.projects.project').id;
      return RSVP.hash({
        project: this.store.loadRecord('project', projectId),
        tags: this.get('store').findAll('tag')
      });
    }
  });
  _exports.default = _default;
});