define("bd-hybrid-cloud/components/modals/x-image-modal", ["exports", "bgr-ember-modal/components/bgr-modal", "ember-keyboard", "bd-hybrid-cloud/utils/slideshow"], function (_exports, _bgrModal, _emberKeyboard, _slideshow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INITIAL_ACTIVE_IMAGE_INDEX = 0;
  var _default = _bgrModal.default.extend(_emberKeyboard.EKMixin, _emberKeyboard.EKOnInsertMixin, {
    /**
     * State
     */

    activeImageIndex: INITIAL_ACTIVE_IMAGE_INDEX,
    classNames: ['modal--lg'],
    /**
     * Hooks
     */
    init: function init() {
      this._super.apply(this, arguments);
      this.activeImageIndex = this.get('model.activeImageIndex') || INITIAL_ACTIVE_IMAGE_INDEX;
    },
    /**
     * Computed
     */

    activeImage: Ember.computed('activeImageIndex', 'model.images', function () {
      return this.get('model.images')[this.get('activeImageIndex')];
    }),
    canNavigateToNextSlide: Ember.computed('activeImageIndex', 'model.images', function () {
      return (0, _slideshow.canNavigateToNextSlide)(this.get('activeImageIndex'), this.get('model.images'));
    }),
    canNavigateToPreviousSlide: Ember.computed('activeImageIndex', 'model.images', function () {
      return (0, _slideshow.canNavigateToPreviousSlide)(this.get('activeImageIndex'), this.get('model.images'));
    }),
    /**
     * Methods
     */
    onNextClick: function onNextClick() {
      if (this.get('canNavigateToNextSlide')) {
        var nextActiveImageIndex = this.get('activeImageIndex') + 1;
        this.set('activeImageIndex', nextActiveImageIndex);
      }
    },
    onPreviousClick: function onPreviousClick() {
      if (this.get('canNavigateToPreviousSlide')) {
        var nextActiveImageIndex = this.get('activeImageIndex') - 1;
        this.set('activeImageIndex', nextActiveImageIndex);
      }
    },
    triggerNextClick: Ember.on((0, _emberKeyboard.keyUp)('ArrowRight'), function () {
      this.onNextClick();
    }),
    triggerPreviousClick: Ember.on((0, _emberKeyboard.keyUp)('ArrowLeft'), function () {
      this.onPreviousClick();
    })
  });
  _exports.default = _default;
});