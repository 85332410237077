define("bd-hybrid-cloud/components/x-configurator-custom-property-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Props
     */
    property: null,
    model: null,
    tagName: '',
    params: null,
    /*
     * Methods
     */
    /**
     * @method applyPropertyValue
     * @description Applies a value to the property object
     * @param {object} propertyObject - internal property data object
     * @param {object} value - value
     * @param {boolean} flush - if true, flushes the property immediately to the 3D view
     *  Not recommended while live dragging if the property change requires loading / rebuilding
     * */
    applyPropertyValue: function applyPropertyValue(propertyObject, value, flush) {
      var currentValue = this.getPropertyValue(propertyObject);
      if (currentValue !== value) {
        this.set('property.value', value);
      }
      if (flush) {
        var configurator = this.get('bd3d.mattressConfigurator');
        var old = configurator.getConfigPropertyValue(propertyObject.property);
        if (old !== value) {
          configurator.setConfigPropertyValue(propertyObject.property, value);
        }
      }
    },
    getPropertyValue: function getPropertyValue(propertyObject) {
      return propertyObject.value;
      // const { index } = propertyObject;

      // return this.get(`properties.${index}.value`);
    },

    /**
    * Actions
    */
    actions: {
      // Opening the sample panel (params = the current property object)
      onOpenSamplePanel: function onOpenSamplePanel(section, params) {
        this.sendAction('openSamplePanel', section, params);
      },
      onChangedNumericPropertyInput: function onChangedNumericPropertyInput(propertyObject, value) {
        this.applyPropertyValue(propertyObject, value, true);
      },
      onChangedNumericPropertySlider: function onChangedNumericPropertySlider(propertyObject, value) {
        this.applyPropertyValue(propertyObject, value, true);
      },
      onChangingNumericPropertySlider: function onChangingNumericPropertySlider(propertyObject, value) {
        var property = propertyObject ? propertyObject.property : null;
        var propertyData = property ? property.data : null;
        var propertyMeta = propertyData ? propertyData.metadata : null;
        var live = propertyMeta ? !!propertyMeta.live : false;

        // If 'live' is true, update the 3D immediately.
        // Only used if the property can be updated fast, like changing shader parameters.
        // Better not be used for properties that require reloading assets or rebuilding the scene.
        this.applyPropertyValue(propertyObject, value, live);
      },
      onRotateClick: function onRotateClick(propertyObject, actionObject) {
        var configurator = this.get('bd3d.mattressConfigurator');

        // Actually not really setting a property but triggering an action
        // (rotating the sample left or right)
        configurator.setConfigPropertyValue(propertyObject.property, actionObject.action);
      },
      onColorSelect: function onColorSelect(propertyObject, colorObject) {
        var color = colorObject.color,
          id = colorObject.id,
          isCustom = colorObject.isCustom;
        var property = propertyObject.property;
        var configurator = this.get('bd3d.mattressConfigurator');

        // Save the color id to the configuration,
        // unless it's a custom color -> save hex value in that case
        var value = isCustom ? color : id;
        configurator.setConfigPropertyValue(property, value, {
          refreshParams: {
            events: {
              types: {
                config_complete: false
              }
            }
          }
        });
      }
    }
  });
  _exports.default = _default;
});