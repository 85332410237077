define("bd-hybrid-cloud/mixins/controller/configuration-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Mixin = Ember.Mixin,
    on = Ember.on;
  var _default = Mixin.create({
    /**
     * State
     */

    component: '',
    queryParams: ['component'],
    selectedComponentData: null,
    /**
     * Events
     */

    mixinInitHandler: on('init', function () {
      this.get('bd3d').on('clickNone', this, this.discardSelection);
      this.get('bd3d').on('changedSelection', this, this.onMattressSelect);
    }),
    /**
     * Methods
     */
    onMattressSelect: function onMattressSelect() {
      var selectedComponentData = this.get('bd3d.mattressConfigurator').getSelectedDataInfo();
      this.set('selectedComponentData', selectedComponentData);
      this.openComponentPanel(selectedComponentData ? selectedComponentData.type : null);
    },
    openComponentPanel: function openComponentPanel(type) {
      var componentType = '';
      if (type) {
        componentType = type === 'top' || type === 'bottom' ? 'panel' : type;
      }
      if (componentType !== 'single') {
        this.discardSelection();
        this.set('component', componentType);
      }
    }
  });
  _exports.default = _default;
});