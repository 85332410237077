define("bd-hybrid-cloud/serializers/application", ["exports", "bgr-ember-json-api/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _serializer.default.extend({
    /**
     * Hooks
     */
    keyForRelationship: function keyForRelationship(key) {
      return key;
    }
  });
  _exports.default = _default;
});