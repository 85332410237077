define("bd-hybrid-cloud/templates/partials/configuration/configurations-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "R4GMJa/D",
    "block": "{\"symbols\":[\"configuration\"],\"statements\":[[6,\"ul\"],[9,\"class\",\"editor-panel-tree__branches\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"configurations\"]]],null,{\"statements\":[[0,\"    \"],[12,\"partials/configuration/configuration-tile\",[1]],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/configurations-list.hbs"
    }
  });
  _exports.default = _default;
});