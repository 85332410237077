define("bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/component/fabric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "z9NrE+bs",
    "block": "{\"symbols\":[],\"statements\":[[12,\"partials/configuration/edit/editor-panel-section/component/edit-sample\",[]],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"hasRibbonComponent\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"editor-panel__section-title\"],[7],[0,\"\\n    \"],[1,[25,\"t\",[\"details.ribbon\"],null],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[12,\"partials/configuration/edit/editor-panel-section/component/edit-ribbon-material\",[]],[0,\"\\n  \"],[12,\"partials/configuration/edit/editor-panel-section/component/edit-ribbon-color\",[]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[12,\"partials/configuration/edit/editor-panel-section/component/edit-quilt\",[]],[0,\"\\n\"],[12,\"partials/configuration/edit/editor-panel-section/component/edit-layer-dimensions\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/configuration/edit/editor-panel/component/fabric.hbs"
    }
  });
  _exports.default = _default;
});