define("bd-hybrid-cloud/initializers/styleguide", ["exports", "bd-hybrid-cloud/router"], function (_exports, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    // Register the `styleguide` route.
    _router.default.map(function () {
      this.route('styleguide');
    });
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});