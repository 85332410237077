define("bd-hybrid-cloud/templates/components/x-configuration-share-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MQZtXGBg",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"editor-panel-tree__header editor-panel-tree__header--lg\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"align align--fixed align--fluid\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"icon icon-chevron-left-light icon--action icon--sm u-left bd-text-grey\"],[3,\"action\",[[19,0,[]],\"goBack\"]],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n      \"],[6,\"h4\"],[9,\"class\",\"editor__title u-text-center u-capitalize\"],[7],[1,[18,\"panelTitle\"],false],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\" \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"editor-panel-tree__body\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"shouldLoadPartial\"]]],null,{\"statements\":[[0,\"    \"],[12,[20,[\"partialName\"]],[]],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-configuration-share-panel.hbs"
    }
  });
  _exports.default = _default;
});