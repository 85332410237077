define("bd-hybrid-cloud/components/x-rich-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Props
     */

    options: {
      actions: ['bold', 'italic', 'underline', 'link']
    },
    onChange: null,
    value: null
  });
  _exports.default = _default;
});