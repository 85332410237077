define("bd-hybrid-cloud/components/x-configurator-borders-editor-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    on = Ember.on;
  var _default = Ember.Component.extend({
    /**
     * Injections
     */

    configuratorEditorPanel: Ember.inject.service(),
    /**
     * State
     */

    isInitialized: false,
    selectedSingle: null,
    availableBorderComponentTypes: [],
    selectedBorderComponentType: null,
    selectedBorderCompoents: [],
    /**
     * Computed
     */

    allowBorderEdit: computed.bool('activeUser.can.editBorderComponents'),
    availableBorderComponentTypesOptions: computed('availableBorderComponentTypes', function () {
      var _this = this;
      return this.get('availableBorderComponentTypes').map(function (type) {
        return {
          value: type.name,
          label: _this.get('trans').t("configurations.borders.types.".concat(type.name))
        };
      });
    }),
    /**
     * Hooks
     */

    didInsertElementHandler: on('didInsertElement', function () {
      this.get('bd3d').on('changedSelection', this, this.changedSelection);
      this.get('bd3d').on('configComplete', this, this.configComplete);
      if (this.get('bd3d.mattressConfig')) {
        this.initMattress();
      }
    }),
    willDestroyElementHandler: on('willDestroyElement', function () {
      this.get('bd3d').off('configComplete', this, this.configComplete);
      this.get('bd3d').off('changedSelection', this, this.changedSelection);
      this.get('bd3d.mattressConfigurator').setSelectionMode('component');
    }),
    /**
     * Methods
     */
    initMattress: function initMattress() {
      this.initSingle();
      this.get('bd3d.mattressConfigurator').setSelectionMode('single');
      this.set('isInitialized', true);
    },
    initSingle: function initSingle() {
      var selectedSingle = this.get('bd3d.mattressConfig').getMainSingle();
      this.get('bd3d.mattressConfigurator').setSelectedData(selectedSingle);
    },
    changedSelection: function changedSelection() {
      var selectedComponentData = this.get('bd3d.mattressConfigurator').getSelectedDataInfo();
      if (selectedComponentData) {
        var type = selectedComponentData.type,
          single = selectedComponentData.single;
        if (type === 'single' && single) {
          this.set('selectedSingle', single);
          this.setAvailableBorderComponentTypes();
          this.drawBorderComponentsList();
        }
      }
    },
    configComplete: function configComplete() {
      if (!this.get('isInitialized')) {
        this.initMattress();
      } else {
        this.drawBorderComponentsList();
      }
    },
    setAvailableBorderComponentTypes: function setAvailableBorderComponentTypes() {
      this.set('availableBorderComponentTypes', this.get('bd3d.mattressConfig').getAvailableBorderComponentTypes(this.get('selectedSingle')));
      this.set('selectedBorderComponentType', this.get('availableBorderComponentTypesOptions')[0]);
    },
    drawBorderComponentsList: function drawBorderComponentsList(list) {
      var _this2 = this;
      if (list) {
        this.set('selectedBorderCompoents', list);
      } else {
        var items = this.get('bd3d.mattressConfig').getBorderComponents(this.get('selectedSingle')).map(function (item, index) {
          return {
            index: index,
            label: _this2.get('trans').t("configurations.borders.types.".concat(item.type)),
            object: item
          };
        });
        this.set('selectedBorderCompoents', items);
      }
    },
    /**
     * Actions
     */

    actions: {
      changeBorderTypeSelection: function changeBorderTypeSelection(option) {
        this.set('selectedBorderComponentType', option);
      },
      addBorderComponentType: function addBorderComponentType() {
        this.get('bd3d.mattressConfig').addBorderComponent(this.get('selectedSingle'), this.get('selectedBorderComponentType').value);
      },
      reorderItems: function reorderItems(items, item) {
        this.drawBorderComponentsList(items);
        this.get('bd3d.mattressConfig').setBorderComponentIndex(this.get('selectedSingle'), item.index, items.indexOf(item));
      },
      removeBorderComponent: function removeBorderComponent(item) {
        this.get('bd3d.mattressConfig').removeBorderComponent(this.get('selectedSingle'), item.index);
      },
      openBorderComponent: function openBorderComponent(item) {
        this.get('bd3d.mattressConfigurator').setSelectedData(item.object);
        this.get('configuratorEditorPanel').set('componentOrigin', 'borders');
      }
    }
  });
  _exports.default = _default;
});