define("bd-hybrid-cloud/components/forms/x-forgot-password-form", ["exports", "bd-hybrid-cloud/components/x-form"], function (_exports, _xForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _xForm.default.extend({
    /**
     * State
     */

    email: '',
    success: false,
    /**
     * Hooks
     */
    request: function request() {
      var _this = this;
      return this.get('api').post('oauth/forgot-password', this.getProperties('email')).then(function () {
        _this.set('success', true);
      });
    }
  });
  _exports.default = _default;
});