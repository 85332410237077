define("bd-hybrid-cloud/components/x-datepicker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Props
     */

    placeholder: '',
    value: '',
    /**
     * State
     */

    tagName: '',
    /**
     * Actions
     */

    actions: {
      onCenterChange: function onCenterChange(_ref) {
        var date = _ref.date;
        this.set('center', date);
      },
      onSelect: function onSelect(dd, _ref2) {
        var date = _ref2.date;
        var dateString = (0, _moment.default)(date).format('YYYY-MM-DD');
        dd.actions.close();

        // TODO: Use DDAU?
        this.set('value', dateString);
      }
    }
  });
  _exports.default = _default;
});