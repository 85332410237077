define("bd-hybrid-cloud/routes/app/products/index", ["exports", "bd-hybrid-cloud/mixins/route/filter-query", "bd-hybrid-cloud/mixins/route/model-loading-state", "bd-hybrid-cloud/mixins/route/reset-selected-samples"], function (_exports, _filterQuery, _modelLoadingState, _resetSelectedSamples) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route;
  var _default = Route.extend(_filterQuery.default, _modelLoadingState.default, _resetSelectedSamples.default, {
    model: function model(params) {
      // TODO: Move samples query logic to controller or component
      return {
        samplesFilterQuery: {
          filter: this.generateFilterObject(params),
          page: params.page,
          per_page: 24,
          search: true,
          sort: params.sort
        }
      };
    }
  });
  _exports.default = _default;
});