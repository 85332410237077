define("bd-hybrid-cloud/components/forms/x-reset-password-form", ["exports", "bd-hybrid-cloud/components/x-form"], function (_exports, _xForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _xForm.default.extend({
    /**
     * Options
     */

    identifier: '',
    /**
     * State
     */

    password: '',
    passwordConfirmation: '',
    success: false,
    /**
     * Hooks
     */
    request: function request() {
      var _this = this;
      return this.get('api').post('oauth/set-password', this.getProperties('identifier', 'password', 'passwordConfirmation')).then(function () {
        _this.set('success', true);
      });
    }
  });
  _exports.default = _default;
});