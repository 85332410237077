define("bd-hybrid-cloud/templates/app/projects/project/add/quilt-patterns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sIP+HsJK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"x-app-content-overlay-view\",null,[[\"title\",\"subtitle\",\"previous\",\"onBackRoute\",\"onCloseRoute\"],[[20,[\"model\",\"title\"]],[25,\"t\",[\"projects.add.quilts\"],null],true,\"app.projects.project.add\",\"app.projects.project\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"gr-12 gr-8@desktop gr-centered\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"wysiwyg-content section__row u-text-sm\"],[7],[0,\"\\n          \"],[1,[25,\"t\",[\"quilts.disclaimer\"],null],false],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n          \"],[1,[25,\"x-file-upload\",null,[[\"allowCrop\",\"endpoint\",\"model\",\"modelThumbKey\",\"modelTitleKey\",\"type\",\"onFileUploadComplete\",\"onFileUploadStart\"],[false,[25,\"concat\",[\"projects/\",[20,[\"model\",\"id\"]],\"/quilt-patterns/upload\"],null],[20,[\"model\"]],\"bumpUrl\",\"name\",\"images\",[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"isUploading\"]]],null],false],null],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"isUploading\"]]],null],true],null]]]],false],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section__row u-text-center\"],[7],[0,\"\\n          \"],[4,\"link-to\",[\"app.projects.project\"],[[\"class\"],[\"bd-button bd-button--regular bd-button--solid-blue\"]],{\"statements\":[[1,[25,\"t\",[\"actions.done\"],null],false]],\"parameters\":[]},null],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/projects/project/add/quilt-patterns.hbs"
    }
  });
  _exports.default = _default;
});