define("bd-hybrid-cloud/components/x-empty-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed;
  var _default = Component.extend({
    /**
     * Props
     */

    type: '',
    /**
     * Computed
     */

    cloudText: computed('type', function () {
      return "projects.empty.cloud.".concat(this.get('type'));
    })
  });
  _exports.default = _default;
});