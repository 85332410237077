define("bd-hybrid-cloud/router", ["exports", "bd-hybrid-cloud/config/environment", "bd-hybrid-cloud/mixins/google-pageview", "ember-router-scroll"], function (_exports, _environment, _googlePageview, _emberRouterScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend(_emberRouterScroll.default, _googlePageview.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    /**
     * Unprotected routes
     */

    this.route('login');
    this.route('welcome');
    this.route('forgot-password');
    this.route('reset-password');
    this.route('register-confirmed', {
      path: 'register-confirmed/:identifier'
    });
    this.route('share', function () {
      this.route('configuration', function () {
        this.route('detail');
      });
      this.route('sample');
    });

    /**
     * Protected routes
     */

    this.route('app', {
      path: ''
    }, function () {
      this.route('products', function () {
        this.route('samples');
      });
      this.route('technologies');
      this.route('concepts', function () {
        this.route('concept', {
          path: '/:id'
        });
      });
      this.route('collections', function () {
        this.route('collection', {
          path: '/:id'
        });
      });
      this.route('collection-theme', {
        path: '/collections/collection-theme/:id'
      });
      this.route('projects', function () {
        this.route('mine');
        this.route('shared');
        this.route('create');
        this.route('project', {
          path: '/:id'
        }, function () {
          this.route('add', function () {
            this.route('samples');
            this.route('sketchbook');
            this.route('notes');
            this.route('documents');
            this.route('configurations');
            this.route('description');
            this.route('draft-samples');
            this.route('quilt-patterns');
          });
          this.route('note', {
            path: '/note/:note_id'
          });
        });
        this.route('archived');
      });
      this.route('create');
      this.route('configuration', function () {
        this.route('create');
        this.route('configuration', {
          path: '/:id'
        }, function () {
          this.route('edit');
        });
      });
      this.route('settings', function () {
        this.route('profile');
        this.route('account');
        this.route('configurator');
        this.route('emails');
      });
      this.route('news', function () {
        this.route('news-item', {
          path: '/:id'
        });
      });
      this.route('notifications');
      this.route('help', function () {
        this.route('tutorials');
        this.route('documents');
      });
      this.route('scoops', function () {
        this.route('scoop', {
          path: '/:id'
        });
      });
      this.route('capsules', function () {
        this.route('capsule', {
          path: '/:id'
        });
      });
    });

    /**
     * Not found route
     */

    this.route('not-found', {
      path: '/*wildcard'
    });
  });
  var _default = Router;
  _exports.default = _default;
});