define("bd-hybrid-cloud/routes/share/configuration/detail", ["exports", "bd-hybrid-cloud/mixins/route/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_configuration.default, {
    /**
     * State
     */

    activeViewMode: 'detail',
    routeController: 'share.configuration'
  });
  _exports.default = _default;
});