define("bd-hybrid-cloud/templates/app/projects/project/add/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+aSBm61o",
    "block": "{\"symbols\":[],\"statements\":[[4,\"x-app-content-overlay-view\",null,[[\"title\",\"subtitle\",\"previous\",\"onBackRoute\",\"onCloseRoute\"],[[20,[\"model\",\"title\"]],[25,\"t\",[\"projects.add.notes\"],null],true,\"app.projects.project.add\",\"app.projects.project\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"gr-8 gr-4@desktop gr-centered\"],[7],[0,\"\\n    \"],[1,[25,\"x-note-form\",null,[[\"onSubmit\"],[\"addNote\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/projects/project/add/notes.hbs"
    }
  });
  _exports.default = _default;
});