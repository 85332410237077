define("bd-hybrid-cloud/mixins/route/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on;
  var _default = Ember.Mixin.create({
    /**
     * Events
     */

    activateHandler: on('activate', function () {
      this.get('bd3d').setIsSelectable(true);
      this.controllerFor(this.get('routeController')).setViewMode(this.get('activeViewMode'));
    }),
    deactivateHandler: on('deactivate', function () {
      this.get('bd3d').setIsSelectable(false);
      this.controllerFor(this.get('routeController')).setViewMode('view');
    })
  });
  _exports.default = _default;
});