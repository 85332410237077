define("bd-hybrid-cloud/adapters/sample", ["exports", "bd-hybrid-cloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var urlForQueryRecord;
      if (query.share) {
        Reflect.deleteProperty(query, 'share');
        urlForQueryRecord = this.buildURL('share/samples', query.identifier);
      } else {
        urlForQueryRecord = this._super(query, modelName);
      }
      return urlForQueryRecord;
    }
  });
  _exports.default = _default;
});