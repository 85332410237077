define("bd-hybrid-cloud/controllers/app/help/tutorials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    /**
     * Actions
     */
    openVimeoVideoPlayerModal: function openVimeoVideoPlayerModal(videoData) {
      this.modal.show('vimeo-video-player', videoData);
    }
  });
  _exports.default = _default;
});