define("bd-hybrid-cloud/templates/welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nTkms/v1",
    "block": "{\"symbols\":[],\"statements\":[[12,\"partials/welcome/header\",[]],[0,\"\\n\"],[12,\"partials/welcome/showroom\",[]],[0,\"\\n\"],[12,\"partials/welcome/customization\",[]],[0,\"\\n\"],[1,[25,\"forms/x-register-form\",null,[[\"countries\",\"instance\"],[[20,[\"model\",\"countries\"]],[20,[\"registerForm\"]]]]],false],[0,\"\\n\"],[1,[18,\"x-cookie-statement\"],false],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/welcome.hbs"
    }
  });
  _exports.default = _default;
});