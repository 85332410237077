define("bd-hybrid-cloud/routes/app/create", ["exports", "bd-hybrid-cloud/mixins/route/permissions"], function (_exports, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_permissions.default, {
    neededPermissions: ['create'],
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('display', '');
      }
    }
  });
  _exports.default = _default;
});