define("bd-hybrid-cloud/components/x-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assert = Ember.assert,
    Component = Ember.Component;
  var _default = Component.extend({
    /**
     * State
     */

    classNames: ['form'],
    errors: [],
    requestIsAllowed: true,
    tagName: 'form',
    /**
     * Events
     */
    submit: function submit(event) {
      var _this = this;
      event.preventDefault();
      if (this.get('requestIsAllowed')) {
        if (this.validate()) {
          this.set('requestIsAllowed', false);
          this.request().then(function () {
            _this.set('errors', []);
          }).catch(function (response) {
            _this.set('errors', response.errors || response.responseJSON.errors);
          }).finally(function () {
            _this.set('requestIsAllowed', true);
          });
        }
      }
    },
    /**
     * Hooks
     */
    request: function request() {
      assert('The \'request\' hook must be overridden.');
    },
    validate: function validate() {
      return true;
    }
  });
  _exports.default = _default;
});