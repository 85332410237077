define("bd-hybrid-cloud/templates/app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "24HqAFWB",
    "block": "{\"symbols\":[],\"statements\":[[1,[18,\"x-app-header\"],false],[0,\"\\n\\n\"],[6,\"section\"],[9,\"class\",\"app-content\"],[9,\"id\",\"app-content\"],[7],[0,\"\\n  \"],[1,[18,\"outlet\"],false],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[1,[18,\"bgr-modal-stack\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app.hbs"
    }
  });
  _exports.default = _default;
});