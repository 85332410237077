define("bd-hybrid-cloud/templates/components/modals/x-file-upload-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o0dG4Row",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bgr-modal-template\",null,null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"modal__row\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"modal__title\"],[7],[1,[20,[\"model\",\"title\"]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal__row\"],[7],[0,\"\\n    \"],[1,[25,\"x-file-upload\",null,[[\"allowCrop\",\"endpoint\",\"type\",\"onFileUploadComplete\",\"onFileUploadStart\"],[[20,[\"model\",\"allowCrop\"]],[20,[\"model\",\"endpoint\"]],[20,[\"model\",\"type\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"isUploading\"]]],null],false],null],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"isUploading\"]]],null],true],null]]]],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal__row u-text-center\"],[7],[0,\"\\n    \"],[6,\"button\"],[10,\"disabled\",[18,\"isUploading\"],null],[10,\"class\",[26,[\"bd-button bd-button--regular \",[25,\"if\",[[25,\"not\",[[20,[\"isUploading\"]]],null],\"bd-button--solid-blue\",\"bd-button--solid-grey-dark disabled\"],null]]]],[3,\"action\",[[19,0,[]],\"hide\"]],[7],[0,\"\\n        \"],[1,[25,\"t\",[\"actions.done\"],null],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/modals/x-file-upload-modal.hbs"
    }
  });
  _exports.default = _default;
});