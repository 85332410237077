define("bd-hybrid-cloud/serializers/notification", ["exports", "bd-hybrid-cloud/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    attrs: {
      ownerUserId: {
        serialize: false
      },
      firstName: {
        serialize: false
      },
      lastName: {
        serialize: false
      },
      type: {
        serialize: false
      },
      action: {
        serialize: false
      },
      name: {
        serialize: false
      },
      multiple: {
        serialize: false
      },
      amount: {
        serialize: false
      },
      createdAt: {
        serialize: false
      },
      updatedAt: {
        serialize: false
      },
      lastLogDate: {
        serialize: false
      }
    }
  });
  _exports.default = _default;
});