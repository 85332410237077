define("bd-hybrid-cloud/utils/create-style-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createStyleString;
  var validators = {
    backgroundImage: function backgroundImage(val) {
      return val ? "url('".concat(val, "')") : '';
    }
  };
  function createStyleString(props) {
    var style = '';
    Object.keys(props).forEach(function (prop) {
      var key = prop.dasherize();
      var validator = validators[prop];
      var val;
      if (validator) {
        val = validator(props[prop]);
      } else {
        val = props[prop];
      }
      if (val) {
        style += "".concat(key, ":").concat(val, ";");
      }
    });
    return style.htmlSafe();
  }
});