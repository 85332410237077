define("bd-hybrid-cloud/templates/app/collections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wEfxCduS",
    "block": "{\"symbols\":[\"isLoading\",\"collections\",\"collection\"],\"statements\":[[4,\"load-records\",null,[[\"modelName\",\"onLoad\",\"options\"],[\"collection\",[25,\"action\",[[19,0,[]],[19,0,[\"onCollectionsLoadedHandler\"]]],null],[25,\"hash\",null,[[\"filter\",\"sort\"],[[25,\"hash\",null,[[\"show_on_cloud\"],[\"1\"]]],\"-created_at\"]]]]],{\"statements\":[[0,\"\\n  \"],[6,\"header\"],[9,\"class\",\"section section--collapse-bottom section--white\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"gr-12\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"section__row section__row--sm\"],[7],[0,\"\\n            \"],[6,\"h1\"],[9,\"class\",\"bd-title bd-title--h3\"],[7],[1,[25,\"t\",[\"collections.title\"],null],false],[8],[0,\"\\n          \"],[8],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"section__row section__row--sm\"],[7],[0,\"\\n            \"],[6,\"nav\"],[7],[0,\"\\n              \"],[6,\"ul\"],[9,\"class\",\"sub-navigation\"],[7],[0,\"\\n\"],[4,\"each\",[[19,2,[]]],null,{\"statements\":[[0,\"                  \"],[6,\"li\"],[9,\"class\",\"sub-navigation__item\"],[7],[0,\"\\n                    \"],[4,\"link-to\",[\"app.collections.collection\",[19,3,[\"id\"]]],[[\"class\"],[\"sub-navigation__link\"]],{\"statements\":[[1,[19,3,[\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n                  \"],[8],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"              \"],[8],[0,\"\\n            \"],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"if\",[[19,1,[]]],null,{\"statements\":[[0,\"    \"],[1,[18,\"app-content-loader\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[18,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/collections.hbs"
    }
  });
  _exports.default = _default;
});