define("bd-hybrid-cloud/templates/partials/general/logos/bd-logo-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "I7/VFTPD",
    "block": "{\"symbols\":[],\"statements\":[[6,\"img\"],[9,\"src\",\"/assets/images/logos/bd-cloud-text-white.svg\"],[9,\"alt\",\"BekaertDeslee Logo\"],[9,\"width\",\"200\"],[9,\"height\",\"155\"],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/partials/general/logos/bd-logo-white.hbs"
    }
  });
  _exports.default = _default;
});