define("bd-hybrid-cloud/templates/forgot-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZZi1+bqz",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n  \"],[6,\"section\"],[9,\"class\",\"section section--lg u-text-center\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"section__row\"],[7],[0,\"\\n        \"],[12,\"partials/general/logos/bd-logo-blue\",[]],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"section__row section__row--xs\"],[7],[0,\"\\n        \"],[6,\"p\"],[7],[1,[25,\"t\",[\"forgotPassword.reset\"],null],false],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"section__row section__row--lg\"],[7],[0,\"\\n      \"],[1,[25,\"forms/x-forgot-password-form\",null,[[\"class\"],[\"form--constrained-width\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/forgot-password.hbs"
    }
  });
  _exports.default = _default;
});