define("bd-hybrid-cloud/templates/components/x-cookie-statement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AjuvT9A1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"visible\"]]],null,{\"statements\":[[6,\"div\"],[9,\"class\",\"cookie-statement\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"cookie-statement__left\"],[7],[0,\"\\n    \"],[6,\"p\"],[7],[0,\"\\n      \"],[1,[25,\"t\",[\"cookieStatement.text\"],null],false],[0,\"\\n      \"],[6,\"a\"],[9,\"target\",\"_blank\"],[9,\"href\",\"https://www.bekaertdeslee.com/cookie-statement\"],[9,\"class\",\"link link--underline link--white\"],[7],[1,[25,\"t\",[\"cookieStatement.link\"],null],false],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"cookie-statement__right\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"align align--centered align--gutter\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"align__cell\"],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"bd-button bd-button--regular-sm bd-button--solid-stroked-white\"],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"optOut\"]],[7],[1,[25,\"t\",[\"cookieStatement.optOut\"],null],false],[8],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"bd-button  bd-button--regular bd-button--solid\"],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"confirm\"]],[7],[1,[25,\"t\",[\"cookieStatement.iUnderstand\"],null],false],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-cookie-statement.hbs"
    }
  });
  _exports.default = _default;
});