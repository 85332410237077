define("bd-hybrid-cloud/components/x-tags-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Props
     */

    tags: [],
    /**
     * State
     */

    classNames: ['tags-list'],
    tagName: 'ul'
  });
  _exports.default = _default;
});