define("bd-hybrid-cloud/templates/app/help/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1RquR8kp",
    "block": "{\"symbols\":[\"isLoadingCategory\",\"courseCategory\",\"isLoadingCourses\",\"courses\",\"course\"],\"statements\":[[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"flex flex-wrap items-stretch w-full\"],[7],[0,\"\\n      \"],[4,\"load-record\",null,[[\"id\",\"modelName\"],[\"3\",\"courseCategory\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[19,1,[]]],null,{\"statements\":[[0,\"          \"],[6,\"div\"],[9,\"class\",\"loader loader--md\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[4,\"load-child-records\",null,[[\"modelName\",\"parentRecord\"],[\"courses\",[19,2,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[19,3,[]]],null,{\"statements\":[[0,\"              \"],[6,\"div\"],[9,\"class\",\"loader loader--md\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[19,4,[]]],null,{\"statements\":[[0,\"                \"],[6,\"div\"],[9,\"class\",\"flex items-stretch p-15px w-1/3\"],[7],[0,\"\\n                  \"],[1,[25,\"help-article-card\",null,[[\"description\",\"documentExtension\",\"documentUrl\",\"totalPages\",\"thumbnail\",\"title\",\"__ANGLE_ATTRS__\"],[[19,5,[\"content\"]],[19,5,[\"documentExt\"]],[19,5,[\"documentPath\"]],[19,5,[\"time\"]],[19,5,[\"thumbUrl\"]],[19,5,[\"title\"]],[25,\"hash\",null,[[\"class\"],[\"w-full\"]]]]]],false],[0,\"\\n                \"],[8],[0,\"\\n\"]],\"parameters\":[5]},null]],\"parameters\":[]}],[0,\"          \"]],\"parameters\":[3,4]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"]],\"parameters\":[1,2]},null],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/app/help/documents.hbs"
    }
  });
  _exports.default = _default;
});