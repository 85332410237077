define("bd-hybrid-cloud/components/x-collapsible-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed;
  var _default = Component.extend({
    /**
     * State
     */

    isCollapsed: true,
    tagName: '',
    /**
     * Arguments
     */

    items: [],
    show: 6,
    shouldCollapseItems: true,
    /**
     * Computed
     */

    isCollapsible: computed('items.length', 'show', function () {
      return this.get('show') < this.get('items.length');
    }),
    visibleItems: computed('isCollapsed', 'items.length', 'show', function () {
      var show = this.get('show');
      var items = this.get('items');
      var isCollapsed = this.get('isCollapsed');
      if (isCollapsed) {
        return items.slice(0, show);
      }
      return items;
    }),
    /**
     * Actions
     */

    actions: {
      toggle: function toggle() {
        this.toggleProperty('isCollapsed');
      }
    }
  });
  _exports.default = _default;
});