define("bd-hybrid-cloud/components/x-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      delete: function _delete() {
        var model = this.get('model');
        var trans = this.get('trans');
        var name = trans.t('modelNames.thisNote');
        var message = trans.t('confirm.delete', {
          name: name
        });
        this.get('modal').confirm(message).then(function () {
          model.destroyRecord();
        });
      }
    }
  });
  _exports.default = _default;
});