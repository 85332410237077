define("bd-hybrid-cloud/models/quilt-pattern", ["exports", "ember-data", "bgr-ember-json-api/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _model.default.extend({
    /**
     * Props
     */

    bumpUrl: attr('string'),
    custom: attr(),
    foam: attr(),
    height: attr('number'),
    isBorder: attr('number', {
      defaultValue: 1
    }),
    isPanel: attr('number', {
      defaultValue: 1
    }),
    name: attr('string'),
    quiltPatternCategory: belongsTo('quiltPatternCategory'),
    repeat: attr(),
    settings: attr(),
    thumbUrl: attr('string'),
    width: attr('number'),
    /**
     * Methods
     */
    getConfigurationData: function getConfigurationData() {
      return {
        bump: this.get('bumpUrl') || null,
        foam: this.get('foam'),
        id: this.get('id'),
        img: {
          settings: this.get('settings')
        },
        name: this.get('name'),
        realHeight: this.get('height') || 0,
        realWidth: this.get('width') || 0,
        repeat: this.get('repeat')
      };
    }
  });
  _exports.default = _default;
});