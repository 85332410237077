define("bd-hybrid-cloud/mixins/show-sample", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    /**
     * Actions
     */

    actions: {
      showSample: function showSample(sample) {
        this.get('router').transitionTo({
          queryParams: {
            sampleId: sample.get('id')
          }
        });
      }
    }
  });
  _exports.default = _default;
});