define("bd-hybrid-cloud/controllers/app/technologies", ["exports", "bd-hybrid-cloud/mixins/controller/iframe"], function (_exports, _iframe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_iframe.default, {
    /**
     * Props
     */

    pathname: '/en/technologies'
  });
  _exports.default = _default;
});