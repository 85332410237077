define("bd-hybrid-cloud/components/x-grid-sorting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed;
  var DASH = '-';
  var ASC = 'asc';
  var DESC = 'desc';
  var ALPHABETICAL_CLASS_PREFIX = 'icon-sort-alphabetical';
  var CHRONOLOGICAL_CLASS_PREFIX = 'icon-sort-date';
  function isDesc(sort) {
    return sort.startsWith(DASH);
  }
  function getAttribute(sort) {
    return isDesc(sort) ? sort.substring(1, sort.length) : sort;
  }
  var _default = Component.extend({
    /**
     * Props
     */

    alphabeticalSort: '',
    chronologicalSort: '-updated_at',
    disableSorting: false,
    sort: '-updated_at',
    /**
     * Computed
     */

    alphabeticalButtonClass: computed('sort', function () {
      return this.generateButtonClass('alphabetical');
    }),
    alphabeticalIconClass: computed('sort', function () {
      return this.generateIconClass('alphabetical');
    }),
    chronologicalButtonClass: computed('sort', function () {
      return this.generateButtonClass('chronological');
    }),
    chronologicalIconClass: computed('sort', function () {
      return this.generateIconClass('chronological');
    }),
    config: computed('alphabeticalSort', 'chronologicalSort', function () {
      var alphabeticalSort = this.get('alphabeticalSort');
      var chronologicalSort = this.get('chronologicalSort');
      return {
        alphabetical: {
          attribute: getAttribute(alphabeticalSort),
          classPrefix: ALPHABETICAL_CLASS_PREFIX,
          defaultSort: alphabeticalSort
        },
        chronological: {
          attribute: getAttribute(chronologicalSort),
          classPrefix: CHRONOLOGICAL_CLASS_PREFIX,
          defaultSort: chronologicalSort
        }
      };
    }),
    /**
     * Actions
     */

    actions: {
      toggleSorting: function toggleSorting(type) {
        var sort = this.get('sort');
        var config = this.get("config.".concat(type));
        var attribute = config.attribute;
        var newSort;
        if (sort.includes(attribute)) {
          newSort = isDesc(sort) ? attribute : DASH + attribute;
        } else {
          newSort = config.defaultSort;
        }
        this.get('router').transitionTo({
          queryParams: {
            sort: newSort
          }
        });
      }
    },
    /**
     * Methods
     */
    generateButtonClass: function generateButtonClass(type) {
      return this.get('sort').includes(this.get("config.".concat(type, ".attribute"))) ? 'is-active' : '';
    },
    generateIconClass: function generateIconClass(type) {
      var sort = this.get('sort');
      var config = this.get("config.".concat(type));
      var classDirection;
      if (sort.includes(config.attribute)) {
        classDirection = isDesc(sort) ? DESC : ASC;
      } else {
        classDirection = isDesc(config.defaultSort) ? DESC : ASC;
      }
      return "".concat(config.classPrefix, "-").concat(classDirection);
    }
  });
  _exports.default = _default;
});