define("bd-hybrid-cloud/templates/components/x-sample-note-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3zgjTuOJ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[9,\"class\",\"icon-note\"],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bd-hybrid-cloud/templates/components/x-sample-note-icon.hbs"
    }
  });
  _exports.default = _default;
});