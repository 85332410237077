define("bd-hybrid-cloud/components/x-spectrum-color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed,
    guidFor = Ember.guidFor;
  var oneWay = computed.oneWay;
  var _default = Component.extend({
    /**
     * props
     */

    color: null,
    onChange: null,
    /**
     * state
     */

    classNames: ['align', 'align--gutter-xs'],
    /**
     * computed
     */

    id: computed(function () {
      var guid = guidFor(this);
      return "".concat(guid, "-spectrum-color-picker");
    }),
    internalColor: oneWay('color'),
    /**
     * actions
     */

    actions: {
      onCancel: function onCancel() {
        this.set('internalColor', this.get('color'));
      },
      onChange: function onChange(internalColor) {
        if (internalColor && typeof internalColor === 'string' && internalColor !== this.get('color')) {
          this.get('onChange')(internalColor);
        }
      }
    }
  });
  _exports.default = _default;
});